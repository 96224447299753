import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import { genericBadge } from "../../../utils/Badges";
import { sells_status } from "../../../variables/constants";
import { financed_status } from "../../../variables/sells";
import { Comments } from "../../../components/Comments";

const Information = (props) => {
  const [information, setInformation] = useState(false);

  useEffect(() => {
    setInformation(props.data);
  }, [props.data]);

  const renderRow = (label, data) => (
    <tr>
      <td>
        <strong>{label}</strong>
      </td>
      <td>{data ?? "-"}</td>
    </tr>
  );

  return (
    <div className="plate-information" style={{ width: "100%" }}>
      <Card
        ctTableFullWidth
        ctTableResponsive
        title="Información"
        content={
          props.loading ? (
            "Loading..."
          ) : (
            <>
              <Table striped hover>
                <tbody>
                  {renderRow("Oportunidad ID", information?.opportunity_id)}
                  {renderRow("Dominio", information?.plate_number)}
                  {renderRow("Stock ID", information?.stock_id)}
                  {renderRow(
                    "Estado",
                    information?.status
                      ? genericBadge(sells_status, information.status)
                      : "-"
                  )}
                  {renderRow(
                    "Fecha Recepción de Cédula",
                    information?.take_licence_date
                  )}
                  {renderRow(
                    "Titular",
                    information?.owner?.length
                      ? `${information?.owner[0].business_name} - DNI ${information?.owner[0].dni} [${information?.owner[0].email}]`
                      : "-"
                  )}
                  {renderRow("Tipo de Dirección", information?.address_type)}
                  {renderRow("Dirección", information?.address)}
                  {renderRow(
                    "Fecha de Documentación",
                    information?.withdraw_date
                  )}
                  {renderRow(
                    "Número de Seguimiento",
                    information?.tracking_number
                  )}
                </tbody>
              </Table>
              <Table>
                <tbody>
                  <tr>
                    <td>
                      <Comments
                        comments={information.comments}
                        statusList={sells_status}
                        title="COMENTARIOS VENTAS"
                        hideReasons
                      />
                    </td>
                  </tr>
                  {!!information?.financedComments?.length && (
                    <tr>
                      <td>
                        <Comments
                          comments={information.financedComments}
                          statusList={financed_status}
                          title="COMENTARIOS PRENDADOS"
                          hideReasons
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </>
          )
        }
      />
    </div>
  );
};

export default Information;
