import axios from "axios";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const insert = (id, data) => {
  return axios
    .post(`${API_URL}/certificate/${id}`, data, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      }
      throw new Error(response.data.message);
    });
};

export default {
  insert,
};
