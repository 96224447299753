import CustomAccordion from "components/Accordion";
import { CommentBox, ObservationBox, ReasonBox } from "./box/";
import React from "react";
const Comments = ({
  onChangeObs,
  comments = [],
  reasons = [],
  generic = false,
  onChangeSelect,
  hideReasons = false,
  title = "COMENTARIOS",
  statusList,
}) => {
  return (
    <>
      <CustomAccordion title={`${title} - ${comments.length}`}>
        <CommentBox
          comments={comments}
          generic={generic}
          statusList={statusList}
        />
      </CustomAccordion>
      <div>
        {!hideReasons && (
          <>
            {reasons?.length > 0 && (
              <ReasonBox reasons={reasons} onChangeSelect={onChangeSelect} />
            )}
            <ObservationBox onChangeObs={onChangeObs} />
          </>
        )}
      </div>
    </>
  );
};

export default Comments;
