import { agentProfiles, sells_status } from "variables/constants";

function disabledSubmit({
  status,
  fields,
  step2,
  step4,
  profile,
  allRequirements,
  on_trial,
}) {
  // Condiciones para el submit - Cambio de estado

  // const isAgentInternal = selectedAgent?.indexOf("[Gestor Interno]") !== -1;
  const requirementStatus = [
    sells_status.PRESENTATION_OBSERVED.value,
    sells_status.PRESENTATION_OBSERVED_RETIRED.value,
  ];

  // Estado en Espera => agente, registro y fecha turno de presentación si es gestor interno
  const conditionOnWaiting =
    status === sells_status.PRESENTATION_WAITING.value &&
    fields.agent_id !== null &&
    fields.issuer_entity_tentative_id !== null &&
    !agentProfiles.includes(profile);
  // Estado entregado =>
  // - agente
  // - registro
  // - fecha turno de presentación
  // - Link de rendición de presentación
  // - si es gestor algún adjunto
  // - Se cumplio el periodo de prueba
  const conditionOnDelivery =
    status === sells_status.PRESENTATION_DELIVERED.value &&
    fields.agent_id !== null &&
    fields.issuer_entity_tentative_id !== null &&
    fields.turn_request_date !== null &&
    // fields.rendition_link_presented?.length &&
    // ((agentProfiles.includes(profile) && fields.images.length > 0) ||
    //   !agentProfiles.includes(profile)) &&
    !on_trial;
  // Estado presentado o re-presentado y no es gestor
  const conditionOnPresented =
    !agentProfiles.includes(profile) &&
    step2.concat(step4).includes(status) &&
    fields.agent_id !== null;
  // Estado CAT =>
  // - fecha turno de retiro obligatoria
  // - Link de redición de retiro
  const conditionOnCat =
    status === sells_status.PRESENTATION_CAT_RECEIVED.value &&
    fields.turn_take_date !== null &&
    // fields.rendition_link_retired?.length &&
    fields.agent_id !== null;
  // Estado Observado => agente, registro y fecha turno de re-presentación si es gestor interno
  const conditionOnObserved =
    requirementStatus.includes(status) &&
    fields.agent_id !== null &&
    fields.issuer_entity_tentative_id !== null &&
    !agentProfiles.includes(profile) &&
    allRequirements;
  // Estado re-entregado => agente, registro, fecha turno de re-presentación, y si es gestor algún adjunto
  const conditionOnObservedDelivery =
    status === sells_status.PRESENTATION_OBSERVED_DELIVERED.value &&
    fields.agent_id !== null &&
    fields.issuer_entity_tentative_id !== null &&
    fields.turn_rerequest_date !== null &&
    // ((agentProfiles.includes(profile) && fields.images.length > 0) ||
    //   !agentProfiles.includes(profile))
    !on_trial;

  return {
    conditionOnWaiting,
    conditionOnDelivery,
    conditionOnPresented,
    conditionOnCat,
    conditionOnObserved,
    conditionOnObservedDelivery,
  };
}

export default disabledSubmit;
