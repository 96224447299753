import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const findOneByPlateNumber = async (plate_number) => {
  return await axios.get(`${API_URL}/purchase-reception/${plate_number}`, { headers: authHeader() });
};

const add = (payload) => {
  return axios.post(`${API_URL}/purchase-reception`, {
    ...payload,
  }, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const del = (plate_number) => {
  return axios.delete(`${API_URL}/purchase-reception/${plate_number}`, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

export default {
  findOneByPlateNumber,
  add,
  del,
};
