import jsPDF from "jspdf";
import PrintService from "../services/print/print.service";

const shareMapping = (vehicle) => {
  if (!vehicle?.car || !vehicle?.engine) {
    throw new Error("Invalid vehicle data: missing car or engine information");
  }
  return [
    { field: "vehicle_plate_number_top", value: vehicle.plate_number },
    { field: "vehicle_plate_number", value: vehicle.plate_number },
    { field: "vehicle_brand", value: vehicle.car.brand },
    { field: "vehicle_type", value: vehicle.car.type },
    { field: "vehicle_model", value: vehicle.car.model },
    { field: "engine_brand", value: vehicle.engine.engineBrand },
    { field: "engine_number", value: vehicle.engine.engineNumber },
    { field: "chasis_brand", value: vehicle.engine.chasisBrand },
    { field: "chasis_number", value: vehicle.engine.chasisNumber },
  ];
};

const mapAddress = (address, prefix) => {
  if (!prefix) {
    throw new Error("prefix parameter is required for address mapping");
  }
  if (!address) {
    return [];
  }
  return [
    { field: `${prefix}_street`, value: address?.street },
    { field: `${prefix}_number`, value: address?.number },
    { field: `${prefix}_floor`, value: address?.floor },
    { field: `${prefix}_apartment`, value: address?.apartment },
    { field: `${prefix}_zip_code`, value: address?.zipCode },
    { field: `${prefix}_city`, value: address?.city },
    { field: `${prefix}_province`, value: address?.province },
  ];
};

const filterAddressFields = (fields, removedFields) =>
  fields.filter((f) => !removedFields.includes(f.field));

// Map marital status
const MARITAL_STATUS_MAP = {
  SOLTERO: "single",
  CASADO: "married",
  VIUDO: "widower",
  DIVORCIADO: "divorced",
};

const mapOwnerInfo = (owner, index) => {
  if (!owner) return [];

  const percentage = `${owner.holder_percentage}`.split(".");
  const prefix = `seller${index + 1}`;

  const mapping = [
    { field: `${prefix}_percentage_number`, value: percentage[0] },
    { field: `${prefix}_percentage_fraction`, value: percentage[1] || "0" },
    { field: `${prefix}_full_name`, value: owner.holder_name },
    { field: `${prefix}_document_number`, value: owner.document_number },
    { field: `${prefix}_nationality`, value: owner.nationality },
  ];

  if (MARITAL_STATUS_MAP[owner.marital_status]) {
    mapping.push({
      field: `${prefix}_marital_${MARITAL_STATUS_MAP[owner.marital_status]}`,
      value: "x",
    });
  }

  // Map document type
  if (owner.document_type === "D.N.I.") {
    mapping.push({ field: `${prefix}_document_dni`, value: "x" });
  } else if (owner.document_type === "D.N.I.Ex") {
    mapping.push({ field: `${prefix}_document_dni_ex`, value: "x" });
  }

  return mapping;
};

export const formPDF = (data, dataVehicle, form, place = "1", functionId) => {
  const payload = {
    plate_number: dataVehicle.plate_number,
    reception_place_id: place,
    form_id: form,
    function_id: functionId,
  };
  PrintService.logPrint(payload)
    .then((response) => console.log("Print Log Success"))
    .catch((error) => console.log("Print Log Failed", error));

  switch (form) {
    // 08-Compra-Frente
    case 1:
    // 08-Bacs-Frente
    case 2:
    // 08-Compra-Consigna-Frente
    case 3: {
      const {
        plate_number,
        car: { use },
      } = dataVehicle;

      const mapping = [
        ...shareMapping(dataVehicle),
        { field: "vehicle_use", value: use },
      ];

      if (!!data) {
        const pushing = [
          {
            field: "buyer_percentage_number",
            value: data.percentage?.number,
          },
          {
            field: "buyer_percentage_fraction",
            value: data?.percentage?.fraction,
          },
          { field: "buyer_first_name", value: data?.fullName },
          { field: "buyer_last_name", value: data?.lastName },
          { field: "buyer_email", value: data?.email },
          { field: "buyer_phone", value: data?.phone },
          ...mapAddress(data?.address, "buyer_legal_address"),
          ...mapAddress(data?.address, "buyer_real_address"),
          { field: "buyer_profession", value: data?.profession },
          { field: "buyer_cuit", value: data?.cuit },
          { field: "buyer_personeria", value: data?.personeria },
          {
            field: "buyer_inscription_number",
            value: data?.inscriptionData?.number,
          },
          {
            field: "buyer_inscription_day",
            value: data?.inscriptionData?.date?.day,
          },
          {
            field: "buyer_inscription_month",
            value: data?.inscriptionData?.date?.month,
          },
          {
            field: "buyer_inscription_year",
            value: data?.inscriptionData?.date?.year,
          },
        ];
        mapping.push(...pushing);
      }
      formPrintOut(form, place, mapping, plate_number);
      break;
    }
    // 08-Compra-Dorso
    case 4:
    // 08-Compra-Consigna-Dorso
    case 5: {
      const { plate_number, owners } = dataVehicle;
      const mapping = [
        ...mapOwnerInfo(owners[0], 0),
        ...mapOwnerInfo(owners[1], 1),
      ];
      formPrintOut(form, place, mapping, plate_number);
      break;
    }
    // 04-Compra-Consigna-Frente
    case 6:
    // 04-Compra-Check-Frente
    case 8: {
      const { plate_number } = dataVehicle;

      const mapping = [...shareMapping(dataVehicle)];

      if (form === 8 && !!data?.address) {
        const removedFields = [
          "buyer_legal_address_apartment",
          "buyer_legal_address_province",
        ];
        const pushing = filterAddressFields(
          mapAddress(data.address, "buyer_legal_address"),
          removedFields
        );
        mapping.push(...pushing);
      }
      formPrintOut(form, place, mapping, plate_number);
      break;
    }
    // 02-Frente
    case 7: {
      const { plate_number } = dataVehicle;
      const mapping = [...shareMapping(dataVehicle)];
      formPrintOut(form, place, mapping, plate_number);
      break;
    }

    default:
      break;
  }
};

const formPrintOut = (
  print_form_id,
  reception_place_id,
  mapping,
  plate_number
) => {
  if (!print_form_id || !reception_place_id || !mapping || !plate_number) {
    throw new Error("Missing required parameters for PDF generation");
  }
  if (!Array.isArray(mapping)) {
    throw new Error("mapping parameter must be an array");
  }

  PrintService.getConfig(print_form_id, reception_place_id)
    .then((response) => {
      const { config, coordinates } = response?.data?.response || {};

      const doc = new jsPDF({
        orientation: config.orientation,
        unit: config.unit,
        format: [config.format_x, config.format_y],
      });
      doc.setFont(config.font_name);
      doc.setFontSize(config.font_size);

      for (let index = 0; index < mapping.length; index++) {
        const element = mapping[index];
        const coordinate = coordinates.find((c) => c.field === element.field);
        if (!coordinate) {
          console.warn(`No se encontraron coordenadas para: ${element.field}`);
          continue;
        }
        const { pos_x, pos_y } = coordinate;
        doc.text(pos_x, pos_y, element.value?.toString() || "");
      }
      doc.save(
        `${config.reception_place}-formulario-${config.form}-${plate_number}.pdf`
      );
    })
    .catch((error) => {
      console.error(error);
    });
};

export default {};
