import axios from "axios";
import authHeader from "../auth-header";
const API_URL = process.env.REACT_APP_API_URL;

const getAll = async (data) => {
  const url = `${API_URL}/management/form-08`;
  return await axios.post(url, { ...data }, { headers: authHeader() });
};

const getById = async (id) => {
  return await axios.get(`${API_URL}/management/form-08/${id}`, {
    headers: authHeader(),
  });
};

const getDataById = async (id) => {
  return await axios.get(`${API_URL}/management/form-08/vehicle/${id}`, {
    headers: authHeader(),
  });
};

const addComment = (id, data) => {
  return axios
    .post(`${API_URL}/management/form-08/${id}/comment`, data, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const edit = (payload) => {
  return axios
    .put(
      `${API_URL}/management/form-08`,
      {
        ...payload,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

// Busca el maximo valor de numero de formulario
const getLastNumber = async (form_code = "08") => {
  return await axios.get(
    `${API_URL}/management/form-08/last-number/${form_code}`,
    { headers: authHeader() }
  );
};

// Inserta los nuevos 08 asignados a las escribanias
const addBatch = (payload) => {
  return axios
    .post(`${API_URL}/management/form-08/add-batch`, payload, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const searchAndRelate = (payload) => {
  return axios
    .put(
      `${API_URL}/management/form-08/search-and-relate`,
      {
        ...payload,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const getCancelReasons = async (id) => {
  return await axios.get(`${API_URL}/purchase-file-form-cancel-reason`, {
    headers: authHeader(),
  });
};

const getStatsGlobal = async (params) => {
  return await axios.post(`${API_URL}/notary/stats/global`, params, {
    headers: authHeader(),
  });
};

const changeRelationship = async (payload) => {
  return await axios.put(
    `${API_URL}/management/form-08/relationship`,
    payload,
    {
      headers: authHeader(),
    }
  );
};

export default {
  addBatch,
  addComment,
  changeRelationship,
  edit,
  getAll,
  getById,
  getCancelReasons,
  getDataById,
  getLastNumber,
  getStatsGlobal,
  searchAndRelate,
};
