import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
// import moment from "moment";
import Card from "components/Card/Card.jsx";
// import { constants } from "../../variables/constants";

const InformationAgent = (props) => {
  const [information, setInformation] = useState(false);

  useEffect(() => {
    setInformation(props.data);
  }, [props.data]);

  return (
    <div className="agent">
      <Card
        ctTableFullWidth
        ctTableResponsive
        title={information.name}
        // legend={<strong>Tomas Totales: {information ? information.closed + information.open + information.others : '-'}</strong>}
        content={
          (props.loading)
            ? 'Loading...'
            : <Table striped hover>
              <tbody>
                <tr style={{ backgroundColor: '#FB404B' }}>
                  <td><strong>Abiertas</strong></td>
                  <td>
                    {information ? information.open : '-'}
                  </td>
                  <td>
                    {(information)
                      ? `(${((information.open / (information.closed + information.open)) * 100).toFixed(2)}%)`
                      : ''}
                  </td>
                </tr>
                <tr style={{ backgroundColor: '#87CB16' }}>
                  <td><strong>Cerradas</strong></td>
                  <td>
                    {information ? information.closed : '-'}
                  </td>
                  <td>
                    {(information)
                      ? `(${((information.closed / (information.closed + information.open)) * 100).toFixed(2)}%)`
                      : ''}
                  </td>
                </tr>
                <tr>
                  <td><strong>Total</strong></td>
                  <td colSpan="2">
                    {information ? information.closed + information.open : '-'}
                  </td>
                </tr>
                <tr>
                  <td style={{width: '200px'}}><strong>Porcentaje Cumplido</strong></td>
                  <td colSpan="2">
                    {information ? `${((information.closed + information.open) * 100 / props.objetive).toFixed(2)}%` : '-'}
                  </td>
                </tr>
                <tr style={{ backgroundColor: '#E0A510' }}>
                  <td><strong>Reingresos</strong></td>
                  <td colSpan="2">
                    {information ? information.reopen : '-'}
                  </td>
                </tr>
                <tr style={{ backgroundColor: '#1E90FF' }}>
                  <td><strong>Historicos</strong></td>
                  <td colSpan="2">
                    {information ? information.history : '-'}
                  </td>
                </tr>
              </tbody>
            </Table>
        }
      />
    </div>
  );
};

export default InformationAgent;
