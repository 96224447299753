export const head = [
  { text: "id", field: "id", model: null },
  { text: "Stock ID", field: "vehicle_id", model: null },
  { text: "Dominio", field: "plate_number", model: null },
  { text: "Estado BAC", field: "status_id", model: null },
  { text: "Link Documentación", field: null, model: null },
  { text: "Adenda", field: "adenda", model: null },
  { text: "Registro", field: "issuer_entity_tentative_id", model: null },
  { text: "Gestor", field: "person_in_charge_id", model: null },
  { text: "Estado del banco", field: "bank_status_id", model: null },
  { text: "Chasis", field: "vehicle_chasis", model: null },
  {
    text: "Antigüedad (meses)",
    field: "updatedAt",
    model: "purchase,purchase_file_form08,forms_status_history",
  },
  {
    text: "Fecha de firma 08 compra",
    field: "updatedAt",
    model: "purchase,purchase_file_form08,forms_status_history",
  },
  {
    text: "Entrega Logistica",
    field: "updatedAt",
    model: "bacs_purchase_logistica",
  },
  {
    text: "Presentation Registro Real",
    field: "updatedAt",
    model: "bacs_purchase_presentacion",
  },
  {
    text: "Recepción de cédulas",
    field: "updatedAt",
    model: "bacs_purchase_retiro",
  },
  { text: "", field: null, model: null },
];

export const headAgent = [
  { text: "id", field: "id", model: null },
  { text: "Stock ID", field: "vehicle_id", model: null },
  { text: "Dominio", field: "plate_number", model: null },
  { text: "Estado BAC", field: "status_id", model: null },
  { text: "Link Documentación", field: null, model: null },
  { text: "Adenda", field: "adenda", model: null },
  { text: "Registro", field: "issuer_entity_tentative_id", model: null },
  { text: "Estado del banco", field: "bank_status_id", model: null },
  { text: "Chasis", field: "vehicle_chasis", model: null },
  {
    text: "Antigüedad (meses)",
    field: "updatedAt",
    model: "purchase,purchase_file_form08,forms_status_history",
  },
  {
    text: "Fecha de firma 08 compra",
    field: "updatedAt",
    model: "purchase,purchase_file_form08,forms_status_history",
  },
  {
    text: "Entrega Logistica",
    field: "updatedAt",
    model: "bacs_purchase_logistica",
  },
  {
    text: "Presentation Registro Real",
    field: "updatedAt",
    model: "bacs_purchase_presentacion",
  },
  {
    text: "Recepción de cédulas",
    field: "updatedAt",
    model: "bacs_purchase_retiro",
  },
  { text: "", field: null, model: null },
];