import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const save = (data) => {
  return axios.post(`${API_URL}/images/upload`, data, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const download = async (props) => {
  const url = `${API_URL}/images/download?limit=${props.countItems}&page=${props.currentPage}&vehicleId=${props.vehicleId}&plateNumber=${props.plateNumber}&section=${props.section}`;
  return await axios.get(url,
    { headers: authHeader() }
  );
};

export default {
  save,
  download,
};
