import { useState } from "react";

function useStatusModal() {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showRejectModal, setRejectModal] = useState({ id: null, show: false });

  const handleShowRejectModal = (id) => setRejectModal({ id: id, show: true });
  const handleCloseRejectModal = () =>
    setRejectModal({ id: null, show: false });
  const handleOpenAddModal = () => setShowAddModal(true);
  const handleCloseAddModal = () => setShowAddModal(false);
  const handleCloseEditModal = () => setShowEditModal(false);
  const handleOpenEditModal = () => setShowEditModal(true);

  return {
    showEditModal,
    showAddModal,
    showRejectModal,
    handleShowRejectModal,
    handleCloseRejectModal,
    handleOpenAddModal,
    handleCloseAddModal,
    handleCloseEditModal,
    handleOpenEditModal,
  };
}

export default useStatusModal;
