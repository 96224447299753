import React from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

const SearchInput = (props) => {   

    const submitHandler = (e) => {
        e.preventDefault();
    }
    
    return (        
        <Form className="form-group" onSubmit={submitHandler}>
            <Input
                type="text"
                className="form-control"
                name={props.name}
                placeholder={props.placeholder}
                autoComplete="off"     
                onChange={props.onTyping}
            />
        </Form>
    );
};

export default SearchInput;
