import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

const Marker = (props) => {   
  const { description, city_province, address_complete, phone, lat, lng } = props;
  const [showTooltip, setShowTooltip] = useState(props.pinSelected);
  useEffect(() => {
    setShowTooltip(props.pinSelected);
  }, [props.pinSelected]);

  const handleShowToolTip = (issuerId) => {
    if (showTooltip === props.issuer_entity_id) {
      setShowTooltip(false); 
    } else {
      setShowTooltip(issuerId);
    }
  }

  return (
    <div className="google-single-point" onClick={() => handleShowToolTip(props.issuer_entity_id)}>
      {(showTooltip === props.issuer_entity_id) && (
        <div className="tootltip">
          <div className="content">              
            <div><Button onClick={() => handleShowToolTip(props.issuer_entity_id)} className="close btn btn-secondary"><span aria-hidden="true">×</span><span className="sr-only">Close</span></Button></div>
            <h4>{description}</h4>
            <div className="info">
              <p>{address_complete}</p>
              <p>{city_province}</p>
              <p>{phone}</p>
            </div>
            <div className="btn-routes">                      
              <div> <a href={`https://www.google.com/maps/dir/Your+Location/${lat},${lng}`} target="_blank" rel="noopener noreferrer">ver en Google</a> </div>
              <div>|</div>
              <div> <a href={`https://www.waze.com/ul?ll=${lat},${lng}&navigate=yes&zoom=17`} target="_blank" rel="noopener noreferrer">ver en Waze</a> </div>
            </div>
          </div>                  
        </div>
      )}
    </div>
  );
};

export default Marker;
