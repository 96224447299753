export const vtv_schedule_status = [
  { value: 1, text: "Apto" },
  { value: 2, text: "Rechazado" },
  { value: 3, text: "Condicional" },
  { value: 4, text: "A Reprogramar" },
  { value: 5, text: "Solicitado" },
  { value: 6, text: "Expirado" },
];

export const statusVtv = [
  { value: 1, text: "Vencido" },
  { value: 2, text: "Vigente" },
  { value: 3, text: "Próximo a Vencer" },
];
