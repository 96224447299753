import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAll = async (props) => {
  return await axios.get(`${API_URL}/functions?limit=${props.countItems}&page=${props.currentPage}&sortField=${props.sortField}&sortType=${props.sortType}`, { headers: authHeader() });
};

const getById = async (id) => {
  return await axios.get(`${API_URL}/functions/${id}`, { headers: authHeader() });
};

const create = (payload) => {
  return axios.post(`${API_URL}/functions`, { ...payload }, { headers: authHeader() })
  .then((response) => {
      if (response.data.success) {
          return response.data;
      } else {
          throw new Error(response.data.message);
      }
  });
};

const edit = (payload) => {
  return axios.put(`${API_URL}/functions`, { ...payload }, { headers: authHeader() })
  .then((response) => {
      if (response.data.success) {
          return response.data;
      } else {
          throw new Error(response.data.message);
      }
  });
};

const del = (id) => {
  return axios.post(`${API_URL}/functions/delete`, {id}, { headers: authHeader() })
  .then((response) => {
      if (response.data.success) {
          return response.data;
      } else {
          throw new Error(response.data.message);
      }
  });
};

export default {
  getAll,
  create,
  getById,
  edit,
  del
};
