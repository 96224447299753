import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getPlateData = async (data) => {
  return axios.post(`${API_URL}/plate-validate`, data, { headers: authHeader() })
  .then(response => {
    if (response && response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  });
};

export default {
  getPlateData  
};
