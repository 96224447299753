import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-validation/build/form";
import CarInfo from "./carInfo";
import { useConfirmationAlert, useToast } from "../../../../hooks/index";
import SellsFinanced from "../../../../services/sells/sellsFinanced.service";
import { Comments } from "../../../Comments/index";
import ConfirmationAlert from "../../../../components/ConfirmationAlert/confirmationAlert";
import {
  DatePickerField,
  SelectField,
} from "../../../../components/Form/index";
import { sellEmployees } from "../../../../utils/Service";
import { financed_status } from "../../../../variables/sells";
import { constants, sells_status } from "../../../../variables/constants";

const confirmTitle = "Ventas - Prendados";
const confirmMessage = "Al confirmar, el prendado cambiará al estado";

const profileAllowEdit = [constants.adminProfile];

const finalStatus = [
  financed_status.COMPLETED.value,
  financed_status.REJECTED.value,
];

const Edit = (props) => {
  const {
    data: {
      opportunity_id,
      plate_number,
      stock_id,
      status_sell,
      status_financed,
      financing_provider,
      financing_amount,
      person_in_charge_id,
      person_in_charge,
      estimated_date,
      restore_status,
      sell_comments,
      financed_comments,
      bacs_id,
    },
    handleClose,
    refresh,
    currentPermissions,
    profile,
  } = props;

  const initialFormState = {
    opportunity_id,
    estimated_date: estimated_date ? new Date(estimated_date) : null,
    person_in_charge_id,
    status_id: status_financed.id,
    comment_reason_id: null,
    comment: null,
  };

  const [fields, setFields] = useState(initialFormState);
  const [reasons, setReasons] = useState([]);
  const { confirmationModal, handleConfirmationModal } = useConfirmationAlert();
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const addToast = useToast();
  const nextStatus =
    status_financed.id === financed_status.REGISTRATION_COPY_REQUEST.value
      ? restore_status.description
      : financed_status[status_financed.description].next;

  useEffect(() => {
    getReasons();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDisabledSubmit(!fields.person_in_charge_id);
  }, [fields]);

  const getReasons = async () => {
    try {
      const response = await SellsFinanced.getAllReasons();
      if (response.data.success) {
        setReasons(response.data.response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const submit = async (next_status_id) => {
    const data = {
      ...fields,
      status_id: next_status_id,
    };

    try {
      const response = await SellsFinanced.update(opportunity_id, data);
      if (response.data.success) {
        addToast({
          variant: "success",
          header: confirmTitle,
          body: "Se actualizó la información correctamente",
        });
        handleClose();
        refresh();
      }
    } catch (error) {
      throw error;
    }
  };

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <CarInfo
            data={props.data}
            fields={fields}
            onSimpleFieldChange={onSimpleFieldChange}
            allowEdit={
              profileAllowEdit.includes(profile) &&
              currentPermissions?.permissions?.hasUpdate
            }
          />
          <div className="row">
            <div className="col-6">
              <SelectField
                label={"Persona responsable"}
                name={"person_in_charge_id"}
                onChange={(e) =>
                  onSimpleFieldChange(
                    "person_in_charge_id",
                    !!e.target.value ? parseInt(e.target.value, 10) : null
                  )
                }
                value={fields.person_in_charge_id}
                options={sellEmployees()}
              />
            </div>
            <div className="col-6">
              <DatePickerField
                label={"Fecha Estimada"}
                selected={fields.estimated_date}
                onChange={(date) => onSimpleFieldChange("estimated_date", date)}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
          <br />
          <Comments
            hideReasons
            comments={sell_comments}
            statusList={sells_status}
            title="COMENTARIOS VENTAS"
          />
          <Comments
            reasons={reasons}
            comments={financed_comments}
            statusList={financed_status}
            title="COMENTARIOS PRENDADOS"
            onChangeSelect={(e) =>
              onSimpleFieldChange("comment_reason_id", e.target.value)
            }
            onChangeObs={(e) => onSimpleFieldChange("comment", e.target.value)}
            hideReasons={finalStatus.includes(fields.status_id)}
          />
        </div>
        <br />
        {!finalStatus.includes(fields.status_id) ? (
          <div className="modal-footer">
            <div>
              <Button
                className="btn-fill pull-right btn btn-success btn-sm"
                onClick={() =>
                  handleConfirmationModal(
                    "",
                    () => submit(fields.status_id),
                    confirmTitle,
                    "Al confirmar, se actualizaran los datos. ¿Esta seguro?"
                  )
                }
              >
                Actualizar
              </Button>
            </div>
            {fields.status_id !==
              financed_status.REGISTRATION_COPY_REQUEST.value && (
              <div className="row">
                <Button
                  className="btn-fill pull-right btn btn-danger btn-sm"
                  onClick={() =>
                    handleConfirmationModal(
                      financed_status.REGISTRATION_COPY_REQUEST.text,
                      () =>
                        submit(financed_status.REGISTRATION_COPY_REQUEST.value),
                      confirmTitle,
                      confirmMessage
                    )
                  }
                  disabled={
                    !fields.comment?.length || !fields.comment_reason_id
                  }
                >
                  {financed_status.REGISTRATION_COPY_REQUEST.text}
                </Button>
              </div>
            )}
            {financed_status[status_financed.description].observed && (
              <div className="row">
                <Button
                  className="btn-fill pull-right btn btn-warning btn-sm"
                  onClick={() =>
                    handleConfirmationModal(
                      financed_status[
                        financed_status[status_financed.description].observed
                      ].text,
                      () =>
                        submit(
                          financed_status[
                            financed_status[status_financed.description]
                              .observed
                          ].value
                        ),
                      confirmTitle,
                      confirmMessage
                    )
                  }
                  disabled={
                    !fields.comment?.length || !fields.comment_reason_id
                  }
                >
                  {
                    financed_status[
                      financed_status[status_financed.description].observed
                    ].text
                  }
                </Button>
              </div>
            )}
            {nextStatus && (
              <div className="row">
                <Button
                  className="btn-fill pull-right btn btn-primary btn-sm"
                  onClick={() =>
                    handleConfirmationModal(
                      financed_status[nextStatus].text,
                      () => submit(financed_status[nextStatus].value),
                      confirmTitle,
                      confirmMessage
                    )
                  }
                  disabled={disabledSubmit}
                >
                  {financed_status[nextStatus].text}
                </Button>
              </div>
            )}
          </div>
        ) : null}
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
      </Form>
    </div>
  );
};

export default Edit;
