import axios from "axios";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAll = async () => {
  return await axios.get(`${API_URL}/bacs-requirements`, {
    headers: authHeader(),
  });
};

const getAllByBac = async (id) => {
  return await axios.get(`${API_URL}/bacs-requirements/${id}`, {
    headers: authHeader(),
  });
};

const insert = (id, requirements) => {
  return axios
    .post(
      `${API_URL}/bacs-requirements/${id}`,
      { requirements },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
        return response.data;
      }
      throw new Error(response.data.message);
    });
};

const updateOne = (id, bacs_id, status) => {
  return axios
    .put(
      `${API_URL}/bacs-requirements/${id}/${bacs_id}/${status}`,
      {},
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
        return response.data;
      }
      throw new Error(response.data.message);
    });
};

const updateAll = (id, requirements) => {
  return axios
    .patch(
      `${API_URL}/bacs-requirements/${id}`,
      { requirements },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
        return response.data;
      }
      throw new Error(response.data.message);
    });
};

export default {
  getAll,
  getAllByBac,
  insert,
  updateOne,
  updateAll,
};
