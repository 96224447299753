import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAllGeneral = async (params) => {
  const url = `${API_URL}/general/list`;
  return await axios.post(url, params, { headers: authHeader() });
}

const getStatsGlobal = async (params) => {
  const url = `${API_URL}/general/stats/global`;
  return await axios.post(url, params, { headers: authHeader() }
  );
}

const getGeneralModalDataById = async (id) => {
  return await axios.get(`${API_URL}/general/${id}`, { headers: authHeader() });
};

const getInquiryById = async (id) => {
  return await axios.get(`${API_URL}/general/inquiry/${id}`, { headers: authHeader() });
};


export default {
  getAllGeneral,
  getStatsGlobal,
  getGeneralModalDataById,
  getInquiryById,
};
