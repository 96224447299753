import axios from "axios";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAllNotaryCollege = async () => {
  const url = `${API_URL}/notary-college/list`;
  return await axios.get(url, { headers: authHeader() });
};

export default {
    getAllNotaryCollege,
};
