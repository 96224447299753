import { issuerEntitiesAll, zones } from "utils/Service";
import { purchaseStatus, yesNoOptions } from "variables/constants";

const dropdownStatus = Object.entries(purchaseStatus)
  .filter((s) => s[1].group === "take")
  .map((s) => s[1]);

export const filtersTake = {
  plateNumber: {
    type: "text",
    name: "plateNumber",
    placeholder: "Dominio",
    value: null,
    size: 2,
  },
  receptionDate: {
    type: "date",
    name: "receptionDate",
    placeholder: "Recepción",
    value: null,
    size: 3,
  },
  signedForm08: {
    type: "date",
    name: "signedForm08",
    placeholder: "Firma 08",
    value: null,
    size: 3,
  },
  zone: {
    type: "select",
    name: "zone",
    options: zones(),
    placeholder: "Seleccione una zona",
    value: "",
    size: 2,
  },
  lossRequest: {
    type: "select",
    name: "lossRequest",
    options: yesNoOptions,
    placeholder: "Solicitud de extravío",
    value: "",
    size: 2,
  },
  issuerEntityId: {
    type: "multiSelect",
    name: "issuerEntityId",
    options: issuerEntitiesAll(),
    placeholder: "Seleccione un registro",
    value: [],
    size: 6,
  },
  status: {
    type: "multiSelect",
    name: "status",
    options: dropdownStatus,
    placeholder: "Estado",
    value: [],
    size: 6,
  },
};
