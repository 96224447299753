import {
  DatePickerField,
  InputField,
  SelectField,
} from "components/Form/index";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-validation/build/form";
import { badgeGeneric } from "utils/Badges";
import { delivery_types } from "variables/constants";
import SellsService from "../../../services/sells/sells.service";
import { useToast } from "../../../hooks/index";
import { providerOptions } from "variables/sells";

const initialFormState = {
  opportunity_id: "",
  domain: "",
  delivery_type: "",
  status_ns_id: "",
  delivery_date: new Date(),
  payment_date: new Date(),
  provider: "",
  price: "",
  car_trade_in: "",
  financed: "",
};

const PAYMENT_METHOD = {
  FINANCED: 2,
  CASH: 1,
};

const yesOrNotOptions = [
  { text: "Si", value: 1 },
  { text: "No", value: 0 },
];

const Add = (props) => {
  const [fields, setFields] = useState(initialFormState);
  const [category, setCategory] = useState([]);
  const [opportunityId, setOpportunityId] = useState(null);
  const [oppIdLabel, setOppIdLabel] = useState("ID Opp");
  const addToast = useToast();

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      const response = await SellsService.getCategories();
      if (response.status === 200) {
        setCategory(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  useEffect(() => {
    if (opportunityId) {
      setOppIdLabel("ID Opp");
      SellsService.getById(opportunityId)
        .then((response) => {
          if (response.data.success) {
            setOppIdLabel(
              <>
                <span>ID Opp</span>
                {badgeGeneric("danger", "No disponible")}
              </>
            );
          }
        })
        .catch((error) => {
          setOppIdLabel(
            <>
              <span>ID Opp</span>
              {badgeGeneric("success", "Disponible")}
            </>
          );
        });
    } else {
      setOppIdLabel("ID Opp");
    }
  }, [opportunityId]);

  const submit = async () => {
    try {
      const data = {
        opportunity_id: fields.opportunity_id,
        transaction_date: fields.transaction_date,
        financing_provider: fields.provider,
        mirror_delivery_date: fields.delivery_date,
        delivery_date: fields.delivery_date,
        delivery_type: fields.delivery_type,
        auto_trade_in: fields.car_trade_in,
        vehicle_price: fields.price,
        payment_date: fields.payment_date,
        plate_number: fields.domain,
        category_id: fields.status_ns_id,
        financed: fields.financed,
        manual: true,
        status_id: 1,
        payment_method_id:
          fields.financed === 1 ? PAYMENT_METHOD.FINANCED : PAYMENT_METHOD.CASH,
      };

      const response = await SellsService.addSell(data);
      if (response.status === 200) {
        addToast({
          variant: "success",
          header: "Ventas",
          body: "Se creo la venta correctamente",
        });
        props.handleClose();
        props.refresh();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="modalChecklist">
      <Form>
        <div className="row">
          <InputField
            label={"Dominio"}
            name={"dominio"}
            onChange={(e) => onSimpleFieldChange("domain", e.target.value)}
            value={fields.domain}
            maxLength={"10"}
            style={{ textTransform: "uppercase" }}
          />
          <InputField
            label={oppIdLabel}
            name={"id_opp"}
            onChange={(e) => {
              onSimpleFieldChange("opportunity_id", e.target.value);
              setOpportunityId(e.target.value);
            }}
            value={fields.opportunity_id}
          />
          <SelectField
            label={"Tipo de entrega"}
            name={"type_of_delivery"}
            value={fields.delivery_type}
            options={delivery_types}
            onChange={(e) =>
              onSimpleFieldChange(
                "delivery_type",
                !!e.target.value ? e.target.value : null
              )
            }
          />
        </div>
        <div className="row">
          <SelectField
            label={"Estado NS"}
            name={"status"}
            value={fields.status_ns_id}
            options={category}
            onChange={(e) =>
              onSimpleFieldChange(
                "status_ns_id",
                !!e.target.value ? e.target.value : null
              )
            }
          />
          <DatePickerField
            onChange={(date) => onSimpleFieldChange("delivery_date", date)}
            selected={fields.delivery_date}
            label={"Fecha de entrega"}
          />
          <SelectField
            label={"Financiado"}
            options={yesOrNotOptions}
            onChange={(e) =>
              onSimpleFieldChange(
                "financed",
                !!e.target.value ? parseInt(e.target.value, 10) : null
              )
            }
            value={fields.financed}
            name={"financed"}
          />
        </div>
        <div className="row">
          <InputField
            label={"Valor del auto"}
            name={"price"}
            onChange={(e) => onSimpleFieldChange("price", e.target.value)}
            value={fields.price}
          />
          <DatePickerField
            onChange={(date) => onSimpleFieldChange("payment_date", date)}
            selected={fields.payment_date}
            label={"Fecha de cobro"}
          />
          <SelectField
            label={"Proveedor"}
            options={providerOptions}
            onChange={(e) =>
              onSimpleFieldChange(
                "provider",
                !!e.target.value ? e.target.value : null
              )
            }
            value={fields.provider}
            name={"provider"}
          />
        </div>
        <div className="row">
          <span></span>
          <span></span>
          <SelectField
            label={"Auto trade In"}
            options={yesOrNotOptions}
            onChange={(e) =>
              onSimpleFieldChange(
                "car_trade_in",
                !!e.target.value ? e.target.value : null
              )
            }
            value={fields.car_trade_in}
            name={"AutoTradeIn"}
          />
        </div>
        <br />
        <div className="modal-footer">
          <div>
            <Button
              className="btn-fill pull-right btn btn-warning btn-sm"
              onClick={props.handleClose}
            >
              Cancelar
            </Button>
          </div>
          <div className="row">
            <Button
              className="btn-fill pull-right btn btn-primary btn-sm"
              onClick={submit}
            >
              Aceptar
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Add;
