import React from "react";
import { Badge } from "react-bootstrap";
import {
  status08,
  constants,
  legalizeStatus,
  purchaseStatus,
} from "../variables/constants";

export const status08Badge = (status) => {
  const { color, text, text_color } = Object.keys(status08).includes(status)
    ? status08[status]
    : { color: "danger", text: "indefinido" };
  if (text === "indefinido") {
    return null;
  }
  return (
    <Badge className={`capitalize`} bg={color} text={text_color}>
      {text}
    </Badge>
  );
};

export const legalizeBadge = (status) => {
  const { color, text, text_color } = Object.keys(legalizeStatus).includes(
    status
  )
    ? legalizeStatus[status]
    : { color: "danger", text: "white" };
  if (text === "indefinido") {
    return null;
  }
  return (
    <Badge className={`capitalize`} bg={color} text={text_color}>
      {text}
    </Badge>
  );
};

export const badgeGeneric = (variant, text) => (
  <Badge bg={variant}>{text}</Badge>
);

export const badgeGenericBoolean = (value) =>
  badgeGeneric(!!value ? "success" : "danger", !!value ? "SI" : "NO");

export const badgeGenericDiv = (color, text) => (
  <div className={`badge ${color} capitalize`}>{text}</div>
);

export const parseRemaining = (item) => {
  if (!item.success || item.success === undefined) {
    return null;
  }
  const variant = item.response.type === "late" ? "danger" : "success";
  return badgeGeneric(variant, item.response.time);
};

export const remainingTimeBadge = (remaining_time) => {
  if (remaining_time === null) {
    return null;
  }
  if (remaining_time.success && typeof remaining_time.success !== "undefined") {
    const variant =
      remaining_time.response.type === "late" ? "danger" : "success";
    return badgeGeneric(variant, remaining_time.response.time);
  } else {
    // Fix para cuando solo viene el form_08 (StatusForm08List.js)
    if (!remaining_time.response.purchase_form) {
      remaining_time.response = {
        ...remaining_time.response,
        purchase_form: {
          success: true,
        },
      };
    }

    //!form 08 and !purchase form
    if (
      !remaining_time.response.form_08.success &&
      !remaining_time.response.purchase_form.success
    ) {
      const variant_08 =
        remaining_time.response.form_08.type === "late" ? "danger" : "success";
      const variant_purchase =
        remaining_time.response.purchase_form.type === "late"
          ? "danger"
          : "success";
      return (
        <>
          {badgeGeneric(
            variant_purchase,
            remaining_time.response.purchase_form.time
          )}
          {badgeGeneric(variant_08, remaining_time.response.form_08.time)}
        </>
      );
    }
    //form 08 and !purchase form
    else if (
      remaining_time.response.form_08.success &&
      !remaining_time.response.purchase_form.success
    ) {
      const variant =
        remaining_time.response.purchase_form.type === "late"
          ? "danger"
          : "success";
      return badgeGeneric(variant, remaining_time.response.purchase_form.time);
    }

    //!form 08 and purchase form
    else if (
      !remaining_time.response.form_08.success &&
      remaining_time.response.purchase_form.success
    ) {
      const variant =
        remaining_time.response.form_08.type === "late" ? "danger" : "success";
      return badgeGeneric(variant, remaining_time.response.form_08.time);
    }
  }
};

export const parseRemainingTime = (body) => {
  let data = body;
  data.forEach((name, index) => {
    if (name.remaining_time === null) {
      data[index].remaining_time = null;
    } else {
      if (
        name.remaining_time.success &&
        name.remaining_time.success !== undefined
      ) {
        const variant =
          name.remaining_time.response.type === "late" ? "danger" : "success";
        data[index].remaining_time = badgeGeneric(
          variant,
          name.remaining_time.response.time
        );
      } else {
        // Fix para cuando solo viene el form_08 (StatusForm08List.js)
        if (!name.remaining_time.response.purchase_form) {
          name.remaining_time.response = {
            ...name.remaining_time.response,
            purchase_form: {
              success: true,
            },
          };
        }

        //!form 08 and !purchase form
        if (
          !name.remaining_time.response.form_08.success &&
          !name.remaining_time.response.purchase_form.success
        ) {
          const variant_08 =
            name.remaining_time.response.form_08.type === "late"
              ? "danger"
              : "success";
          const variant_purchase =
            name.remaining_time.response.purchase_form.type === "late"
              ? "danger"
              : "success";
          data[index].remaining_time = (
            <>
              {badgeGeneric(
                variant_purchase,
                name.remaining_time.response.purchase_form.time
              )}
              {badgeGeneric(
                variant_08,
                name.remaining_time.response.form_08.time
              )}
            </>
          );
        }

        //form 08 and !purchase form
        else if (
          name.remaining_time.response.form_08.success &&
          !name.remaining_time.response.purchase_form.success
        ) {
          const variant =
            name.remaining_time.response.purchase_form.type === "late"
              ? "danger"
              : "success";
          data[index].remaining_time = badgeGeneric(
            variant,
            name.remaining_time.response.purchase_form.time
          );
        }

        //!form 08 and purchase form
        else if (
          !name.remaining_time.response.form_08.success &&
          name.remaining_time.response.purchase_form.success
        ) {
          const variant =
            name.remaining_time.response.form_08.type === "late"
              ? "danger"
              : "success";
          data[index].remaining_time = badgeGeneric(
            variant,
            name.remaining_time.response.form_08.time
          );
        }
      }
    }
  });

  return data;
};

export const parseSDP = (body) => {
  let data = body;
  data.forEach((name, index) => {
    data[index].plate_number = SDPBadge(name.plate_number, name.sdp);
  });
  return data;
};

export const SDPBadge = (plate_number, sdp) =>
  sdp ? (
    <>
      <span>{plate_number}</span>
      <br />
      <Badge bg="warning" text="dark">
        {constants.sdp}
      </Badge>
    </>
  ) : (
    plate_number
  );

export const twoBadges = (plate_number, second_badge) =>
  second_badge ? (
    <>
      <span>{plate_number}</span>
      <br />
      <Badge bg="warning">{second_badge}</Badge>
    </>
  ) : (
    plate_number
  );

export const alertBadge = (alert) => {
  const alerts = {
    success: "success",
    warning: "warning",
    alert: "danger",
  };

  return (
    <Badge className="alert-list" bg={alerts[alert] ?? "info"}>
      &nbsp;
    </Badge>
  );
};

export const parseAlerts = (body) => {
  const alerts = {
    success: "success",
    warning: "warning",
    alert: "danger",
  };

  let data = body;
  data.forEach((name, index) => {
    data[index].alert = (
      <Badge className="alert-list" bg={alerts[name.alert] ?? "info"}>
        &nbsp;
      </Badge>
    );
  });

  return data;
};

export const parseStatus08 = (body) => {
  let data = body;
  data.forEach((name, index) => {
    data[index].form_08 = status08Badge(name.form_08);
  });
  return data;
};

export const parseStatus = (body) => {
  let data = body;
  data.forEach((name, index) => {
    data[index].status = statusBadge(name.status);
  });

  return data;
};

export const statusBadge = (status) => {
  const statusElem = Object.entries(purchaseStatus).find(
    (s) => s[1].value === status
  );
  const { color, text } = statusElem
    ? statusElem[1]
    : { color: "badge-danger", text: "indefinido" };

  return badgeGenericDiv(color, text);
};

export const parseDiffDate = (body) => {
  let data = body;
  data.forEach((name, index) => {
    data[index].diff_days = diffDateBadge(name.diff_days);
  });
  return data;
};

export const diffDateFormula = (days) => {
  // Sin datos
  if (!days) {
    return "secondary";
  }
  // 0-6 Verde
  if (days < 7) {
    return "success";
  }
  if (days <= 10) {
    // 7-10 Amarillo
    return "warning";
  }
  // mayor a 10 Rojo
  return "danger";
};

export const diffDateBadge = (days) =>
  days ? (
    <Badge className={`capitalize`} bg={diffDateFormula(days)}>
      {days}
    </Badge>
  ) : (
    badgeGeneric("secondary", 0)
  );

export const plateNumberBadge = (text) => (
  <Badge className="plate_number" bg="warning" text="dark">
    {text}
  </Badge>
);

export const sinceDaysBadge = (days) => {
  const color = days >= 7 ? "badge-danger" : "badge-success";
  return <Badge className={`badge ${color} capitalize`}>{days}</Badge>;
};

export const genericBadge = (object, field, cutStatus = false) => {
  const { color, text } = Object.keys(object).includes(field)
    ? object[field]
    : { color: "badge-danger", text: "indefinido" };
  if (text === "indefinido") {
    return null;
  }

  return badgeGenericDiv(color, !cutStatus ? text : text.substring(14));
};

export default {};
