import { useState } from "react";

function useConfirmationAlert() {
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    title: "",
    message: "",
    handleAction: null,
    handleClose: null,
  });

  const handleConfirmationModal = (
    state,
    handleAction,
    title = "¿Desea cambiar el estado de la toma?",
    message = "Al confirmar, la toma cambiará al estado"
  ) => {
    setConfirmationModal({
      open: true,
      title: title,
      message: `${message} ${state}`,
      handleAction: () => handleAction(),
      handleClose: () =>
        setConfirmationModal({
          open: false,
          title: "",
          message: "",
          handleAction: null,
          handleClose: null,
        }),
    });
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModal({
      open: false,
      title: "",
      message: "",
      handleAction: null,
      handleClose: null,
    });
  };

  const handlePreConfirmationPurchaseModal = (
    status_name,
    value,
    next_status,
    changeStatusMethod
  ) => {
    handleConfirmationModal(next_status, () =>
      changeStatusMethod(status_name, value)
    );
  };

  return {
    confirmationModal,
    handleConfirmationModal,
    handleCloseConfirmationModal,
    handlePreConfirmationPurchaseModal,
    setConfirmationModal,
  };
}
export default useConfirmationAlert;
