export const headSuspended = [
    { text: "Dominio", field: "plate_number", model: null },
    { text: "Stock ID", field: "vehicle_id", model: null },
    { text: "Estado", field: "status_id", model: null },
    { text: "Fecha suspension", field: "suspended_date", model: null },
    { text: "Persona Responsable", field: "person_in_charge_id", model: null },
    { text: "Dias suspendidos", field: "suspended_period", model: null },
    { text: "Aging", field: null, model: null },
    { text: "Días en tramite", field: null, model: null },
    { text: "Fecha Manual", field: "past_suspended_date", model: null },
    { text: "", field: null, model: null },
  ];