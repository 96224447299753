import { useEffect, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import Form from "react-validation/build/form";
import Multiselect from "multiselect-react-dropdown";

import auditService from "../../../services/audit.service";
import managementService from "../../../services/management.service";
import purchaseSuspendedService from "../../../services/purchase/suspended.service";
import purchaseRequirementsService from "../../../services/purchase/requirements.service";

import { Comments } from "../../../components/Comments";
import ConfirmationAlert from "../../../components/ConfirmationAlert/confirmationAlert";
import Requirement from "../../../components/RequirementCheck";
import CustomAccordion from "../../../components/Accordion";
import { diffDateFormula } from "../../../utils/Badges";
import { purchaseComment } from "../../../utils/Comments";
import { purchaseSuspendedRequirements } from "../../../utils/Service";
import { useConfirmationAlert, useToast } from "../../../hooks";
import {
  activate_button_suspended_purchase,
  constants,
  purchaseStatus,
  purchase_status_select,
} from "../../../variables/constants";
import { DatePickerField, InputField } from "../../Form/index";
import { BadgeField } from "../Sells/components";
import { useGetStatus } from "hooks";

const Edit = (props) => {
  const {
    data: {
      response: {
        plate_number,
        status,
        suspended_date,
        suspended_period,
        restore_status,
        stock_id,
        past_suspended_date,
        diff_days,
        suspended_days,
        reception_place,
        suspended_reason,
      },
    },
    handleClose,
    refresh,
  } = props;

  const initialFormState = {
    comment_reason_id: null,
    comment: null,
    stock_id,
  };
  const [fields, setFields] = useState(initialFormState);
  const [comments, setComments] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [currentRequirements, setCurrentRequirements] = useState([]);
  const [totalRequirements, setTotalRequirements] = useState();
  const { status: currentStatus } = useGetStatus(status, purchaseStatus);
  const { confirmationModal, handleConfirmationModal } = useConfirmationAlert();
  const addToast = useToast();

  const getReasons = async () => {
    try {
      // Motivos de los comentarios de suspendidos
      const response = await managementService.getSuspendedCommentReasons();
      if (response.status === 200) {
        setReasons(response.data.response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getComments = async () => {
    await auditService
      .getHistory(stock_id, "comment_summary")
      .then((response) => {
        setComments(response.data.response);
      });
  };

  const getRequirements = () => {
    purchaseRequirementsService
      .getAllById(stock_id, "suspended")
      .then((response) => {
        if (response.data.success) {
          setCurrentRequirements(response.data.response);
          setTotalRequirements(
            response.data.response.filter((r) => r.status).length
          );
        }
      });
  };

  const updateRequirements = (id, status) => {
    const idx = currentRequirements.findIndex((r) => r.requirement_id === id);
    currentRequirements[idx].status = status;
    setTotalRequirements(currentRequirements.filter((r) => r.status).length);
  };

  useEffect(() => {
    getReasons();
    getComments();
    getRequirements();
  }, []);

  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const submit = async () => {
    const arrPromises = [];
    // Actualizamos los datos de la toma si corresponde
    if (fields?.comment?.length && fields.comment_reason_id) {
      const data = {
        status_id: activate_button_suspended_purchase.includes(
          fields.comment_reason_id
        )
          ? restore_status
          : status,
        comment: fields.comment,
        comment_reason_id: fields.comment_reason_id,
      };

      const promise_vehicle = new Promise((resolve, reject) => {
        purchaseSuspendedService.update(stock_id, data).then((response) => {
          if (response.status === 200) {
            resolve("Se actualizó la información correctamente");
          }
          reject("Fallo la actualización de los datos");
        });
      });

      arrPromises.push(promise_vehicle);
    }

    // Si agregó nuevos requisitos
    if (fields.requirements?.length) {
      const promise_requirements = new Promise((resolve, reject) => {
        purchaseRequirementsService
          .insert(stock_id, fields.requirements)
          .then((response) => {
            if (response.success) {
              resolve("Se crearon los requisitos");
            } else {
              reject("Fallo la creación de los requisitos");
            }
          });
      });

      arrPromises.push(promise_requirements);
    }

    Promise.all(arrPromises)
      .then((values) => {
        values.map((v) =>
          addToast({
            variant: "success",
            header: "Toma - Suspendidos",
            body: v,
          })
        );
        refresh();
        handleClose();
      })
      .catch((reason) => {
        addToast({
          variant: "danger",
          header: "Toma - Suspendidos",
          body: reason,
        });
        refresh();
        handleClose();
      });
  };

  const handleChangeStatus = () => {
    const change_status = purchase_status_select.filter(
      (x) => x.value === restore_status
    )[0];
    handleConfirmationModal(change_status.text, submit);
  };

  return (
    <div className="container">
      <Form>
        <div className="row">
          <>
            <div className="row">
              <BadgeField
                label={"Dominio"}
                text={plate_number}
                badgeClass={"plate_number"}
                variant={"warning"}
              />
              <BadgeField
                label={"Stock ID"}
                text={stock_id}
                badgeClass={`plate_number`}
                variant={"info"}
              />
              <BadgeField
                label={"Estado"}
                text={currentStatus.text}
                badgeClass={`badge ${currentStatus.color} capitalize`}
                style={{ width: "100%", padding: "13px" }}
              />
            </div>
            <div className="row">
              <BadgeField
                label={"Días en tramite"}
                text={diff_days}
                badgeClass={`capitalize plate_number`}
                variant={diffDateFormula(diff_days)}
              />
              <BadgeField
                label={"Días Suspendidos"}
                text={suspended_days}
                badgeClass={`capitalize plate_number`}
                variant={"info"}
              />
              <BadgeField
                label={"Lugar de toma"}
                text={reception_place}
                badgeClass={`capitalize plate_number`}
                variant={"info"}
              />
            </div>
          </>
        </div>
        {!!suspended_reason ? (
          <div className="row">
            <InputField
              label={"Motivo Suspensión"}
              name={"suspended_reason_id"}
              value={`${suspended_reason}`}
              disabled={true}
            />
          </div>
        ) : null}
        <div className="row">
          <DatePickerField
            selected={suspended_date == null ? null : new Date(suspended_date)}
            disabled={true}
            label={"Fecha suspension"}
          />
          <DatePickerField
            selected={
              past_suspended_date == null ? null : new Date(past_suspended_date)
            }
            disabled={true}
            label={"Fecha Manual"}
          />
          <InputField
            label={"Días suspendidos"}
            name={"diff_days"}
            value={`${suspended_period}`}
            disabled={true}
          />
        </div>
        <Comments
          reasons={reasons}
          comments={purchaseComment(comments)}
          statusList={purchaseStatus}
          onChangeSelect={(e) =>
            onSimpleFieldChange(
              "comment_reason_id",
              e.target.value === constants.dropdownDefault
                ? null
                : e.target.value
            )
          }
          onChangeObs={(e) => onSimpleFieldChange("comment", e.target.value)}
        />
        <CustomAccordion
          title={`Requisitos Pendientes (${totalRequirements}/${currentRequirements.length})`}
        >
          <Stack direction="horizontal" gap={3} className="d-flex flex-wrap">
            {currentRequirements.map((c) => (
              <div>
                <Requirement
                  id={stock_id}
                  data={c}
                  onChange={updateRequirements}
                  serviceName={purchaseRequirementsService.updateOne}
                />
              </div>
            ))}
          </Stack>
        </CustomAccordion>
        <div className="row">
          <div className="col">
            <label className="capitalize">
              <strong>Requisitos Adicionales</strong>
            </label>
            <Multiselect
              options={purchaseSuspendedRequirements().filter(
                (r) =>
                  !currentRequirements
                    .map((c) => c.requirement_id)
                    .includes(r.value)
              )}
              onSelect={(selectedItem) => {
                onSimpleFieldChange("requirements", selectedItem);
              }}
              onRemove={(selectedItem) => {
                onSimpleFieldChange("requirements", selectedItem);
              }}
              displayValue="text"
              placeholder="Requisitos"
              closeIcon="cancel"
              selectionLimit="-1"
              showCheckbox={true}
              singleSelect={false}
            />
          </div>
        </div>
        <br />
        <div className="modal-footer">
          <div>
            <Button
              className="btn-fill pull-right btn btn-primary btn-sm"
              onClick={submit}
              disabled={
                (!fields?.comment?.length ||
                  fields.comment_reason_id == null ||
                  fields.comment_reason_id === constants.dropdownDefault ||
                  activate_button_suspended_purchase.includes(
                    fields.comment_reason_id
                  )) &&
                !fields?.requirements?.length
              }
            >
              Actualizar
            </Button>
          </div>
          <div>
            <Button
              className="btn-fill pull-right btn btn-success btn-sm"
              onClick={handleChangeStatus}
              disabled={
                !fields?.comment?.length ||
                fields.comment_reason_id == null ||
                fields.comment_reason_id === constants.dropdownDefault ||
                !activate_button_suspended_purchase.includes(
                  fields.comment_reason_id
                ) ||
                currentRequirements.length !== totalRequirements
              }
            >
              Alta suspensión
            </Button>
          </div>
        </div>
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
      </Form>
    </div>
  );
};

export default Edit;
