import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;
const API_URL_SELL = process.env.REACT_APP_API_SELLS_URL;

// Rutas para la API de ventas
const sell_types = [
  "ventas",
  "ventasAperturaFolio",
  "ventasPresentacion",
  "ventasPresentacionGestor",
  "ventasGeneral",
  "ventasSuspendidos",
  "ventasRechazados",
  "ventasPrendados",
];

const build = async (type, data) => {
  return await axios.post(
    `${sell_types.includes(type) ? API_URL_SELL : API_URL}/excel/${type}`,
    { ...data },
    { headers: authHeader(), responseType: "blob" }
  );
};

export default {
  build,
};
