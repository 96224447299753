import React, { useEffect, useState } from "react";
import GenericReject from "../../GenericReject/reject";
import { useToast } from "../../../../hooks";
import sellsFinancedService from "../../../../services/sells/sellsFinanced.service";

const STATUS_FINANCED_CANCEL = 9;

const initalFormState = {
  cancel_reason_id: "",
  cancel_reason: "",
};

const Cancel = (props) => {
  const [reasons, setReasons] = useState([]);
  const [fields, setFields] = useState(initalFormState);
  const addToast = useToast();
  const {
    handleClose,
    refresh,
    id,
    getCancelReasons = () => sellsFinancedService.getCancelReasons()
  } = props;

  const getAllCancelReasons = async () => {
    try {
      const response = await getCancelReasons();
      if (response.data.success) {
        setReasons(response.data.response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submit = async () => {
    const data = {
      status_id: STATUS_FINANCED_CANCEL,
      cancel_reason_id: fields.cancel_reason_id,
      cancel_reason: fields.cancel_reason,
      comment_reason_id: null,
      comment: null,
    };

    const response = await sellsFinancedService.update(id, data);
    if (response.status === 200) {
      addToast({
        variant: "success",
        header: "Ventas - Prendados",
        body: "Se rechazo correctamente",
      });
      handleClose();
      refresh();
    }
  };

  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  useEffect(() => {
    getAllCancelReasons();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {reasons.length > 0 && (
        <GenericReject
          reasons={reasons}
          handleOnChange={onSimpleFieldChange}
          submit={submit}
          fields={fields}
          {...props}
        />
      )}
    </div>
  );
};

export default Cancel;
