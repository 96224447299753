import { useState } from "react";
import { useDebounce } from "use-debounce";

function useFilters(filterFields, cleanPage) {
  const [filters, setFilters] = useState(filterFields);
  const [filters_debounced] = useDebounce(filters, 300);

  const onFilterChange = (filterName, filterValue) => {
    setFilters({
      ...filters_debounced,
      [filterName]: { ...filters_debounced[filterName], value: filterValue },
    });
    cleanPage();
  };

  const onFilterChangeArray = (filter_array) => {
    setFilters({
      ...filters_debounced,
      ...filter_array,
    });
  };

  return { onFilterChange, filters: filters_debounced, onFilterChangeArray };
}

export default useFilters;
