import {
  atLeastOneNumeric,
  minCharsOrMore,
  atLeastOneSpecialChar,
  atLeastOneLowercase,
  atLeastOneUppercase,
} from "variables/constants";

export const passwordTracker = (password) => ({
  uppercase: password.match(atLeastOneUppercase),
  lowercase: password.match(atLeastOneLowercase),
  number: password.match(atLeastOneNumeric),
  specialChar: password.match(atLeastOneSpecialChar),
  minCharsOrGreater: password.match(minCharsOrMore),
});

export default {};
