import { receptionPlaces, categories } from "../../../utils/Service";
import { yesNoOptions } from "../../../variables/constants";
import {
  statusVtv,
  vtv_schedule_status,
} from "../../../variables/vtv_constants";

export const filtersVtv = {
  plate_number: {
    type: "text",
    name: "plateNumber",
    placeholder: "Dominio",
    value: null,
    size: 2,
  },
  delivery_place: {
    type: "select",
    name: "delivery_place",
    options: receptionPlaces(),
    placeholder: "Ubicación",
    value: "",
    size: 2,
  },
  ot: {
    type: "select",
    name: "ot",
    options: yesNoOptions,
    placeholder: "OT",
    value: "",
    size: 2,
  },
  expired_date: {
    type: "date",
    name: "expired_date",
    placeholder: "Fecha de vencimiento",
    value: null,
    size: 2,
  },
  schedule_date: {
    type: "date",
    name: "schedule_date",
    placeholder: "Fecha turno VTV",
    value: null,
    size: 2,
  },
  status_vtv: {
    type: "select",
    name: "status_vtv",
    options: statusVtv,
    placeholder: "Estado VTV",
    value: "",
    size: 2,
  },
  status_sell: {
    type: "multiSelect",
    name: "status_sell",
    options: categories(),
    placeholder: "Estado de la venta",
    value: [],
    size: 5,
  },
  schedule_status: {
    type: "multiSelect",
    name: "schedule_status",
    options: vtv_schedule_status,
    placeholder: "Estado del turno VTV",
    value: [],
    size: 5,
  },
  hidden: {
    type: "select",
    name: "hidden",
    options: yesNoOptions,
    placeholder: "Ocultos",
    value: "no",
    size: 2,
  },
};
