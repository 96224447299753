import pkgJSON from "../../../package.json";

const Footer = () => (
  <footer className="footer">
    <nav className="pull-left"></nav>
    <p className="copyright pull-right">
      &copy; {new Date().getFullYear()} KAVAK (v
      {pkgJSON?.version || ""})
    </p>
  </footer>
);

export default Footer;
