import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL;

const getAll = async (year) => {
  return await axios.get(`${API_URL}/holiday?year=${year}`, { headers: authHeader() });
};

const add = (date) => {
  return axios.put(`${API_URL}/holiday`, { date }, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      }
      throw new Error(response.data.message);
    });
};

const del = (date) => {
  return axios.delete(`${API_URL}/holiday/${date}`, { headers: authHeader(), data: date })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

export default {
  getAll,
  add,
  del
};
