import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Card } from "../../components/Card/Card";
import GruposService from "../../services/groups.service";
import { useToast } from "../../hooks";
import styles from "../General/styles.module.scss";
import { updateLocalStorageCache } from "utils/Service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        El campo es obligatorio!
      </div>
    );
  }
};

const GroupsCreate = (props) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const form = useRef();
  const checkBtn = useRef();
  const addToast = useToast();

  const handSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      const params = {
        name,
        description,
      };
      GruposService.create(params).then(
        () => {
          addToast({
            variant: "success",
            header: "Grupos",
            body: "Se creó el nuevo grupo correctamente",
          });
          updateLocalStorageCache().then(() => {
            navigate("/admin/groups/list");
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          addToast({
            variant: "danger",
            header: "Grupos",
            body: resMessage,
          });
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <Container fluid>
        <div className={`content-filters form-group ${styles.lessHeight}`}>
          <Button href="/admin/groups/list" variant="outline-primary">
            Volver
          </Button>
        </div>
        <br />
        <Row>
          <Col md={12}>
            <Card
              content={
                <Form onSubmit={handSubmit} ref={form}>
                  <div className="form-group">
                    <label>Nombre</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Nombre"
                      onChange={(e) => setName(e.target.value)}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label>Descripción</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="description"
                      placeholder="description"
                      onChange={(e) => setDescription(e.target.value)}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group mt-3">
                    <button
                      className="btn btn-primary btn-fill pull-right"
                      disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Crear Grupo</span>
                    </button>
                  </div>

                  <CheckButton style={{ display: "none" }} ref={checkBtn} />

                  <div className="clearfix" />
                </Form>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GroupsCreate;
