import React, {useEffect, useState} from "react";
import {Table} from "react-bootstrap";
import Card from "components/Card/Card.jsx";

const ExtraData = (props) => {  
    const [extradata, setExtradata] = useState(false);

    useEffect(() => {
        setExtradata(props.data);        
    }, [props.data]);

    return (
        <div className="extradata-information">
            <Card
                ctTableFullWidth
                ctTableResponsive
                title="Extra Data"
                content={
                    (props.loading) 
                    ? 'Loading...'
                    : <Table striped hover>
                        <tbody>
                            <tr>
                                <td><strong>Valuación</strong></td>
                                <td>
                                    {(extradata && extradata.p_aut_valuacion) 
                                    ? extradata.p_aut_valuacion
                                    : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Vehiculo</strong></td>
                                <td>
                                    {(extradata && extradata.p_aut_fmm_ds) 
                                    ? extradata.p_aut_fmm_ds
                                    : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Año</strong></td>
                                <td>
                                    {(extradata && extradata.p_aut_anio) 
                                    ? extradata.p_aut_anio
                                    : '-'}
                                </td>
                            </tr>                                                       
                        </tbody>
                    </Table>
                }
            />
        </div>
    );
};

export default ExtraData;
