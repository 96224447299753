import { InputField } from "../../../../components/Form/index";
import { BadgeField } from "../../Sells/components/";
import React from "react";
const VehicleInfo = ({ fields }) => {
  return (
    <div className="row">
      <InputField
        value={fields.vehicle_id}
        label={"Stock ID"}
        name={"vehicle_id"}
        disabled={true}
      />
      <BadgeField
        label={"Dominio"}
        text={fields.domain}
        badgeClass={"plate_number"}
        variant={"warning"}
      />
      {fields.status_vtv ? (
        <BadgeField
          label={"Status"}
          text={fields.status_vtv}
          badgeClass={"plate_number"}
          variant={fields.status_vtv === "Vencido" ? "danger" : "success"}
        />
      ) : null}
    </div>
  );
};

export default VehicleInfo;
