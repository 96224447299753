import logo from "assets/img/logo_kavak_white.svg";
import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import authHeader from "../../services/auth-header";
import MenuService from "../../services/menu.service";
import { appName, constants, homePage } from "../../variables/constants";
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";
import styles from "./styles.module.scss";

const renderTooltip = (message, props) => {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {message}
    </Tooltip>
  );
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      menuItems: [],
      profile: null,
      minimizeSidebar: false,
    };
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  expandSidebar() {
    this.setState({ minimizeSidebar: !this.state.minimizeSidebar });
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    const user = JSON.parse(localStorage.getItem("user")) || null;
    if (!!user) {
      this.getMenu().then((menu) => {
        const menuItems = menu.data.menu.rows;
        this.setState({
          menuItems: menuItems,
          profile: user.data.user.profile.name.toLowerCase(),
        });
      });
    }
  }

  componentDidUpdate() {
    if (this.state.width <= 991 && this.state.minimizeSidebar === true) {
      this.setState({ minimizeSidebar: false });
    }
  }

  async getMenu() {
    return await MenuService.get();
  }

  render() {
    const sidebarBackground = {
      backgroundImage: "url(" + this.props.image + ")",
    };
    const { menuItems, profile, minimizeSidebar, width } = this.state;
    return (
      <div className={minimizeSidebar ? styles.containerSidebar : ""}>
        <div
          id="sidebar"
          className="sidebar"
          data-color={this.props.color}
          data-image={this.props.image}
        >
          {this.props.hasImage ? (
            <div className="sidebar-background" style={sidebarBackground} />
          ) : null}
          {
            <div className="logo">
              <div className="simple-text logo-mini">
                <a
                  href={homePage[profile] ?? homePage[constants.adminProfile]}
                  style={{ color: "#FFFFFF",textDecoration: "none" }}
                >
                  {!minimizeSidebar ? (
                    <>
                      <img src={logo} alt="logo_image" width="100px" />{" "}
                      {appName}
                    </>
                  ) : (
                    <img src={logo} alt="logo_image" width="60px" />
                  )}
                </a>
              </div>
            </div>
          }
          <div className="sidebar-wrapper">
            <ul className="nav">
              {width > 981 && (
                <li
                  onClick={() => this.expandSidebar()}
                  className={minimizeSidebar ? styles.icons : ""}
                >
                  <NavLink style={{textDecoration: "none"}} to="#">
                    {!minimizeSidebar ? (
                      <i className="fas fa-chevron-circle-left" />
                    ) : (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 150, hide: 200 }}
                        overlay={(props) => renderTooltip("Achicar", props)}
                      >
                        <i className="fas fa-chevron-circle-right" />
                      </OverlayTrigger>
                    )}
                    {!minimizeSidebar && <p>Achicar</p>}
                  </NavLink>
                </li>
              )}
              {this.state.width <= 991 ? (
                <AdminNavbarLinks logged={authHeader} />
              ) : null}
              {menuItems
                .sort((a, b) => a.position - b.position)
                .map((menuItem, key) => {
                  return (
                    <li
                      key={key}
                      className={minimizeSidebar ? styles.icons : ""}
                    >
                      <NavLink style={{textDecoration: "none"}} to={menuItem.link}>
                        {minimizeSidebar ? (
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 150, hide: 200 }}
                            overlay={(props) =>
                              renderTooltip(menuItem.title, props)
                            }
                          >
                            <i className={menuItem.icon} />
                          </OverlayTrigger>
                        ) : (
                          <i className={menuItem.icon} />
                        )}
                        {!minimizeSidebar && <p>{menuItem.title}</p>}
                      </NavLink>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
