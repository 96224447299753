import { renditionType } from "variables/constants";

export const filterFields = {
  stock_id: {
    type: "text",
    name: "stock_id",
    placeholder: "Stock ID",
    value: "",
    size: 4,
  },
  plate_number: {
    type: "text",
    name: "plate_number",
    placeholder: "Dominio",
    value: "",
    size: 4,
  },
  type: {
    type: "select",
    name: "type",
    options: renditionType,
    placeholder: "Tipo",
    value: "",
    size: 4,
  },
};
