import axios from "axios";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_SELLS_URL;

const update = async (id, data) => {
  return await axios.put(
    `${API_URL}/sells-withdraw-documentation/${id}`,
    data,
    {
      headers: authHeader(),
    }
  );
};

export default {
  update,
};
