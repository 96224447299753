import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

// const getAll = async (props) => {  
//   return await axios.get(`${API_URL}/vehicles?limit=${props.countItems}&page=${props.currentPage}`, { headers: authHeader() });
// };

const getVehicleById = async (id) => {
  return await axios.get(`${API_URL}/vehicle/${id}`, { headers: authHeader() });
};

const create = (payload) => {
  return axios.post(`${API_URL}/create-vehicle`, {
    ...payload,
  }, { headers: authHeader() })
  .then((response) => {
      if (response.data.success) {
          return response.data;
      } else {
          throw new Error(response.data.message);
      }
  });
};

// const edit = (payload) => {
//   return axios.put(`${API_URL}/vehicles`, {
//     ...payload,
//   }, { headers: authHeader() })
//   .then((response) => {
//       if (response.data.success) {
//           return response.data;
//       } else {
//           throw new Error(response.data.message);
//       }
//   });
// };

// const del = (id) => {
//   return axios.post(`${API_URL}/vehicles/delete`, {id}, { headers: authHeader() })
//   .then((response) => {
//       if (response.data.success) {
//           return response.data;
//       } else {
//           throw new Error(response.data.message);
//       }
//   });
// };

export default {
  // getAll,
  getVehicleById,
  create,
  // edit,
  // del
};
