import Input from "react-validation/build/input";
import React from "react";

const InputField = ({
  label,
  name,
  value,
  onChange,
  disabled = false,
  type = "text",
  maxLength = "255",
  containerClassName = "col",
  ...props
}) => {
  return (
    <div className={containerClassName}>
      <label className="capitalize">
        <strong>{label}</strong>
      </label>
      <span>
        <Input
          type={type}
          className="form-control"
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          maxLength={maxLength}
          ref={props.refs}
          {...props}
        />
      </span>
    </div>
  );
};

export default InputField;
