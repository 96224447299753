import axios from "axios";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_SELLS_URL;

const getAll = async (params) => {
  return await axios.post(`${API_URL}/sells/list`, params, {
    headers: authHeader(),
  });
};

const getById = async (id) => {
  return await axios.get(`${API_URL}/sells/${id}`, { headers: authHeader() });
};

const getAllReasons = async () => {
  return await axios.get(`${API_URL}/sell-file-comment-reason`, {
    headers: authHeader(),
  });
};

const getAllSuspendedReasons = async () => {
  return await axios.get(`${API_URL}/sell-suspended-comment-reason`, {
    headers: authHeader(),
  });
};

const updateSells = async (id, data) => {
  return await axios.put(`${API_URL}/sells/${id}`, data, {
    headers: authHeader(),
  });
};

const fixSellsData = async (id, data) => {
  return await axios.put(`${API_URL}/sells/inquiry/update?id=${id}`, data, {
    headers: authHeader(),
  });
};

const addSell = async (data) => {
  return await axios.post(`${API_URL}/sells`, data, {
    headers: authHeader(),
  });
};

const getCategories = async () => {
  return await axios.get(`${API_URL}/category`, {
    headers: authHeader(),
  });
};

const getCancelReason = async () => {
  return await axios.get(`${API_URL}/sell-file-cancel-reason`, {
    headers: authHeader(),
  });
};

const updateCancelReason = async (id, data) => {
  return await axios.put(`${API_URL}/sells-cancel-reason/${id}`, data, {
    headers: authHeader(),
  });
};

const getAllDomains = async (params) => {
  return await axios.post(`${API_URL}/sells/domains`, params, {
    headers: authHeader(),
  });
};

const getInquiryById = async (id) => {
  return await axios.get(`${API_URL}/sells/inquiry/${id}`, {
    headers: authHeader(),
  });
};

const getComments = async (id) => {
  return await axios.get(`${API_URL}/audit/sell-comments/${id}`, {
    headers: authHeader(),
  });
};

const updateSimpleSells = async (id, data) => {
  return await axios.put(`${API_URL}/sells-update-simple/${id}`, data, {
    headers: authHeader(),
  });
};

export default {
  addSell,
  fixSellsData,
  getAll,
  getAllDomains,
  getAllReasons,
  getAllSuspendedReasons,
  getById,
  getCancelReason,
  getCategories,
  getComments,
  getInquiryById,
  updateCancelReason,
  updateSells,
  updateSimpleSells,
};
