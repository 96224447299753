import { agents, issuerEntities, zones } from "../../../utils/Service";
import { purchaseStatus, yesNoOptions } from "../../../variables/constants";

const getPurchaseStatus = () => {
  const purchase_status_array = [];
  Object.entries(purchaseStatus).forEach((status, index) => {
    if (status[1].group === "presentation") {
      purchase_status_array.push({
        value: status[1].value,
        text: status[1].text,
      });
    }
  });
  return purchase_status_array;
};

export const filtersPresentationForNoAgents = {
  plate_number: {
    type: "text",
    name: "plateNumber",
    placeholder: "Dominio",
    value: null,
    size: 2,
  },
  presentation_date: {
    type: "select",
    name: "presentation_date",
    options: yesNoOptions,
    placeholder: "Fecha presentación",
    value: "",
    size: 2,
  },
  take_date: {
    type: "select",
    name: "take_date",
    options: yesNoOptions,
    placeholder: "Fecha Retiro",
    value: "",
    size: 2,
  },
  zone: {
    type: "select",
    name: "zone",
    options: zones(),
    placeholder: "Seleccione una zona",
    value: "",
    size: 2,
  },
  agents: {
    type: "select",
    name: "agents",
    options: agents(),
    placeholder: "Gestor que lo presenta",
    value: "",
    size: 2,
  },
  issuerEntityId: {
    type: "multiSelect",
    name: "issuerEntityId",
    options: issuerEntities(),
    placeholder: "Seleccione un registro",
    value: [],
    size: 6,
  },
  status: {
    type: "multiSelect",
    name: "status",
    options: getPurchaseStatus(),
    placeholder: "Estado",
    value: [],
    size: 6,
  },
};

export const filtersPresentationForAgents = agentId => ({
  plate_number: {
    type: "text",
    name: "plateNumber",
    placeholder: "Dominio",
    value: "",
    size: 3,
  },
  issuerEntityId: {
    type: "multiSelect",
    name: "issuerEntityId",
    options: issuerEntities(),
    placeholder: "Seleccione un registro",
    value: [],
    size: 6,
  },
  turn_take_date: {
    type: "date",
    name: "turnTakeDate",
    placeholder: "Fecha de entrega",
    value: null,
    size: 3,
  },
  turn_request_date: {
    type: "date",
    name: "turnRequestDate",
    placeholder: "Fecha de entrega",
    value: null,
    size: 3,
  },
  status: {
    type: "multiSelect",
    name: "status",
    options: getPurchaseStatus(),
    placeholder: "Estado",
    value: [],
    size: 9,
  },
  agents: {
    type: "select",
    name: "agents",
    options: agents(),
    placeholder: "Gestor que lo presenta",
    value: agentId,
    size: 2,
    hide: true
  },
});
