import React, { useEffect, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import Form from "react-validation/build/form";
import Multiselect from "multiselect-react-dropdown";

import { useConfirmationAlert, useToast } from "../../../../hooks";
import sellsService from "../../../../services/sells/sells.service";
import sellsSuspendedService from "../../../../services/sells/sellsSuspended.service";
import sellRequirementsService from "../../../../services/sells/requirements.service";
import { InputField, DatePickerField, SelectField } from "../../../Form/index";
import { DomainAndStatus } from "../components";
import { Comments } from "../../../../components/Comments";
import ConfirmationAlert from "../../../../components/ConfirmationAlert/confirmationAlert";
import Requirement from "../../../../components/RequirementCheck";
import CustomAccordion from "../../../../components/Accordion";
import {
  activate_button_suspended_sell,
  constants,
  delivery_types,
  sells_status,
  sells_status_select,
} from "../../../../variables/constants";
import { sellSuspendedRequirements } from "utils/Service";

//crear venta, suspenderla y pasarla a folio
//ver en que estado queda. (deberia pasar a apertura de folio)

//verificar lo mismo pero con observado

const Edit = (props) => {
  const {
    data: {
      response: {
        opportunity_id,
        plate_number,
        delivery_type,
        status,
        status_id,
        delivery_date,
        owner,
        comments,
        suspended_date,
        estimated_end_trial,
        suspended_period,
        bill_created_date,
        restore_status,
        stock_id,
        past_suspended_date,
        diff_days,
        suspended_days,
      },
    },
    handleClose,
    refresh,
  } = props;

  const initialFormState = {
    comment_reason_id: null,
    comment: null,
    finish_date_test: estimated_end_trial,
    payment_date: new Date(bill_created_date),
    stock_id,
  };
  const [fields, setFields] = useState(initialFormState);
  const [reasons, setReasons] = useState([]);
  const [currentRequirements, setCurrentRequirements] = useState([]);
  const [totalRequirements, setTotalRequirements] = useState();
  const { confirmationModal, handleConfirmationModal } = useConfirmationAlert();
  const addToast = useToast();

  const updateRequirements = (id, status) => {
    const idx = currentRequirements.findIndex((r) => r.requirement_id === id);
    currentRequirements[idx].status = status;
    setTotalRequirements(currentRequirements.filter((r) => r.status).length);
  };

  const getReasons = async () => {
    try {
      const response = await sellsService.getAllSuspendedReasons();
      if (response.status === 200) {
        setReasons(response.data.response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getRequirements = () => {
    sellRequirementsService
      .getAllByOpportunity(opportunity_id, "suspended")
      .then((response) => {
        if (response.data.success) {
          setCurrentRequirements(response.data.response);
          setTotalRequirements(
            response.data.response.filter((r) => r.status).length
          );
        }
      });
  };

  useEffect(() => {
    getReasons();
    getRequirements();
  }, []);

  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const submit = async () => {
    const data = {
      status_id: activate_button_suspended_sell.includes(
        fields.comment_reason_id
      )
        ? restore_status
        : status_id,
      comment: fields.comment,
      comment_reason_id: fields.comment_reason_id,
    };

    const arrPromises = [];

    const promise_opportunity = new Promise((resolve, reject) => {
      sellsSuspendedService.update(opportunity_id, data).then((response) => {
        if (response.status === 200) {
          resolve("Se actualizaron los datos");
        } else {
          reject("Fallo la actualización de los datos");
        }
      });
    });
    arrPromises.push(promise_opportunity);

    // Si estoy observando y cargo requisitos
    if (fields.requirements?.length) {
      const promise_requirements = new Promise((resolve, reject) => {
        sellRequirementsService
          .insert(opportunity_id, fields.requirements)
          .then((response) => {
            if (response.success) {
              resolve("Se crearon los requisitos");
            } else {
              reject("Fallo la creación de los requisitos");
            }
          });
      });

      arrPromises.push(promise_requirements);
    }

    Promise.all(arrPromises)
      .then((values) => {
        values.map((v) =>
          addToast({
            variant: "success",
            header: "Ventas - Suspendidos",
            body: v,
          })
        );
        refresh();
        handleClose();
      })
      .catch((reason) => {
        addToast({
          variant: "danger",
          header: "Ventas - Suspendidos",
          body: reason,
        });
        refresh();
      });
  };

  const handleChangeStatus = () => {
    const change_status = sells_status_select.filter(
      (x) => x.value === restore_status
    )[0];

    handleConfirmationModal(change_status.text, submit);
  };

  return (
    <div className="container">
      <Form>
        <div className="row">
          <DomainAndStatus
            fields={{
              domain: plate_number,
              status: status,
              stock_id,
              diff_days,
              suspended_days,
            }}
          />
        </div>
        <div className="row">
          <InputField
            label={"Titular 1"}
            value={owner}
            disabled={true}
            name={"client"}
          />
          <DatePickerField
            selected={delivery_date == null ? null : new Date(delivery_date)}
            disabled={true}
            label={"Fecha de entrega"}
          />
          <SelectField
            label={"Tipo de entrega"}
            name={"type_of_delivery"}
            value={delivery_type}
            disabled={true}
            options={delivery_types}
          />
          <DatePickerField
            selected={suspended_date == null ? null : new Date(suspended_date)}
            disabled={true}
            label={"Fecha suspension"}
          />
          <DatePickerField
            selected={
              past_suspended_date == null ? null : new Date(past_suspended_date)
            }
            disabled={true}
            label={"Fecha Manual"}
          />
        </div>
        <div className="row">
          <DatePickerField
            label={"Fin periodo de prueba"}
            disabled={true}
            selected={
              fields.finish_date_test == null
                ? null
                : new Date(fields.finish_date_test)
            }
          />
          <DatePickerField
            selected={
              fields.payment_date == null ? null : new Date(fields.payment_date)
            }
            disabled={true}
            label={"Fecha de cobro"}
          />
          <InputField
            label={"Días suspendidos"}
            name={"diff_days"}
            value={`${suspended_period}`}
            disabled={true}
          />
        </div>
        <br />
        <Comments
          reasons={reasons}
          comments={comments}
          statusList={sells_status}
          onChangeSelect={(e) =>
            onSimpleFieldChange(
              "comment_reason_id",
              e.target.value === constants.dropdownDefault
                ? null
                : e.target.value
            )
          }
          onChangeObs={(e) => onSimpleFieldChange("comment", e.target.value)}
        />
        <CustomAccordion
          title={`Requisitos Pendientes (${totalRequirements}/${currentRequirements.length})`}
        >
          <Stack direction="horizontal" gap={3} className="d-flex flex-wrap">
            {currentRequirements.map((c) => (
              <div>
                <Requirement
                  id={opportunity_id}
                  data={c}
                  onChange={updateRequirements}
                  serviceName={sellRequirementsService.updateOne}
                />
              </div>
            ))}
          </Stack>
        </CustomAccordion>
        <div className="row">
          <div className="col">
            <label className="capitalize">
              <strong>Requisitos Adicionales</strong>
            </label>
            <Multiselect
              options={sellSuspendedRequirements().filter(
                (r) =>
                  !currentRequirements
                    .map((c) => c.requirement_id)
                    .includes(r.value)
              )}
              onSelect={(selectedItem) => {
                onSimpleFieldChange("requirements", selectedItem);
              }}
              onRemove={(selectedItem) => {
                onSimpleFieldChange("requirements", selectedItem);
              }}
              displayValue="text"
              placeholder="Requisitos"
              closeIcon="cancel"
              selectionLimit="-1"
              showCheckbox={true}
              singleSelect={false}
            />
          </div>
        </div>

        <div className="modal-footer">
          <div>
            <Button
              className="btn-fill pull-right btn btn-primary btn-sm"
              onClick={submit}
              disabled={
                (!fields.comment?.length ||
                  fields.comment_reason_id == null ||
                  fields.comment_reason_id === constants.dropdownDefault ||
                  activate_button_suspended_sell.includes(
                    fields.comment_reason_id
                  )) &&
                !fields?.requirements?.length
              }
            >
              Actualizar
            </Button>
          </div>
          <div>
            <Button
              className="btn-fill pull-right btn btn-success btn-sm"
              onClick={handleChangeStatus}
              disabled={
                !fields.comment?.length ||
                fields.comment_reason_id == null ||
                fields.comment_reason_id === constants.dropdownDefault ||
                !activate_button_suspended_sell.includes(
                  fields.comment_reason_id
                ) ||
                currentRequirements.length !== totalRequirements
              }
            >
              Alta suspensión
            </Button>
          </div>
        </div>
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
      </Form>
    </div>
  );
};

export default Edit;
