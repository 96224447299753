import axios from "axios";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const insertBulk = async (data) => {
  return await axios.put(`${API_URL}/additional-bulk`, data, {
    headers: authHeader(),
  });
};

const insert = async (data) => {
  return await axios.put(`${API_URL}/additional`, data, {
    headers: authHeader(),
  });
};

const getAll = async (params) => {
  return await axios.post(`${API_URL}/additional/list`, params, {
    headers: authHeader(),
  });
};

const getById = async (id) => {
  return await axios.get(`${API_URL}/additional/${id}`, {
    headers: authHeader(),
  });
};

const getConcepts = async () => {
  return await axios.get(`${API_URL}/additional-concepts`, {
    headers: authHeader(),
  });
};

const update = async (id, data) => {
  return await axios.post(`${API_URL}/additional/${id}`, data, {
    headers: authHeader(),
  });
};

export default {
  insertBulk,
  insert,
  getAll,
  getById,
  getConcepts,
  update,
};
