import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-validation/build/form";

import additionalService from "../../../services/additional/additional.service";
import { InputField, SelectField } from "../../Form";
import { useConfirmationAlert, useToast } from "../../../hooks";
import { additional_status } from "variables/constants";
import ConfirmationAlert from "components/ConfirmationAlert/confirmationAlert";

const status_reject_id = 7;

const Reject = (props) => {
  const { handleClose, refresh, id } = props;

  const [fields, setFields] = useState({
    comment: null,
    status_id: status_reject_id,
  });
  const addToast = useToast();
  const { confirmationModal, handleConfirmationModal } = useConfirmationAlert();

  const submit = async () => {
    try {
      const response = await additionalService.update(id, fields);
      if (response.status === 200) {
        addToast({
          variant: "success",
          header: "Adicionales",
          body: "Se anulo el adicional correctamente",
        });
        props.handleClose();
        props.refresh();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <div className="row">
            <div className="col">
              <InputField
                label={"Comentario sobre la anulación"}
                value={fields.comment}
                name={"comment"}
                onChange={(e) => onSimpleFieldChange("comment", e.target.value)}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="modal-footer">
          <div>
            <Button
              className="btn-fill pull-right btn btn-warning btn-sm"
              onClick={props.handleClose}
            >
              Cancelar
            </Button>
          </div>
          <div className="row">
            <Button
              className="btn-fill pull-right btn btn-primary btn-sm"
              disabled={!fields.comment?.length}
              onClick={() =>
                handleConfirmationModal(
                  additional_status[status_reject_id].text,
                  () => submit(),
                  "¿Desea anular el adicional?",
                  "Al confirmar, el adicional pasará al estado"
                )
              }
            >
              Anular
            </Button>
          </div>
        </div>
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
      </Form>
    </div>
  );
};

export default Reject;
