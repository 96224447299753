import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ProfileService from "../../services/profile.service";
import AuthService from "../../services/auth.service";
import { useToast } from "../../hooks";
import { updateLocalStorageCache } from "../../utils/Service";
import ButtonIcon from "../../components/ButtonIcon";
import List from "../../components/Table/List.js";

const headSort = [
  { text: "Id", field: "id", model: null },
  { text: "Nombre", field: "name", model: null },
  { text: "Descripción", field: "description", model: null },
  { text: "", field: null, model: null },
];

const ProfilesList = (props) => {
  const [body, setBody] = useState([]);
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    model: null,
    field: "id",
    type: "ASC",
  });
  const addToast = useToast();
  const navigate = useNavigate();

  const handleSort = (model, field, type) => {
    setCurrentSort({ model, field, type });
  };

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions("PERFILES");
    setCurrentPermissions(permissions);
    const params = {
      sortField: currentSort?.field,
      sortType: currentSort?.type,
    };
    ProfileService.getAll(params).then((response) => {
      if (response.data.count > 0) {
        setBody(response.data.response);
      }
      setTotalItems(response.data.count);
    });
  }, [currentSort]);

  const handleDelete = (id, e) => {
    e.preventDefault();
    const del = window.confirm("¿Eliminar perfil?");
    if (del) {
      ProfileService.del(id).then(() => {
        let list = body.filter((data) => data.id !== parseInt(id));
        setBody(list);
        addToast({
          variant: "success",
          header: "Perfiles",
          body: "Se eliminó el perfil correctamente",
        });
        updateLocalStorageCache();
      });
    }
  };

  const actions = (props) => {
    return (
      <>
        {currentPermissions?.permissions?.hasUpdate ? (
          <ButtonIcon
            onClick={() => navigate(`/admin/profiles/${props.value}/edit`)}
            name="file-alt"
            title="Editar"
          />
        ) : null}

        {currentPermissions?.permissions?.hasDelete ? (
          <ButtonIcon
            onClick={(e) => handleDelete(props.value, e)}
            name="times"
            color="danger"
            size="lg"
            title="Eliminar"
          />
        ) : null}
      </>
    );
  };

  return (
    <div className="content">
      <div className="content-filters ps-3 mb-4">
        <Button
          href="/admin/profiles/create"
          className="btn-fill btn btn-primary capitalize"
        >
          Nuevo Perfil
        </Button>
      </div>
      <Container fluid>
        <List
          thead={headSort}
          tbody={body}
          totalItems={totalItems}
          actions={actions}
          handleSort={handleSort}
          sortable
          currentSort={currentSort}
        />
      </Container>
    </div>
  );
};

export default ProfilesList;
