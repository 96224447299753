import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { excelDownload } from "../../utils/File";
import { useToast } from "../../hooks/index";
import Icon from "../Icon";

function ExcelButton({ type, filters }) {
  const [isLoading, setLoading] = useState(false);
  const addToast = useToast();

  const handleClick = async () => {
    setLoading(true);
    excelDownload(type, filters)
      .then((resolve) => {
        setLoading(false);
        addToast({
          variant: "success",
          header: "Descarga Excel",
          body: "Se generó correctamente el archivo solicitado",
        });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        addToast({
          variant: "danger",
          header: "Descarga Excel",
          body: "No se pudo generar el archivo solicitado",
        });
      });
  };

  return (
    <Button
      disabled={isLoading}
      onClick={!isLoading ? handleClick : null}
      className="btn-fill btn btn-success capitalize"
    >
      {isLoading ? (
        <>
          <Icon name="spinner" animate="spin" color="white" /> Procesando...
        </>
      ) : (
        <>
          <Icon name="file-excel" color="white" /> Descargar Excel
        </>
      )}
    </Button>
  );
}

export default ExcelButton;
