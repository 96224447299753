// https://docs.fontawesome.com/web/style/size
// 2xs - xs - sm - "null" - lg - xl - 2xl

// https://docs.fontawesome.com/web/style/rotate

const Icon = ({
  name,
  title = "",
  size = null,
  color = "primary",
  animate = null,
  rotate = null,
}) => (
  <span
    title={title}
    className={`fas fa-${name} ${!!size ? `fa-${size}` : ""} ${
      !!color ? `text-${color}` : ""
    } ${!!animate ? `fa-${animate}` : ""} ${!!rotate ? `fa-${rotate}` : ""}`}
    aria-hidden="true"
  ></span>
);

export default Icon;
