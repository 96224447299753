import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAll = async (props) => {
  return await axios.get(
    `${API_URL}/issuer-entity?limit=${props.limit}&page=${props.page}&searchq=${props.searchq}&zone=${props.zone}&sortField=${props.sortField}&sortType=${props.sortType}`,
    { headers: authHeader() }
  );
};

const getAllCoords = async (props) => {
  return await axios
    .get(
      `${API_URL}/issuer-entity?limit=${props.limit}&page=${props.page}&searchq=${props.searchq}`,
      { headers: authHeader() }
    )
    .then((response) => {
      const data = response.data.response;
      const coords = data.map((row, i) => {
        return {
          id: i,
          issuer_entity_id: row.issuer_entity_id,
          lat: row.lat,
          lng: row.lng,
          description: row.description,
          address: `${row.address}`,
          province: row.province,
          phone: row.phone_01,
          zip_code: row.zip_code,
          city: row.city,
          address_complete: row.address_complete,
          city_province: row.city_province,
        };
      });
      return coords;
    });
};

const getById = async (id) => {
  return await axios.get(`${API_URL}/issuer-entity/${id}`, {
    headers: authHeader(),
  });
};

const getZones = async (id) => {
  const headers = authHeader();
  return (
    Object.keys(headers).length &&
    (await axios.get(`${API_URL}/issuer-entity-zones`, { headers }))
  );
};

const edit = (payload) => {
  return axios
    .put(
      `${API_URL}/issuer-entity`,
      {
        ...payload,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

export default {
  getAll,
  getAllCoords,
  getById,
  getZones,
  edit,
};
