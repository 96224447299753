import { constants } from "variables/constants";

export const head = [
  { text: "ID", field: "id", model: null },
  { text: "Stock ID", field: "stock_id", model: null },
  { text: constants.domain, field: "plate_number", model: null },
  { text: "Tipo", field: "type_id", model: null },
  { text: "Ingresos", field: null, model: null },
  { text: "Retiros", field: null, model: null },
  { text: "Monto", field: null, model: null },
  { text: "Fecha de Presentación", field: null, model: null },
  { text: "Fecha de Retiro", field: null, model: null },
  { text: "", field: null, model: null },
];
