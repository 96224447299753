import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAll = async (props) => {
  const url = `${API_URL}/management/notary-office?limit=1000&page=0&sortField=${props.sortField}&sortType=${props.sortType}`;
  return await axios.get(url, { headers: authHeader() });
};

const list = async (params) => {
  const url = `${API_URL}/management/notary-office/list`;
  return await axios.post(url, params, { headers: authHeader() });
};

const getById = async (id) => {
  return await axios.get(`${API_URL}/management/notary-office/${id}`, {
    headers: authHeader(),
  });
};

const create = (payload) => {
  return axios
    .post(
      `${API_URL}/management/notary-office`,
      {
        ...payload,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const edit = (payload) => {
  return axios
    .put(
      `${API_URL}/management/notary-office`,
      {
        ...payload,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const toggleActive = (id, active) => {
  const url = `${API_URL}/management/notary-office/toggleActive`;
  return axios
    .post(url, { id, active }, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

// En relación con usuarios
const getAllUsers = async (params) => {
  return await axios.get(
    `${API_URL}/notary-office-users?limit=1000&page=0&notary_office_id=${params.notary_office_id}&user_id=${params.user_id}`,
    { headers: authHeader() }
  );
};

const addUser = (payload) => {
  return axios
    .post(
      `${API_URL}/notary-office-users`,
      {
        ...payload,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const delUser = (notary_office_id, user_id) => {
  return axios
    .delete(`${API_URL}/notary-office-users/${notary_office_id}/${user_id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

export default {
  addUser,
  create,
  delUser,
  edit,
  getAll,
  getAllUsers,
  getById,
  list,
  toggleActive,
};
