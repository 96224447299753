import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAll = async (props) => {
  return await axios.get(`${API_URL}/groups?limit=${props.countItems}&page=${props.currentPage}&sortField=${props.sortField}&sortType=${props.sortType}`, { headers: authHeader() });
};

const getById = async (id) => {
  return await axios.get(`${API_URL}/groups/${id}`, { headers: authHeader() });
};

const create = async (payload) => {
  const response = await axios.post(`${API_URL}/groups`, { ...payload }, { headers: authHeader() });
  if (response.data.success) {
    return response.data;
  } else {
    throw new Error(response.data.message);
  }
};

const edit = async (payload) => {
  const response = await axios.put(`${API_URL}/groups/${payload.id}`, { ...payload }, { headers: authHeader() });
  if (response.data.success) {
    return response.data;
  } else {
    throw new Error(response.data.message);
  }
};

const del = async (id) => {
  const response = await axios.patch(`${API_URL}/groups/${id}/toggle-active`, {}, { headers: authHeader() });
  if (response.data.success) {
    return response.data;
  } else {
    throw new Error(response.data.message);
  }
};

const getUsersFromGroup = async (props) => {
  return await axios.get(`${API_URL}/groups/${props.id}/users?limit=${props.countItems}&page=${props.currentPage}&sortField=${props.sortField}&sortType=${props.sortType}`, { headers: authHeader() });
};

const addUserToGroup = async (payload) => {
  return await axios.post(`${API_URL}/groups/${payload.group_id}/users/${payload.user_id}`, {}, { headers: authHeader() });
}

const removeUserFromGroup = async (payload) => {
  return await axios.delete(`${API_URL}/groups/${payload.id}/users/${payload.userId}`, { headers: authHeader() });
}

export default {
  getAll,
  create,
  getById,
  edit,
  del,
  getUsersFromGroup,
  addUserToGroup,
  removeUserFromGroup,
};
