import React, { useEffect, useState } from "react";
import Form from "react-validation/build/form";
import Textarea from "react-validation/build/textarea";
import { Button } from "react-bootstrap";
import StatusForm08Service from "../../../services/form08/statusForm08.service";
import Dropdown from "../../../components/Table/Dropdown";
import {
  constants,
  purchaseStatus,
  status08,
} from "../../../variables/constants";
import { useConfirmationAlert } from "hooks";
import ConfirmationAlert from "components/ConfirmationAlert/confirmationAlert";

const Cancel = (props) => {
  const { data, handleDelete, handleClose } = props;

  const initialFormState = {
    id: data,
    observation: "",
    cancel_reason_id: null,
  };

  const [allowSubmit, setAllowSubmit] = useState(false);
  const [submitAction, setSubmitAction] = useState(false);
  const [fields, setFields] = useState(initialFormState);
  const [reasons, setReasons] = useState([]);

  const { confirmationModal, handlePreConfirmationPurchaseModal } =
    useConfirmationAlert();

  useEffect(() => {
    StatusForm08Service.getCancelReasons().then((response) => {
      if (response.data.success) {
        setReasons(response.data.response);
      }
    });
  }, []);

  // Al presionar un botón
  useEffect(() => {
    // Validacion de campos
    const simplesFields =
      Object.entries(fields).filter(
        (f) =>
          f[1] === null || f[1] === "null" || f[1] === "" || f[1] === undefined
      ).length === 0;
    setAllowSubmit(simplesFields);
    if (submitAction) {
      handleDelete(fields);
      setSubmitAction(false);
      handleClose();
    }
  }, [submitAction, fields, handleDelete, handleClose]);

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const handleOnClick = () => {
    const rejectedBy = Object.entries(status08).find(
      (s) => s[1].cancelReasonId === parseInt(fields.cancel_reason_id, 10)
    );
    handlePreConfirmationPurchaseModal(
      "current_status",
      rejectedBy ? rejectedBy[1].value : status08.CANCELED.value,
      rejectedBy ? rejectedBy[1].text : status08.CANCELED.text,
      () => setSubmitAction(true)
    );
  };

  return (
    <div className="modalChecklist">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>¿Esta seguro que desea anular el formulario?</h2>
          </div>
        </div>
      </div>
      <br />
      <Form>
        <div className="container">
          <div className="row">
            <div className="col">
              <label className="capitalize">
                <strong>Motivo de rechazo</strong>
              </label>
              <div>
                <Dropdown
                  className="form-control"
                  name="cancel_reason_id"
                  placeholder="Motivo de rechazo"
                  onSelect={(e) =>
                    onSimpleFieldChange(
                      "cancel_reason_id",
                      e.target.value === constants.dropdownDefault
                        ? null
                        : e.target.value
                    )
                  }
                  options={reasons}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="capitalize">
                <strong>{constants.comment}</strong>
              </label>
              <div>
                <Textarea
                  className="form-control"
                  rows={3}
                  name="observation"
                  placeholder="Ingrese un comentario"
                  onChange={(e) =>
                    onSimpleFieldChange("observation", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <br />
        </div>
      </Form>

      <div className="modal-footer">
        <Button
          className="btn-fill pull-right btn btn-primary btn-sm capitalize"
          onClick={() => {
            handleClose();
          }}
        >
          {constants.close}
        </Button>
        <Button
          className="btn-fill pull-right btn btn-danger btn-sm capitalize"
          value={purchaseStatus.ACCEPT.value}
          onClick={handleOnClick}
          disabled={!allowSubmit}
        >
          {constants.accept}
        </Button>
      </div>
      {confirmationModal.open && (
        <ConfirmationAlert
          show={confirmationModal.open}
          handleAction={confirmationModal.handleAction}
          handleClose={confirmationModal.handleClose}
          message={confirmationModal.message}
          title={confirmationModal.title}
        />
      )}
    </div>
  );
};

export default Cancel;
