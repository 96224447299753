import { InputField } from "components/Form";
import React, { useState } from "react";
import { VehicleInfo } from "./components";
import Form from "react-validation/build/form";
import { TitleAndHide } from "../Sells/components";
import { DatePickerField } from "components/Form";
import { Button } from "react-bootstrap";

const RecordVtvSchedule = ({ data, handleClose }) => {
  const [records, setRecords] = useState(data);

  const fields = {
    vehicle_id: data[data.length - 1]?.vehicle_id,
    domain: data[data.length - 1]?.plate_number,
    status_vtv: data[data.length - 1]?.status_vtv,
  };

  const handlerHide = (vtv) => {
    if (vtv.hide) {
      vtv.hide = false;
    } else {
      vtv.hide = true;
    }
    setRecords([...records]);
  };

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <VehicleInfo fields={fields} />
          <hr />
          {records && records.length ? (
            records.map((vtv, index) => {
              return (
                <>
                  <div>
                    <TitleAndHide
                      handlerHide={() => handlerHide(vtv)}
                      hide={!vtv.hide}
                      title={`${vtv.updatedAt} - ${vtv.vtv_schedule_status}`}
                    />
                  </div>
                  {!vtv.hide ? (
                    <>
                      <div className="row" key={index}>
                        <InputField
                          label={"Ubicación"}
                          disabled={true}
                          value={vtv.delivery_place}
                        />
                        <InputField
                          label={"Planta VTV"}
                          disabled={true}
                          value={vtv.vtv_reception_place}
                        />
                      </div>
                      <div className="row" key={index + 2}>
                        <DatePickerField
                          selected={
                            vtv.schedule_date != null
                              ? new Date(vtv.schedule_date)
                              : null
                          }
                          disabled={true}
                          label={"Fecha Turno VTV"}
                        />
                      </div>
                      <div className="row">
                        <DatePickerField
                          selected={
                            vtv.expired_date != null
                              ? new Date(vtv.expired_date)
                              : null
                          }
                          disabled={true}
                          label={"Fecha de expiración"}
                        />
                        <InputField
                          label={"Actualizado por"}
                          disabled={true}
                          value={vtv.first_name}
                        />
                      </div>
                      {index !== data.length - 1 ? <hr /> : <br />}
                    </>
                  ) : null}
                </>
              );
            })
          ) : (
            <h3>Sin Registros Históricos</h3>
          )}
        </div>
        <div className="modal-footer mt-2">
          <div className="row">
            <Button
              className="btn-fill pull-right btn btn-primary btn-sm"
              onClick={handleClose}
            >
              Cerrar
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default RecordVtvSchedule;
