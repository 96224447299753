import React, { useState, useRef, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import NotFound from "components/Errors/NotFound.js";
import ProfileService from "../../services/profile.service";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../hooks";
import { updateLocalStorageCache } from "utils/Service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        El campo es obligatorio!
      </div>
    );
  }
};

const ProfilesEdit = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const profile_id = params.id;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [resourceExist, setResourceExist] = useState(true);

  const form = useRef();
  const checkBtn = useRef();
  const addToast = useToast();

  useEffect(() => {
    ProfileService.getById(profile_id)
      .then((response) => {
        if (response.success && response.data) {
          setName(response.data.name);
          setDescription(response.data.description);
        }
      })
      .catch((e) => {
        setResourceExist(false);
      });
  }, [profile_id]);

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const onChangeDescription = (e) => {
    const description = e.target.value;
    setDescription(description);
  };

  const handSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      ProfileService.edit(profile_id, name, description).then(
        () => {
          addToast({
            variant: "success",
            header: "Perfiles",
            body: "Se actualizó la información correctamente",
          });
          updateLocalStorageCache().then(() => {
            navigate("/admin/profiles/list");
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          addToast({
            variant: "danger",
            header: "Perfiles",
            body: resMessage,
          });
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <Container fluid>
        <Button
          href="/admin/profiles/list"
          className="btn-fill btn btn-info capitalize"
        >
          Volver
        </Button>
        <br />
        <br />
        <Row>
          <Col md={12}>
            <Card
              content={
                resourceExist ? (
                  <Form onSubmit={handSubmit} ref={form}>
                    <div className="form-group">
                      <label htmlFor="email">Nombre</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Nombre"
                        value={name}
                        onChange={onChangeName}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Descripción</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="description"
                        placeholder="description"
                        value={description}
                        onChange={onChangeDescription}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group mt-3">
                      <button
                        className="btn btn-primary btn-fill pull-right"
                        disabled={loading}
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Editar Perfil</span>
                      </button>
                    </div>

                    <CheckButton style={{ display: "none" }} ref={checkBtn} />

                    <div className="clearfix" />
                  </Form>
                ) : (
                  <NotFound />
                )
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProfilesEdit;
