import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-validation/build/form";
import SellsService from "../../../../services/sells/sells.service";
import { Comments } from "../../../Comments/index";
import {
  InputField,
  DatePickerField,
  SelectField,
} from "../../../../components/Form";
import { delivery_types, sells_status } from "../../../../variables/constants";
import { useToast } from "../../../../hooks";
import { providerOptions } from "../../../../variables/sells";
import { yesOrNotFinancedOptions } from "variables/constants";


const InquiryEdit = (props) => {
  const {
    data: {
      id,
      opportunity_id,
      stock_id,
      delivery_type,
      delivery_date,
      comments,
      financing_provider,
      financing_amount,
      financed,
    },
    handleClose,
    refresh,
  } = props;

  const initialFormState = {
    id,
    stock_id: stock_id,
    delivery_type: delivery_type,
    delivery_date: delivery_date ? new Date(delivery_date) : null,
    financed: financed,
    financing_provider: financing_provider,
    financing_amount: financing_amount,
  };

  const [fields, setFields] = useState(initialFormState);
  const [reasons, setReasons] = useState([]);
  const addToast = useToast();
  const [allowSubmit, setAllowSubmit] = useState(false);

  const getReasons = async () => {
    try {
      const response = await SellsService.getAllReasons();
      if (response.status === 200) {
        setReasons(response.data.response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const commentsRemapped = comments.map((comment) => {
      const obj = {
        ...comment,
        sell_file_comment_reason: {
          description: comment.reason,
        },
        sell_status: {
          description: comment.status,
        },
        auditTimestamp: comment.comment_date,
      };
      return obj;
    });

    onSimpleFieldChange("comments", commentsRemapped);
    getReasons();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAllowSubmit(fields?.stock_id?.toString().length === 6 && fields.delivery_date && (fields.financed ? fields.financing_provider && fields.financing_amount : true));
  }, [fields]);

  const submit = async () => {
    const data = {
      // opportunity_id: opportunity_id,
      comment: fields.observation,
      comment_reason_id: fields.cancel_reason_id,
      stock_id: fields.stock_id,
      delivery_date: fields.delivery_date,
      delivery_type: fields.delivery_type,
      financed: fields.financed,
      financing_provider: fields.financed ? fields.financing_provider : null,
      financing_amount: fields.financed ? Number(fields.financing_amount) : null,
    };

    const response = await SellsService.fixSellsData(opportunity_id, data);
    if (response.status === 200) {
      addToast({
        variant: "success",
        header: "Ventas - Consulta",
        body: "Se actualizó la información correctamente",
      });
      handleClose();
      refresh();
    }
  };

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <div className="row">
            <InputField
              label={"Stock ID"}
              name={"stock_id"}
              onChange={(e) => onSimpleFieldChange("stock_id", e.target.value)}
              value={fields.stock_id}
              maxLength="6"
            />
            <DatePickerField
              onChange={(date) => onSimpleFieldChange("delivery_date", date)}
              selected={fields.delivery_date}
              label={"Fecha de entrega"}
            />
            <SelectField
              label={"Tipo de entrega"}
              name={"type_of_delivery"}
              value={fields.delivery_type}
              options={delivery_types}
              onChange={(e) =>
                onSimpleFieldChange(
                  "delivery_type",
                  !!e.target.value ? e.target.value : null
                )
              }
            />
          </div>
          <hr />
          <div className="row">
            <SelectField
              label={"Prendado"}
              options={yesOrNotFinancedOptions}
              onChange={(e) =>
                onSimpleFieldChange(
                  "financed",
                  e.target.value === "true"
                )
              }
              value={!!fields.financed}
              name={"financed"}
            />
          </div>
          
          {!!fields.financed ? <div className="row" >
            <div className="col col-6">
              <SelectField
                label={"Proveedor"}
                options={providerOptions}
                onChange={(e) =>
                  onSimpleFieldChange(
                    "financing_provider",
                    !!e.target.value ? e.target.value : null
                  )
                }
                value={fields.financing_provider ?? ""}
                name={"financing_provider"}
              />
            </div>
            <div className="col col-6">
              <Form>
                <InputField
                  label={"Importe Prestamo"}
                  name={"financing_amount"}
                  onChange={(e) =>
                    onSimpleFieldChange("financing_amount", e.target.value)
                  }
                  // disabled
                  value={fields.financing_amount ?? ""}
                />
              </Form>
            </div>
          </div> : null}
          <hr />

          <Comments
            reasons={reasons}
            comments={fields.comments}
            statusList={sells_status}
            onChangeSelect={(e) =>
              onSimpleFieldChange("cancel_reason_id", e.target.value)
            }
            onChangeObs={(e) =>
              onSimpleFieldChange("observation", e.target.value)
            }
          />
        </div>
        <br />
        <div className="modal-footer">
          <div>
            <Button
              className="btn-fill pull-right btn btn-primary btn-sm"
              onClick={() => submit(false)}
              disabled={!allowSubmit}
            >
              Actualizar
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default InquiryEdit;
