import React, { useEffect, useState } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import GoogleMaps from '../../components/MapsGoogle/GoogleMap/index.js';
import IssuerEntity from "../../services/issuerEntity.service";
import { googleMaps } from "../../variables/constants";

const MapIssuerEntity = () => {
  const [callback, setCallback] = useState({});
  const [markersData, setMarkersData] = useState([]);
  const [address, setAddress] = useState([]);

  useEffect(() => {
    const params = { limit: 1000, page: 0, searchq: null };
    IssuerEntity.getAllCoords(params).then(response => {
      setMarkersData(response);
    });
  }, []);

  const onSelect = (selectedList, selectedItem) => {
    const data = {
      "center": { lat: selectedItem.lat, lng: selectedItem.lng },
      "zoom": parseInt(googleMaps.defaultSelZoom),
      "pinSelected": selectedItem.issuer_entity_id
    };
    setCallback(data);
  }

  const onRemove = () => {
    setAddress([]);
  }

  const onSearch = (word) => {
    if (word.length > 1) {
      const params = { limit: 1000, page: 0, searchq: word };
      IssuerEntity.getAllCoords(params).then(response => {
        const row = response.map((data) => {
          const obj = {
            id: data.id,
            issuer_entity_id: data.issuer_entity_id,
            name: data.description,
            lat: data.lat,
            lng: data.lng
          };

          return obj;
        });

        setAddress(row);
      });
    }
  }

  return (
    <div className="google-maps-container">
      <div className="row address-selection">
        <Multiselect
          options={address}
          onSearch={onSearch}
          onSelect={onSelect}
          onRemove={onRemove}
          displayValue="name"
          placeholder="Registros"
          closeIcon="cancel"
          selectionLimit="1"
          showCheckbox={false}
          singleSelect={false}
        />
      </div>
      {
        markersData.length > 0 && (
          <GoogleMaps
            markersData={markersData}
            callback={callback}
          />
        )
      }
    </div>
  )
};

export default MapIssuerEntity;
