import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Form from "react-validation/build/form";
import moment from "moment";

import ConfirmationAlert from "../../../components/ConfirmationAlert/confirmationAlert";
import { useConfirmationAlert, useToast } from "../../../hooks/";
import renditionService from "../../../services/renditions/rendition.service";
// Compras
import AuditService from "../../../services/audit.service";
import ManagementService from "../../../services/management.service";
// Ventas
import sellsService from "../../../services/sells/sells.service";
// Bacs
import bacsService from "../../../services/bacs/bacs.service";
import CustomAccordion from "../../Accordion/index";
import { Comments } from "../../Comments/index";
import { DatePickerField, InputField, SelectField } from "../../Form/index";
import {
  purchaseStatus,
  renditionType,
  sells_status,
} from "../../../variables/constants";
import { bacs_status } from "../../../variables/bacs_constants";
import { formatAmount } from "../../../utils/Numbers";
import { BadgeField } from "../Sells/components";
import { purchaseComment } from "utils/Comments";

//Preguntar si se puede modificar el vehicle_id / opp / bacs / stock_id (se puden modificar en cualquier momento)
//Si ya esta cargando el detalle, que NO se puede modificar?
//Pedir excel de rendiciones
//Motivos de rendicion.

const condition = [
  {
    text: "Ingreso",
    value: 1,
  },
  {
    text: "Retiro",
    value: 2,
  },
];

const CustomTable = ({ header_id, values, addOrEditDetail }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Monto</th>
          <th>Condición</th>
          <th>Concepto</th>
          <th>Origen</th>
          <th>Fecha</th>
          <th></th>
        </tr>
      </thead>
      <tbody style={{ width: "100%" }}>
        {values.map((value) => {
          return (
            <tr key={value.id}>
              <td>{value.amount}</td>
              <td>{value.rendition_condition_id}</td>
              <td>{value.rendition_concept_id}</td>
              <td>{value.rendition_origin_id}</td>
              <td>{value.input_date}</td>
              <td>
                <Button
                  onClick={(e) =>
                    addOrEditDetail(header_id, value.id, 2, value)
                  }
                  className="btn-simple btn btn-xs btn-danger"
                >
                  <i className="fas fa-times"></i>
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const Edit = (props) => {
  const {
    data: {
      response: {
        id,
        plate_number,
        opportunity_id,
        stock_id,
        vehicle_id,
        bacs_id,
        type,
        status_id,
        details,
      },
    },
    handleClose,
    refresh,
  } = props;

  const detailState = {
    id: null,
    amount: 0,
    rendition_condition_id: 0,
    rendition_concept_id: 0,
    rendition_origin_id: 0,
    input_date: null,
  };

  const initialFormState = {
    id,
    stock_id,
    plate_number,
    type_id: type.id,
    vehicle_id,
    opportunity_id,
    bacs_id,
    ...detailState,
  };

  const [fields, setFields] = useState(initialFormState);
  const [allowAddDetail, setAllowAddDetail] = useState(false);
  const [detail, setDetail] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [comments, setComments] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const { confirmationModal, handleConfirmationModal } = useConfirmationAlert();
  const addToast = useToast();

  const [allConcepts, setAllConcepts] = useState([]);
  const [concepts, setConcepts] = useState([]);
  const [origins, setOrigins] = useState([]);

  // Bloque comentarios
  const getCommentData = () => {
    switch (type.id) {
      // Compras
      case 1:
        // Comentarios historicos
        AuditService.getHistory(vehicle_id, "comment_summary").then(
          (response) => {
            setComments(purchaseComment(response.data.response));
          }
        );
        // Motivos de los comentarios
        ManagementService.getCommentReasons().then((response) => {
          if (response.data.success) {
            setReasons(
              response.data.response.filter(
                (r) => !r.text.includes("[SUSPENDER]")
              )
            );
          }
        });
        // Listado de estados
        setStatusList(purchaseStatus);
        break;
      // Ventas
      case 2:
        // Comentarios historicos
        sellsService.getComments(opportunity_id).then((response) => {
          setComments(response.data.response);
        });
        // Motivos de los comentarios
        sellsService.getAllReasons().then((response) => {
          if (response.data.success) {
            setReasons(
              response.data.response.filter(
                (r) => !r.text.includes("[SUSPENDER]")
              )
            );
          }
        });
        // Listado de estados
        setStatusList(sells_status);
        break;
      // Bacs
      case 3:
        // Comentarios historicos
        bacsService.getComments(bacs_id).then((response) => {
          setComments(response.data.response);
        });
        // Motivos de los comentarios
        bacsService.getReasons().then((response) => {
          if (response.data.success) {
            setReasons(
              response.data.response.filter(
                (r) => !r.text.includes("[SUSPENDER]")
              )
            );
          }
        });
        // Listado de estados
        setStatusList(bacs_status);
        break;
    }
  };

  useEffect(() => {
    const detailMapped = details.map((detail) => {
      return {
        id: detail.id,
        input_date: detail.input_date,
        amount: formatAmount(detail.amount),
        rendition_condition_id:
          detail.rendition_classification_type.rendition_condition.description,
        rendition_concept_id: detail.rendition_concept.description,
        rendition_origin_id: detail.rendition_origin.description,
      };
    });
    setDetail(detailMapped);
    getCommentData();
    renditionService.getConcepts().then((response) => {
      if (response.status === 200 && response.data.success) {
        setAllConcepts(response.data.response);
      }
    });
    renditionService.getOrigins().then((response) => {
      if (response.status === 200 && response.data.success) {
        setOrigins(response.data.response);
      }
    });
  }, []);

  useEffect(() => {
    // Filtra los conceptos en base a la condición seleccionada
    setConcepts(
      !!fields.rendition_condition_id
        ? allConcepts.find(
            (f) =>
              f.condition_id === parseInt(fields.rendition_condition_id, 10)
          ).concepts
        : []
    );
  }, [fields.rendition_condition_id]);

  useEffect(() => {
    setAllowAddDetail(
      !!fields.amount &&
        !!fields.rendition_condition_id &&
        !!fields.rendition_concept_id &&
        !!fields.rendition_origin_id &&
        !!fields.input_date
    );
  }, [fields]);

  const submit = async () => {
    let response = null;
    const data = {
      comment: fields.comment,
      comment_reason_id: fields.comment_reason_id,
    };

    switch (type.id) {
      // Compras
      case 1:
        data.vehicle_id = vehicle_id;
        response = await ManagementService.edit(data);
        break;
      // Ventas
      case 2:
        response = await sellsService.updateSimpleSells(opportunity_id, data);
        break;
      // Bacs
      case 3:
        data.id = bacs_id;
        response = await bacsService.update(data);
        break;
    }

    // const response = await renditionService.update(data);
    if (response.status === 200 && response.data.success) {
      addToast({
        variant: "success",
        header: "Rendición",
        body: "Se actualizó la información correctamente",
      });
      handleClose();
      refresh();
    } else {
      addToast({
        variant: "danger",
        header: "Rendición",
        body: response.message,
      });
    }
  };

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const addOrEditDetail = async (header_id, id, status_id, values) => {
    const form = values;

    // Si actualiza, es solo el estado para eliminar, sino se crea un detalle
    const data = !!id
      ? {
          status_id: status_id,
          id: id,
        }
      : {
          status_id: status_id,
          amount: form.amount,
          condition_id: +form.rendition_condition_id,
          type_id: type.id,
          concept_id: +form.rendition_concept_id,
          origin_id: +form.rendition_origin_id,
          input_date: form.input_date,
        };

    try {
      const response = await renditionService.addOrUpdateDetail(
        header_id,
        data
      );
      if (response.status === 200 && response.data.success) {
        const newDetail = {
          id: response.data.response.id,
          input_date: moment(response.data.response.input_date)
            .utcOffset("-0300")
            .format("DD/MM/YYYY"),
          amount: formatAmount(response.data.response.amount),
          rendition_condition_id:
            response.data.response.rendition_classification_type
              .rendition_condition.description,
          rendition_concept_id:
            response.data.response.rendition_concept.description,
          rendition_origin_id:
            response.data.response.rendition_origin.description,
        };
        if (!id) {
          setDetail(detail.concat(newDetail));
        }

        if (status_id === 2) {
          const details = detail.filter((value) => value.id !== id);
          setDetail(details);
        }
        refresh();
        addToast({
          variant: "success",
          header: "Rendición",
          body: `Se ${
            id ? "actualizó" : "agregó"
          } la información correctamente`,
        });
      } else {
        addToast({
          variant: "danger",
          header: "Rendición",
          body: `No se pudo ${id ? "actualizar" : "agregar"} la información`,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const incomes = detail.filter(
    (value) => value.rendition_condition_id === "Ingreso"
  );
  const withdraws = detail.filter(
    (value) => value.rendition_condition_id === "Retiro"
  );

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <div className="row">
            <div className="col">
              {/* <InputField
                label={"Dominio"}
                value={fields.plate_number}
                onChange={(e) =>
                  onSimpleFieldChange("plate_number", e.target.value)
                }
                name={"plate_number"}
              /> */}
              <BadgeField
                name={"plate_number"}
                label={"Dominio"}
                text={fields.plate_number}
                badgeClass={"plate_number"}
                variant={"warning"}
              />
            </div>
            <div className="col">
              {/* <InputField
                label={"Stock ID"}
                value={fields.stock_id}
                onChange={(e) =>
                  onSimpleFieldChange("stock_id", e.target.value)
                }
                name={"stock_id"}
              /> */}
              <BadgeField
                name={"stock_id"}
                label={"Stock ID"}
                text={fields.stock_id}
                badgeClass={"plate_number"}
                variant={"info"}
              />
            </div>
            <div className="col">
              {fields.opportunity_id && (
                // <InputField
                //   label={"Opportunity ID"}
                //   value={fields.opportunity_id}
                //   disabled={true}
                //   name={"opportunity_id"}
                // />
                <BadgeField
                  name={"opportunity_id"}
                  label={"Opportunity ID"}
                  text={fields.opportunity_id}
                  badgeClass={"plate_number"}
                  variant={"info"}
                />
              )}
              {fields.vehicle_id && (
                // <InputField
                //   label={"Vehiculo ID"}
                //   value={fields.vehicle_id}
                //   disabled={true}
                //   name={"vehicule_id"}
                // />
                <BadgeField
                  name={"vehicle_id"}
                  label={"Vehiculo ID"}
                  text={fields.vehicle_id}
                  badgeClass={"plate_number"}
                  variant={"info"}
                />
              )}
              {fields.bacs_id && (
                // <InputField
                //   label={"Bacs ID"}
                //   value={fields.bacs_id}
                //   disabled={true}
                //   name={"bacs_id"}
                // />
                <BadgeField
                  name={"bacs_id"}
                  label={"Bacs ID"}
                  text={fields.bacs_id}
                  badgeClass={"plate_number"}
                  variant={"info"}
                />
              )}
            </div>
            <div className="col">
              {/* <SelectField
                label={"Tipo de rendicion"}
                name={"rendition_type"}
                value={fields.type_id}
                onChange={(e) => onSimpleFieldChange("type_id", e.target.value)}
                options={renditionType}
                disabled={detail.length}
              /> */}
              <BadgeField
                name={"rendition_type"}
                label={"Tipo de rendicion"}
                text={
                  renditionType.find((e) => e.value === fields.type_id).text
                }
                badgeClass={"plate_number"}
                variant={"primary"}
              />
            </div>
          </div>
          <h4>Rendiciones</h4>
          <CustomAccordion
            title={`Ingresos - ${incomes.length}`}
            key="ingresos-accordion"
          >
            <CustomTable
              addOrEditDetail={addOrEditDetail}
              header_id={id}
              values={incomes}
            />
          </CustomAccordion>
          <CustomAccordion
            title={`Retiros - ${withdraws.length}`}
            key="retiros-accordion"
          >
            <CustomTable
              addOrEditDetail={addOrEditDetail}
              header_id={id}
              values={withdraws}
            />
          </CustomAccordion>
          <CustomAccordion title={"Agregar Rendición"} key="add-accordion">
            <div className="pt-4">
              <div className="row">
                <div className="col">
                  <InputField
                    label={"Monto"}
                    value={fields.amount}
                    onChange={(e) =>
                      onSimpleFieldChange("amount", e.target.value)
                    }
                    name={"amount"}
                  />
                </div>
                <div className="col">
                  <SelectField
                    label={"Condición"}
                    name={"rendition_condition"}
                    onChange={(e) =>
                      onSimpleFieldChange(
                        "rendition_condition_id",
                        e.target.value
                      )
                    }
                    value={fields.rendition_condition_id}
                    options={condition}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <SelectField
                    label={"Concepto"}
                    name={"rendition_concept"}
                    value={fields.rendition_concept_id}
                    onChange={(e) =>
                      onSimpleFieldChange(
                        "rendition_concept_id",
                        e.target.value
                      )
                    }
                    options={concepts}
                  />
                </div>
                <div className="col">
                  <SelectField
                    label={"Origen"}
                    name={"rendition_origin"}
                    value={fields.rendition_origin_id}
                    onChange={(e) =>
                      onSimpleFieldChange("rendition_origin_id", e.target.value)
                    }
                    options={origins}
                  />
                </div>
                <div className="col">
                  <DatePickerField
                    selected={
                      fields.input_date ? new Date(fields.input_date) : null
                    }
                    label={"Fecha"}
                    onChange={(date) => onSimpleFieldChange("input_date", date)}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "10px",
                }}
              >
                <Button
                  className="btn-fill pull-right btn btn-primary btn-sm"
                  style={{ marginRight: "10px" }}
                  onClick={() => addOrEditDetail(id, null, 1, fields)}
                  disabled={!allowAddDetail}
                >
                  Agregar
                </Button>
              </div>
            </div>
          </CustomAccordion>
          <Comments
            reasons={reasons}
            comments={comments}
            statusList={statusList}
            onChangeSelect={(e) =>
              onSimpleFieldChange("comment_reason_id", e.target.value)
            }
            onChangeObs={(e) => onSimpleFieldChange("comment", e.target.value)}
          />
        </div>
        <br />
        <div className="modal-footer">
          <div>
            <Button
              className="btn-fill pull-right btn btn-primary btn-sm"
              onClick={submit}
            >
              Actualizar
            </Button>
          </div>
          <div>
            <Button
              className="btn-fill pull-right btn btn-primary btn-sm"
              onClick={() => {
                handleClose();
                refresh();
              }}
            >
              Cerrar
            </Button>
          </div>
        </div>
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
      </Form>
    </div>
  );
};

export default Edit;
