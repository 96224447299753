import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL;

const getAll = async (props) => {
  return await axios.get(`${API_URL}/permissions?limit=${props.countItems}&page=${props.currentPage}&sortField=${props.sortField}&sortType=${props.sortType}`, { headers: authHeader() });
};

const getById = async (id) => {
  return await axios.get(`${API_URL}/permissions/${id}`, { headers: authHeader() });
};

const create = (profileId, functionId, hasSelect, hasCreate, hasUpdate, hasDelete) => {
  return axios.post(`${API_URL}/permissions`, {
    profile_id: profileId,
    function_id: functionId,
    has_select: hasSelect,
    has_create: hasCreate,
    has_update: hasUpdate,
    has_delete: hasDelete
  }, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      }
      throw new Error(response.data.message);
    });
};

const edit = (id, hasSelect, hasCreate, hasUpdate, hasDelete) => {
  return axios.put(`${API_URL}/permissions`, {
    id,
    has_select: hasSelect,
    has_create: hasCreate,
    has_update: hasUpdate,
    has_delete: hasDelete
  }, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      }
      throw new Error(response.data.message);
    });
};

const del = (id) => {
  return axios.post(`${API_URL}/permissions/delete`, { id }, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

export default {
  getAll,
  create,
  getById,
  edit,
  del
};
