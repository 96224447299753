import React from "react";
import { Badge } from "react-bootstrap";

const ApplyBadge = ({ criteria }) =>
  criteria ? (
    <Badge bg="warning">Aplica</Badge>
  ) : (
    <Badge bg="secondary">No Aplica</Badge>
  );

const CheckBoxBlock = ({ item, onChangeChecks, index }) => (
  <div className="row" key={index}>
    <div className="col-7" key={index + 1} style={{marginLeft:"-2.5%"}}>
      <strong className="capitalize">{item.name}</strong>
    </div>
    <div className="col" key={index + 2}>
      <ApplyBadge criteria={item.received != null} />
    </div>
    <div className="col" key={index + 3}>
      {item.received != null ? (
        <input
          type="checkbox"
          checked={item.received}
          onChange={(e) => onChangeChecks(null, index, e.target.checked)}
        />
      ) : null}
    </div>
  </div>
);

const CheckForms = ({ data, onSimpleFieldChange,columns }) => {
  const onChangeChecks = (_, index, fieldValue) => {
    data[index].received = fieldValue;
    onSimpleFieldChange("forms", data);
  };

  return (
    <div className="container" style={{marginTop:"10px"}}>
      <div className="row">
        <div className="col-7" style={{marginLeft:"-2.5%"}}>&nbsp;</div>
        {columns.map((column,index) => {
          return <div className="col" key={index}>
            <strong>{column.name}</strong>
          </div>
        })}
      </div>
      {data.map((check, index) => {
        return (
          <CheckBoxBlock
            item={check}
            index={index}
            onChangeChecks={onChangeChecks}
          />
        );
      })}
    </div>
  );
};

export default CheckForms;
