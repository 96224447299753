import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAll = async (props) => {
  return await axios.get(`${API_URL}/profiles?limit=1000&page=0&sortField=${props.sortField}&sortType=${props.sortType}`, { headers: authHeader() });
};

const getById = async (id) => {
  let r;
  return axios.get(`${API_URL}/profiles/${id}`, { headers: authHeader() })
  .then((response) => {
      if (response.data.success) {
          r = response.data;
      } else {
        switch (response.data.error_code) {
          case 'NOT_FOUND':
            r =  null;
            break;
          default:
            r = null;
            throw new Error(response.data.message);
        }
      }
      return r;
  }).catch((err) => {
    return null;
  });
};

const create = (name, description) => {
  return axios.post(API_URL + "/profiles", {name, description}, { headers: authHeader() })
  .then((response) => {
      if (response.data.success) {
          return response.data;
      } else {
          throw new Error(response.data.message);
      }
  }).catch((err) => {
    console.log(err);
    return null;
  });
};

const edit = (id, name, description) => {
  return axios.put(`${API_URL}/profiles`, { id, name, description }, { headers: authHeader() })
  .then((response) => {
      if (response.data.success) {
          return response.data;
      } else {
          throw new Error(response.data.message);
      }
  }).catch((err) => {
    console.log(err);
    return null;
  });
};

const del = (id) => {
  return axios.post(`${API_URL}/profiles/delete`, {id}, { headers: authHeader() })
  .then((response) => {
      if (response.data.success) {
          return response.data;
      } else {
          throw new Error(response.data.message);
      }
  }).catch((err) => {
    console.log(err);
    return null;
  });
};

export default {
  getAll,
  create,
  getById,
  edit,
  del
};
