import React from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import moment from "moment";

const icon = {
  primary: "fas fa-bell",
  secondary: "fas fa-bell",
  success: "fas fa-check-circle",
  danger: "fas fa-times-circle",
  warning: "fas fa-exclamation-circle",
  info: "fas fa-info-circle",
  light: "fas fa-bell",
  dark: "fas fa-bell",
};

const whiteText = ["success", "dark", "danger"];

const ToastNotification = ({
  delay = 5000,
  variant = "success",
  header = "",
  body = "",
  onClose,
}) => (
  <ToastContainer position="top-center" className="p-3">
    <Toast onClose={onClose} show={true} delay={delay} autohide bg={variant}>
      <Toast.Header>
        <i className={`${icon[variant]} me-2`}></i>
        <strong className="me-auto">{header}</strong>
        <small>{moment().format("H:mm")}</small>
      </Toast.Header>
      <Toast.Body className={whiteText.includes(variant) && "text-white"}>
        {body}
      </Toast.Body>
    </Toast>
  </ToastContainer>
);

export default ToastNotification;
