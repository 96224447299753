import { purchaseStatus } from "../../../variables/constants";

const dropdownStatus = Object.entries(purchaseStatus)
  .filter((s) => s[1].group === "folio")
  .map((s) => {
    return { text: s[1].text, value: s[1].value };
  })

export const filtersFolioInProgress = {
  plateNumber: {
    type: "text",
    name: "plateNumber",
    placeholder: "Dominio",
    value: null,
    size: 6,
  },
  status: {
    type: "multiSelect",
    name: "status",
    options: dropdownStatus,
    placeholder: "Estado",
    value: [],
    size: 6,
  },
};
