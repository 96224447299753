import React from "react";
import Textarea from "react-validation/build/textarea";

const ObservationBox = ({ onChangeObs }) => {
  return (
    <div className="row">
      <div className="col">
        <label className="capitalize">
          <strong>Observación</strong>
        </label>
        <div>
          <Textarea
            className="form-control"
            rows={3}
            name="observation"
            placeholder="Ingrese un comentario"
            onChange={onChangeObs}
          />
        </div>
      </div>
    </div>
  );
};

export default ObservationBox;
