import { constants } from "variables/constants";

export const headSort = [
    { text: "Id", field: "vehicle_id", model: null },
    { text: constants.domain, field: "plate_number", model: null },
    { text: constants.status, field: "purchase_file_status", model: null },
    { text: "semana toma", field: "createdAt", model: null },
    { text: "Recepción", field: "createdAt", model: null },
    { text: constants.received, field: "is_recived_date", model: null },
    { text: "Firma 08", field: "updatedAt", model: "purchase_file_form08,forms_status_history" },
    { text: constants.lossRequest, field: "loss_request", model: null },
    {
      text: constants.finalPaymentDate,
      field: "final_payment_date",
      model: null,
    },
    { text: "Validados", field: null, model: null },
    // { text: "Remaining", field: null, model: null },
    { text: "Radicación", field: "current_location", model: null },
    { text: "Tentativo", field: "issuer_entity_id", model: null },
    { text: constants.zone, field: null, model: null },
    { text: constants.receptionPlace, field: "reception_place_id", model: null },
    { text: "Distancia", field: null, model: null },
    // { text: "Alerta", field: null, model: null },
    { text: "Aging", field: null, model: null },
    { text: "Días en tramite", field: null, model: null },
    { text: "", field: null, model: null },
  ];