import React, { useState } from "react";
import { Button } from "react-bootstrap";

const Requirement = ({ data, id, onChange, serviceName }) => {
  const [checked, setChecked] = useState(data.status);

  // Cambio de estado
  const handleRequirements = async (status) => {
    const response = await serviceName(data.id, id, status);
    if (response.success) {
      setChecked(status);
      onChange(data.requirement_id, status);
    }
  };

  return (
    <Button
      variant={checked ? "success" : "danger"}
      size="sm"
      className="btn-fill btn text-capitalize"
      onClick={() => handleRequirements(!checked)}
    >
      {data.description.toLowerCase()}
    </Button>
  );
};

export default Requirement;
