import axios from "axios";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_SELLS_URL;

const getAll = async (params) => {
  return await axios.post(`${API_URL}/sells-folio/list`, params, { headers: authHeader() });
};

const getById = async (id) => {
  return await axios.get(`${API_URL}/sells-folio/${id}`, { headers: authHeader() });
};

const updateForms = async (id,data) => {
  return await axios.put(`${API_URL}/sells-folio/${id}`,data,{
    headers: authHeader(),
  })
}

export default {
  getAll,
  getById,
  updateForms
};
