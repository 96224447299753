import ConfirmationAlert from "components/ConfirmationAlert/confirmationAlert";
import { DatePickerField } from "components/Form";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-validation/build/form";
import { useConfirmationAlert, useToast } from "../../../hooks/";
import SellsService from "../../../services/sells/sells.service";
import {
  sells_status_select,
  sells_status,
  status08,
} from "../../../variables/constants";
import { Comments } from "../../Comments/index";
import { CarInfo, CheckForm, DataClient, Form08 } from "./components/";

const columns = [
  {
    name: "Teorico",
  },
  {
    name: "Recibido",
  },
];

const Edit = (props) => {
  const {
    data: {
      response: {
        id,
        opportunity_id,
        plate_number,
        delivery_type,
        financed,
        status_ns,
        status,
        delivery_date,
        status_id,
        financing_provider,
        vehicle_price,
        auto_trade_in,
        owner,
        signature_place,
        bill_created_date,
        comments,
        loss_request,
        estimated_delivery_date,
        restore_status,
        estimated_end_trial,
        stock_id,
        canceled_status_id,
        financed_date,
        diff_days,
        suspended_days,
        bacs_id,
        report_vehicle_sale_date,
        transaction_type,
      },
      form_08,
      forms,
    },
    handleClose,
    refresh,
  } = props;

  const initialFormState = {
    id,
    domain: plate_number,
    delivery_type,
    prendado: financed,
    status_ns,
    status,
    delivery_date: delivery_date ? new Date(delivery_date) : null,
    payment_date: bill_created_date ? new Date(bill_created_date) : null,
    provider: financing_provider,
    price: vehicle_price,
    car_trade_in: auto_trade_in ? 1 : 0,
    finish_date_test: estimated_end_trial
      ? new Date(estimated_end_trial)
      : null,
    owner: owner != null ? owner : [],
    signature_place,
    observation: null,
    cancel_reason_id: null,
    forms,
    form_08,
    loss_request,
    estimated_delivery_date: estimated_delivery_date
      ? new Date(estimated_delivery_date)
      : null,
    stock_id,
    canceled_status_id,
    financed_date,
    diff_days,
    suspended_days,
    bacs_id,
    report_vehicle_sale_date,
    transaction_type,
  };

  const [fields, setFields] = useState(initialFormState);
  const [reasons, setReasons] = useState([]);
  const { confirmationModal, handleConfirmationModal } = useConfirmationAlert();
  const addToast = useToast();

  const getReasons = async () => {
    try {
      const response = await SellsService.getAllReasons();
      if (response.status === 200) {
        setReasons(
          response.data.response.filter((r) => !r.text.includes("[SUSPENDER]"))
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getReasons();
  }, []);

  const submit = async (finished = false) => {
    if (form_08.current_status !== "No encontrado") {
      if (form_08.received) {
        form_08.current_status = status08.RECEIVED.value;
      }
      forms.push(form_08);
    }

    const forms_witout_nulls = forms.filter((x) => x.received != null);

    const data = {
      opportunity_id: opportunity_id,
      comment: fields.observation,
      comment_reason_id: fields.cancel_reason_id,
      auto_trade_in: fields.car_trade_in,
      delivery_date: fields.delivery_date,
      vehicle_price: fields.price,
      financing_provider: fields.provider,
      bill_created_date: fields.payment_date,
      financed: fields.prendado,
      status_id: !finished ? status_id : sells_status.FOLIO_IN_PROGRESS.value,
      forms: forms_witout_nulls,
      loss_request: fields.loss_request,
      financed_date: fields.financed_date,
      report_vehicle_sale_date: fields.report_vehicle_sale_date,
    };
    const response = await SellsService.updateSells(opportunity_id, data);
    if (response.status === 200) {
      addToast({
        variant: "success",
        header: "Ventas",
        body: "Se actualizó la información correctamente",
      });
      handleClose();
      refresh();
    }
  };

  const changeStatusToObs = async () => {
    let status = sells_status.VALIDATION_EXCEPTION.value;

    if (status_id === sells_status.VALIDATION_EXCEPTION.value) {
      status = restore_status;
    }
    const data = {
      opportunity_id: opportunity_id,
      comment: fields.observation,
      comment_reason_id: fields.cancel_reason_id,
      toObserve: status, // VALIDATION_EXCEPTION
    };
    const response = await SellsService.updateSells(opportunity_id, data);
    if (response.status === 200) {
      addToast({
        variant: "success",
        header: "Ventas",
        body: "Se actualizó la información correctamente",
      });
      handleClose();
      refresh();
    }
  };

  const handleStatusObs = () => {
    let actual_status = sells_status.VALIDATION_EXCEPTION.value;
    if (status_id === sells_status.VALIDATION_EXCEPTION.value) {
      actual_status = restore_status;
    }
    const statusBefore = sells_status_select.filter(
      (x) => x.value === actual_status
    )[0];
    handleConfirmationModal(
      status !== sells_status.VALIDATION_EXCEPTION.value
        ? "Observado"
        : statusBefore.text,
      changeStatusToObs
    );
  };

  const validateForms = () => {
    let count = 0;
    const trueForms = forms.filter((x) => x.received != null);
    forms.forEach((x) => {
      if (x.received === true) {
        count++;
      }
    });
    if (count === trueForms.length) {
      return true;
    }

    return false;
  };

  const disabledButton = () => {
    const isFormsValid = validateForms();
    const hasValidReport =
      fields.bacs_id != null && fields.report_vehicle_sale_date != null;
    const validFinanced =
      !fields.prendado || (fields.prendado && fields.provider && fields.price);

    if (
      ((isFormsValid && hasValidReport) ||
        (isFormsValid && fields.bacs_id === null)) &&
      validFinanced
    ) {
      return false;
    }

    return true;
  };

  const changeStatusToFinished = () => {
    const validate = validateForms();
    if (validate) {
      handleConfirmationModal("Folio en progreso", () => submit(true));
    } else {
      addToast({
        variant: "danger",
        header: "Ventas",
        body: "Hay formularios que no se encuentran recibidos",
      });
    }
  };

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <CarInfo
            fields={fields}
            disabledAll={false}
            onSimpleFieldChange={onSimpleFieldChange}
          />
          <div className="row">
            {fields.prendado.toString() === "true" ? (
              <DatePickerField
                label={"Recepción Prenda"}
                selected={
                  fields.financed_date ? new Date(fields.financed_date) : null
                }
                onChange={(date) => onSimpleFieldChange("financed_date", date)}
                dateFormat="dd/MM/yyyy"
              />
            ) : null}
          </div>
          <div className="row">
            {fields.bacs_id ? (
              <DatePickerField
                label={"Denuncia de venta"}
                selected={
                  fields.report_vehicle_sale_date
                    ? new Date(fields.report_vehicle_sale_date)
                    : null
                }
                onChange={(date) =>
                  onSimpleFieldChange("report_vehicle_sale_date", date)
                }
                dateFormat="dd/MM/yyyy"
              />
            ) : null}
          </div>
          <br />
          <DataClient owner={owner} />
          <Form08
            data={fields.form_08}
            onSimpleFieldChange={onSimpleFieldChange}
          />
          <CheckForm
            data={fields.forms}
            onSimpleFieldChange={onSimpleFieldChange}
            columns={columns}
          />
          <br />
          <Comments
            reasons={reasons}
            comments={comments}
            statusList={sells_status}
            onChangeSelect={(e) =>
              onSimpleFieldChange("cancel_reason_id", e.target.value)
            }
            onChangeObs={(e) =>
              onSimpleFieldChange("observation", e.target.value)
            }
          />
        </div>
        <br />
        <div className="modal-footer">
          <div>
            <Button
              className="btn-fill pull-right btn btn-primary btn-sm"
              onClick={() => submit(false)}
            >
              Actualizar
            </Button>
          </div>
          {status_id !== sells_status.ERP_VENTAS_SUSPENDED.value ? (
            <div className="row">
              <Button
                className="btn-fill pull-right btn btn-warning btn-sm"
                onClick={handleStatusObs}
                disabled={
                  fields.observation == null ||
                  fields.cancel_reason_id == null ||
                  fields.stock_id == null
                }
              >
                {status !== sells_status.VALIDATION_EXCEPTION.value
                  ? "Observar"
                  : "Desobservar"}
              </Button>
            </div>
          ) : null}
          <div className="row">
            <Button
              className="btn-fill pull-right btn btn-primary btn-sm"
              onClick={changeStatusToFinished}
              disabled={disabledButton()}
            >
              Aceptar
            </Button>
          </div>
        </div>
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
      </Form>
    </div>
  );
};

export default Edit;
