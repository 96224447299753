import { useState } from "react";

function useGrid(sort, countItems = 10) {
  //Paginate
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  //Sort
  const [currentSort, setCurrentSort] = useState(sort);
  const [body, setBody] = useState({body:[],loading: true});
  //Refresh
  const [refresh, setRefresh] = useState(Date.now());

  const refreshGrid = () => {
    setRefresh(new Date());
  };

  const cleanPage = () => {
    cleanBody()
    setCurrentPage(0);
  }

  const handleToPage = (page, e) => {
    e.preventDefault();
    cleanBody()
    setCurrentPage(page);
  };

  const handleSort = (model, field, type) => {
    cleanBody()
    setCurrentSort({ model, field, type });
    setCurrentPage(0);
  };

  const handleTotalPage = (totalPages) => {
    setTotalPage(totalPages);
  };

  const handleTotalItems = (totalItems) => {
    setTotalItems(totalItems);
  };

  const handleBody = (rows) => {
    setBody({body:rows,loading: false});
  };

  const cleanBody = () => {
    setBody({body:[],loading: true});
  }

  return {
    currentPage,
    totalPage,
    totalItems,
    currentSort,
    body,
    countItems,
    refresh,
    handleToPage,
    handleSort,
    handleTotalItems,
    handleBody,
    handleTotalPage,
    refreshGrid,
    cleanPage
  };
}

export default useGrid;
