import React from 'react';
import Dropdown  from '../../Table/Dropdown'

const ReasonBox = ({ reasons, onChangeSelect }) => {
    return (
      <div className="row">
        <div className="col">
          <label className="capitalize">
            <strong>Motivo del comentario</strong>
          </label>
          <div>
            <Dropdown
              className="form-control"
              name="cancel_reason_id"
              placeholder="Motivo del comentario"
              onSelect={onChangeSelect}
              options={reasons}
            />
          </div>
        </div>
      </div>
    );
  };

  export default ReasonBox;