import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAll = async (props) => {
  return await axios.get(
    `${API_URL}/management/purchase_files?limit=${props.countItems}&page=${props.currentPage}&searchq=${props.keyword}&status=${props.status}`,
    { headers: authHeader() }
  );
};

const getAllCheckList = async (data) => {
  const url = `${API_URL}/take/list`;
  return await axios.post(url, { ...data }, { headers: authHeader() });
};

const getAllRejected = async (data) => {
  const url = `${API_URL}/rejected/list`;
  return await axios.post(url, { ...data }, { headers: authHeader() });
};

const getAllDomains = async (props) => {
  const url = `${API_URL}/management/domains?limit=${props.limit}&search=${props.search}`;
  return await axios.get(url, { headers: authHeader() });
};

const edit = (data) => {
  return axios
    .put(`${API_URL}/management/purchase_file_form`, data, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const getChecklistModalDataById = async (id) => {
  return await axios.get(`${API_URL}/management/checklist-take/${id}`, {
    headers: authHeader(),
  });
};

const getById = async (id) => {
  return await axios.get(`${API_URL}/management/purchase_data_popup/${id}`, {
    headers: authHeader(),
  });
};

const saveformsValidated = async (forms) => {
  return axios
    .post(`${API_URL}/management/purchase-files/validate-form`, forms, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const getCommentReasons = async (id) => {
  return await axios.get(`${API_URL}/purchase-file-comment-reason`, {
    headers: authHeader(),
  });
};

const getSuspendedCommentReasons = async (id) => {
  return await axios.get(`${API_URL}/purchase-suspended-comment-reason`, {
    headers: authHeader(),
  });
};

export default {
  getAll,
  getAllCheckList,
  getAllRejected,
  getAllDomains,
  getChecklistModalDataById,
  getById,
  getCommentReasons,
  getSuspendedCommentReasons,
  saveformsValidated,
  edit,
};
