import React from "react";
import constants, { additional_status } from "../../../../variables/constants";
import { BadgeField } from "../components";

const DomainAndStatus = ({ readOnly, profile }) => {
  return (
    <>
      <div className="row">
        <BadgeField
          label={"Tipo"}
          text={readOnly.type}
          badgeClass={"plate_number"}
          variant={"info"}
        />
        <BadgeField
          label={"Dominio"}
          text={readOnly.plate_number}
          badgeClass={"plate_number"}
          variant={"warning"}
        />
        <BadgeField
          label={"Stock ID"}
          text={readOnly.vehicle_id || "Pendiente"}
          badgeClass={`plate_number`}
          variant={readOnly.vehicle_id ? "info" : "danger"}
        />
        {readOnly.opportunity_id && (
          <BadgeField
            label={"Oportunidad ID"}
            text={readOnly.opportunity_id}
            badgeClass={`plate_number`}
            variant={readOnly.opportunity_id ? "info" : "danger"}
          />
        )}
        <BadgeField
          label={"Estado"}
          text={additional_status[readOnly.status_id].text}
          badgeClass={`badge ${
            additional_status[readOnly.status_id].color
          } capitalize plate_number`}
        />
      </div>
      <div className="row">
        <BadgeField
          label={"Lugar de Toma"}
          text={readOnly.reception_place}
          badgeClass={`capitalize plate_number`}
          variant={"secondary"}
        />
        {profile !== constants.notaryProfile && (
          <BadgeField
            label={"Lugar de Firma"}
            text={readOnly.notary_office}
            badgeClass={`capitalize plate_number`}
            variant={"info"}
          />
        )}
        <BadgeField
          label={"Fecha Solicitud"}
          text={readOnly.request_date}
          badgeClass={`plate_number`}
          variant={"secondary"}
        />
        <BadgeField
          label={"Concepto"}
          text={readOnly.concept}
          badgeClass={`plate_number`}
          variant={"success"}
        />
      </div>
    </>
  );
};

export default DomainAndStatus;
