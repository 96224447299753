import React, {useEffect, useState} from "react";
import { Table } from "react-bootstrap";
import Card from "components/Card/Card.jsx";

const Energas = (props) => {  
    const [energas, setEnergas] = useState(false);

    useEffect(() => {
        setEnergas(props.data);
    }, [props.data]);

    return (            
        <div>
            <Card
                ctTableFullWidth
                ctTableResponsive
                title="Energas"
                content={
                    (props.loading) 
                    ? 'Loading...'
                    : <Table striped hover>
                        <tbody>
                            <tr>
                                <td><strong>Fecha</strong></td>
                                <td>
                                    {(energas && energas.date) 
                                    ? energas.date
                                    : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td><strong>PEC</strong></td>
                                <td>
                                    {(energas && energas.pec) 
                                    ? energas.pec
                                    : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td><strong>CUIT</strong></td>
                                <td>
                                    {(energas && energas.cuit) 
                                    ? energas.cuit
                                    : '-'}
                                </td>
                            </tr>       
                            <tr>
                                <td><strong>Oblea</strong></td>
                                <td>
                                    {(energas && energas.oblea) 
                                    ? energas.oblea
                                    : '-'}
                                </td>
                            </tr> 
                            <tr>
                                <td><strong>Oblea Anterior</strong></td>
                                <td>
                                    {(energas && energas.lastOblea) 
                                    ? energas.lastOblea
                                    : '-'}
                                </td>
                            </tr> 
                            <tr>
                                <td><strong>Dominio</strong></td>
                                <td>
                                    {(energas && energas.plateNumber) 
                                    ? energas.plateNumber
                                    : '-'}
                                </td>
                            </tr> 
                            <tr>
                                <td><strong>CUIT</strong></td>
                                <td>
                                    {(energas && energas.cuit) 
                                    ? energas.cuit
                                    : '-'}
                                </td>
                            </tr>                                                 
                        </tbody>
                    </Table>                          
                }
            />
        </div>
  );
};

export default Energas;
