import React from "react";

const NotFound = () => {

    return (
        <>
            <h3>Error 404!</h3>
            <p>La página que estas solicitando ya no existe o fue movida.</p>
        </>
    );
};

export default NotFound;
