import { constants } from "variables/constants";

export const headSort = [
  { text: "Id", field: "vehicle_id", model: null },
  { text: constants.domain, field: "plate_number", model: null },
  { text: constants.status, field: "purchase_file_status", model: null },
  { text: "Recepción", field: "createdAt", model: null },
  { text: "Radicación", field: "current_location", model: null },
  {
    text: constants.transactionType,
    field: "transaction_type_id",
    model: null,
  },
  { text: constants.agentPresenting, field: "agent_id", model: null },
  { text: "Empleado", field: "updatedUser", model: null },
  { text: constants.zone, field: null, model: null },
  { text: "Motivo de rechazo", field: "rejected_reason", model: null },
  // { text: "", field: null, model: null },
];
