import {
  additionalConcepts,
  notaries,
  receptionPlaces,
} from "../../../utils/Service";
import { additionalType, additionalStatus } from "../../../variables/constants";

export const filterFields = {
  vehicle_id: {
    type: "text",
    name: "vehicle_id",
    placeholder: "Stock ID",
    value: "",
    size: 2,
  },
  plateNumber: {
    type: "text",
    name: "plateNumber",
    placeholder: "Dominio",
    value: "",
    size: 2,
  },
  type: {
    type: "select",
    name: "type",
    options: additionalType,
    placeholder: "Tipo",
    value: "",
    size: 2,
  },
  concept: {
    type: "select",
    name: "concept",
    options: additionalConcepts(),
    placeholder: "Concepto",
    value: "",
    size: 2,
  },
  receptionPlace: {
    type: "select",
    name: "receptionPlace",
    options: receptionPlaces(),
    placeholder: "Lugar de Toma",
    value: "",
    size: 2,
  },
  notaryOffice: {
    type: "select",
    name: "notaryOffice",
    options: notaries(),
    placeholder: "Lugar de Firma",
    value: "",
    size: 2,
  },
  status: {
    type: "multiSelect",
    name: "status",
    options: additionalStatus,
    placeholder: "Estado",
    value: [],
    size: 8,
  },
  receivedStart: {
    type: "date",
    name: "receivedStart",
    placeholder: "Recibido Desde",
    value: null,
    size: 2,
  },
  receivedEnd: {
    type: "date",
    name: "receivedEnd",
    placeholder: "Recibido Hasta",
    value: null,
    size: 2,
  },
};
