import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Col, Row, Table } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Pagination from "components/Table/Pagination.js";
import Loading from "../Loading/loading";

const List = (props) => {
  const {
    thead,
    tbody,
    actions,
    next,
    totalItems,
    currentPage,
    totalPage,
    toPage,
    sortable = false,
    currentSort = null,
    handleSort,
    emptyFilters
  } = props;

  const [data, setData] = useState({ data: [], loading: true });

  useEffect(() => {
    if (tbody && tbody.body) {
      setData({ body: tbody.body, loading: tbody.loading });
    } else {
      setData({ body: tbody, loading: false });
    }
  }, [tbody]);

  return (
    <>
      <Row>
        <Col md={12}>
          <Card
            ctTableFullWidth
            ctTableResponsive
            legend={`Registros: ${totalItems}`}
            content={
              <Table
                striped
                hover
                style={{ display: "contents" }}
              >
                <thead>
                  <tr>
                    {sortable
                      ? thead.map((prop, key) => (
                          <th key={key}>
                            {prop.field !== null ? (
                              <span
                                className={`sort-by sort-by-${
                                  currentSort?.model === prop.model &&
                                  currentSort?.field === prop.field
                                    ? currentSort?.type.toLowerCase()
                                    : "none"
                                }`}
                                onClick={() =>
                                  handleSort(
                                    prop.model,
                                    prop.field,
                                    currentSort?.model === prop.model &&
                                      currentSort?.field === prop.field &&
                                      currentSort?.type === "ASC"
                                      ? "DESC"
                                      : "ASC"
                                  )
                                }
                              >
                                {prop.text}
                              </span>
                            ) : (
                              <span className="sort-by-block">{prop.text}</span>
                            )}
                          </th>
                        ))
                      : thead.map((prop, key) => <th key={key}>{prop}</th>)}
                  </tr>
                </thead>
                {!data.loading ? (
                  <tbody>
                    {data.body.map((prop, key) => {
                      return (
                        <tr key={key}>
                          {Object.entries(prop)
                            .filter((c) => c[0][0] !== "_")
                            .map((item, key) => {
                              return <td key={key}>{item[1]}</td>;
                            })}
                          {actions && (
                            <td className="td-actions text-right">
                              {actions(prop)}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <Loading cols={thead.length} />
                )}
              </Table>
            }
          />
        </Col>
      </Row>
      {totalPage > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          toPage={toPage}
        />
      )}

      {!data.loading && totalItems === 0 && next && (
        <>
          {emptyFilters ? (<>
            <br />
            <p className="d-flex flex-column">
              <strong className="text-warning">⚠️ No se encontraron filtros para mostrar resultados.</strong> 
              <small className="text-muted fw-lighter">Solo se mostrarán los registros que cumplan con los filtros seleccionados.</small>
            </p>
          </>) : (<>
            <br />
            <NavLink to={next?.link}>
              <p>
                No se encontraron resultados puede dirigirse a la siguiente
                pantalla <strong>{next?.text}</strong>
              </p>
            </NavLink>
          </>)}
        </>
      )}

    </>
  );
};

export default List;
