import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAll = async (props) => {
  return await axios.get(`${API_URL}/reception-place?limit=1000&page=0&sortField=${props.sortField}&sortType=${props.sortType}`, { headers: authHeader() });
};

const getById = async (id) => {
  return await axios.get(`${API_URL}/reception-place/${id}`, { headers: authHeader() });
};

const create = (payload) => {
  return axios.post(`${API_URL}/reception-place`, {
    ...payload,
  }, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const edit = (payload) => {
  return axios.put(`${API_URL}/reception-place`, {
    ...payload,
  }, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const del = (id) => {
  return axios.delete(`${API_URL}/reception-place`, { id }, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

export default {
  getAll,
  getById,
  create,
  edit,
  del,
};
