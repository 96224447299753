import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import moment from "moment";
import InformationAgent from "./informationAgent";
import Dropdown from "../../components/Table/Dropdown";
import AgentStatsService from '../../services/agentStats.service';
import { constants, login, } from "../../variables/constants";

const years = [];
for (let index = moment().year(); index >= 2021; index--) {
  years.push({ value: index, text: index });
}

const weeks = [];
for (let index = 1; index <= 53; index++) {
  weeks.push({ value: index, text: index });
}

const agentProfile = [
  constants.agentExternalProfile,
  constants.agentInternalProfile
];

// Guardado de los filtros
const DASHBOARD_LOCAL_STORAGE = 'dashboard_filters_agent';
const filters = JSON.parse(localStorage.getItem(DASHBOARD_LOCAL_STORAGE));

const Dashboard = () => {
  const [year, setYear] = useState(filters?.year ?? constants.dropdownDefault);
  const [week, setWeek] = useState(filters?.week ?? constants.dropdownDefault);
  const [agent, setAgent] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(false);
  const [period, setPeriod] = useState('');
  const [objetive, setObjetive] = useState(false);

  const handleSearchYear = (e) => {
    setYear(e.target.value !== constants.dropdownDefault ? e.target.value : constants.dropdownDefault);
  }

  const handleSearchWeek = (e) => {
    setWeek(e.target.value !== constants.dropdownDefault ? e.target.value : constants.dropdownDefault);
  }

  useEffect(() => {
    // Para obtener el perfil
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (!user) {
      window.location.assign(login);
      return;
    }
    setAgent(agentProfile.includes(user.data.user.profile.name.toLowerCase()) ? user.data.user.id : null);
    setProfile(user.data.user.profile.name.toLowerCase());
  }, []);

  useEffect(() => {
    if (profile !== null) {
      setLoading(true);

      const params = {
        id: agent,
        year: year !== constants.dropdownDefault ? year : null,
        week: week !== constants.dropdownDefault ? week : null,
      };

      localStorage.setItem(DASHBOARD_LOCAL_STORAGE, JSON.stringify(params));

      if (params.year !== null && params.week !== null) {
        AgentStatsService.getStatsGlobal(params).then(response => {
          if (response.data.success && response.data.response.length > 0) {
            setInfo(response.data.response.map(i => ({ ...i, open: Number(i.open), closed: Number(i.closed) })));
            setPeriod(`Periodo ${moment(response.data.response[0].monday).format('DD/MM/YYYY')} - ${moment(response.data.response[0].monday).add(6, 'd').format('DD/MM/YYYY')}`);
            AgentStatsService.getObjetive(params).then(response => {
              setObjetive(Number(response.data.response[0].objetive));
            });
          } else {
            setInfo(false);
            setPeriod(false);
          }
          setLoading(false);
        });
      } else {
        setInfo(false);
        setPeriod(false);
        setLoading(false);
      }
    }
  }, [profile, agent, year, week]);

  return (
    <div className="content content-dashboard">
      <Container fluid>
        <div className="form-group content-search-input">
          <div>
            <Dropdown
              className="form-control"
              name="year"
              placeholder="Año"
              onSelect={handleSearchYear}
              selected={year}
              options={years}
            />
          </div>
          <div>
            <Dropdown
              className="form-control"
              name="week"
              placeholder="Semana"
              onSelect={handleSearchWeek}
              selected={week}
              options={weeks}
            />
          </div>
        </div>
        {period
          ? (
            <div>
              <h4>
                {period}
                <span className="objetive">Objetivo Semanal: {objetive}</span>
              </h4>
            </div>)
          : null
        }

        <div className="content-dashboard-agent">
          {info && info.length > 0
            ? info.map(i => <InformationAgent data={i} loading={loading} objetive={objetive} key={i.name} />)
            : <><span>sin datos</span></>
          }
        </div>
      </Container>
    </div>
  );
};

export default Dashboard;
