import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import { SDPBadge, statusBadge, diffDateBadge } from "../../utils/Badges";

const Deadline = (props) => {
  const [data, setData] = useState(false);
  const [groupCheck, setGroupCheck] = useState([]);
  const [details, setDetails] = useState([]);

  const onChangeGroup = (e) => {
    const newGroupCheck = [...groupCheck];
    if (e.target.checked) {
      newGroupCheck.push(e.target.name);
    } else {
      newGroupCheck.splice(groupCheck.indexOf(e.target.name), 1);
    }
    setGroupCheck(newGroupCheck);
    setDetails([]);
  }

  useEffect(() => {
    setData(props.data);
    if (!props.data) {
      setDetails([]);
      setGroupCheck([]);
    }
  }, [props.data]);

  useEffect(() => {
    if (groupCheck.length > 0) {
      const tmpConcat = [];
      data
        .filter(g => groupCheck.includes(g.name))
        .forEach(element => {
          Array.prototype.push.apply(tmpConcat, element.items);
        });
      setDetails(tmpConcat);
    }
  }, [groupCheck, data]);

  return (
    <div className="deadline">
      <Card
        ctTableFullWidth
        ctTableResponsive
        title="Estados por Plazos"
        legend={<strong>Suma: {data ? props.parcial : ''}</strong>}
        content={
          (props.loading)
            ? 'Loading...'
            : <Table striped hover>
              <thead>
                <tr>
                  <th>Estado del Plazo</th>
                  <th>Cantidad</th>
                  <th>Porc. de esta tabla</th>
                  <th>Porc. sobre total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {(data && data.length > 0)
                  ? data.map(s => (
                    <tr key={s.name}>
                      <td>
                        <strong>{s.name}</strong>
                      </td>
                      <td>
                        {s.items.length}
                      </td>
                      <td>
                        {((s.items.length / props.parcial) * 100).toFixed(2)}%
                      </td>
                      <td>
                        {((s.items.length / props.total) * 100).toFixed(2)}%
                      </td>
                      <td>
                        {s.items.length > 0
                          ? (
                            <input
                              type="checkbox"
                              name={s.name}
                              value={s.name}
                              onChange={(e) => onChangeGroup(e)}
                            />)
                          : ''
                        }
                      </td>
                    </tr>))
                  : (<tr>
                    <td><strong>Sin Datos</strong></td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>)
                }
              </tbody>
            </Table >
        }
      />
      <Card
        ctTableFullWidth
        ctTableResponsive
        title="Detalle de Tramites por Plazos"
        legend={<strong>Suma: {details.length}</strong>}
        content={
          (props.loading)
            ? 'Loading...'
            : <Table striped hover>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Dominio</th>
                  <th>Estado</th>
                  <th>Diferencia En Días</th>
                </tr>
              </thead>
              <tbody>
                {(details && details.length > 0)
                  ? details.map(s => (
                    <tr key={s.vehicle_id}>
                      <td>
                        <strong>{s.vehicle_id}</strong>
                      </td>
                      <td>
                        {SDPBadge(s.plate_number, s.sdp)}
                      </td>
                      <td>
                        {statusBadge(s.status)}
                      </td>
                      <td>
                        {diffDateBadge(s.diff_days)}
                      </td>
                    </tr>))
                  : (<tr>
                    <td><strong>Sin Datos</strong></td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>)
                }
              </tbody>
            </Table >
        }
      />
    </div >
  );
};

export default Deadline;
