import image from "assets/img/sidebar-3.jpg";
import Footer from "components/Footer/Footer";
import AuthNavbar from "components/Navbars/AuthNavbar";
import { Component } from "react";
import routes from "routes.js";
import { style } from "variables/Variables.jsx";
import { RoutesAuthMapped } from "./wrappers";

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: image,
      color: "black",
      hasImage: true,
      fixedClasses: "dropdown show-dropdown open",
    };
  }

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "KAVAK";
  };

  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };

  render() {
    return (
      <div className="wrapper">
        <div
          id="main-panel"
          className="main-panel main-panel-login"
          ref="mainPanel"
        >
          <AuthNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <RoutesAuthMapped
            userLogged={this.state.userLogged}
          />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Auth;
