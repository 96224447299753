import { Button } from "react-bootstrap";
import Icon from "../Icon";

const ButtonIcon = ({
  onClick,
  name,
  title = "",
  size = null,
  color = "primary",
}) => (
  <Button variant="link" onClick={onClick} className="p-1 border-0">
    <Icon name={name} size={size} color={color} title={title} />
  </Button>
);

export default ButtonIcon;
