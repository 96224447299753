import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Chart from "react-apexcharts";
import moment from "moment";
import InformationNotary from "./informationNotary";
import ListStatusNotary from "./listStatusNotary";
import DeadlineNotary from "./deadlineNotary";
import Dropdown from "../../components/Table/Dropdown";
import NotaryService from "../../services/notary.service";
import StatusForm08Service from "../../services/form08/statusForm08.service";
import {
  constants,
  meses,
  status08Conditions,
  status08,
  login,
} from "../../variables/constants";
import { pieOptions } from "variables/Variables.jsx";

const years = [];
for (let index = moment().year(); index >= 2021; index--) {
  years.push({ value: index, text: index });
}

const months = [];
for (let index = 1; index <= 12; index++) {
  months.push({ value: index, text: meses[index] });
}

const weeks = [];
for (let index = 1; index <= 53; index++) {
  weeks.push({ value: index, text: index });
}

// Guardado de los filtros
const DASHBOARD_LOCAL_STORAGE = "dashboard_filters";
const filters = JSON.parse(localStorage.getItem(DASHBOARD_LOCAL_STORAGE));

const groups = [0, 0, 0, 1, 1, 2, 2, 3, 3];

const donutOptions = {
  labels: ["Abiertos", "Cerradas"],
  ...pieOptions,
};

const Dashboard = () => {
  const [year, setYear] = useState(filters?.year ?? constants.dropdownDefault);
  const [month, setMonth] = useState(
    filters?.month ?? constants.dropdownDefault
  );
  const [week, setWeek] = useState(filters?.week ?? constants.dropdownDefault);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(false);
  const [pieData, setPieData] = useState(false);
  const [series, setSeries] = useState([]);
  const [openCases, setOpenCases] = useState(false);
  const [closedCases, setClosedCases] = useState(false);
  const [statusCheck, setStatusCheck] = useState([]);
  const [deadlines, setDeadlines] = useState(false);
  const [deadlineTotal, setDeadlineTotal] = useState(0);
  const [loadingDeadline, setLoadingDeadline] = useState(false);
  const [notaryOffice, setNotaryOffice] = useState(null);

  const handleSearchYear = (e) => {
    setYear(
      e.target.value !== constants.dropdownDefault
        ? e.target.value
        : constants.dropdownDefault
    );
  };

  const handleSearchMonth = (e) => {
    setMonth(
      e.target.value !== constants.dropdownDefault
        ? e.target.value
        : constants.dropdownDefault
    );
    setWeek(constants.dropdownDefault);
  };

  const handleSearchWeek = (e) => {
    setWeek(
      e.target.value !== constants.dropdownDefault
        ? e.target.value
        : constants.dropdownDefault
    );
    setMonth(constants.dropdownDefault);
  };

  const onChangeStatus = (e) => {
    const newStatusCheck = [...statusCheck];
    if (e.target.checked) {
      newStatusCheck.push(e.target.name);
    } else {
      newStatusCheck.splice(statusCheck.indexOf(e.target.name), 1);
    }
    setStatusCheck(newStatusCheck);
  };

  useEffect(() => {
    // Para obtener el perfil y la escribania asociada si corresponde
    const user = JSON.parse(localStorage.getItem("user")) || null;
    if (!user) {
      window.location.assign(login);
      return;
    }
    if (user.data.user.profile.name.toLowerCase() === constants.notaryProfile) {
      NotaryService.getAllUsers({
        notary_office_id: null,
        user_id: user.data.user.id,
      }).then((notary_users) => {
        if (notary_users.data.success) {
          setNotaryOffice(notary_users.data.response[0].notary_office.id);
        }
      });
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    const params = {
      notary_office_id: notaryOffice,
      year: year !== constants.dropdownDefault ? year : null,
      month: month !== constants.dropdownDefault ? month : null,
      week: week !== constants.dropdownDefault ? week : null,
    };

    localStorage.setItem(DASHBOARD_LOCAL_STORAGE, JSON.stringify(params));

    StatusForm08Service.getStatsGlobal(params).then((response) => {
      if (response.data.count > 0) {
        // Lunes de la semana
        const monday = response.data.response[0].monday;
        // Cerrados
        const closedResult = response.data.response.filter(
          (s) => s.condition === status08Conditions.CLOSED.value
        );
        // Abiertos
        const openResult = response.data.response.filter(
          (s) => s.condition === status08Conditions.OPEN.value
        );
        // Errores
        const errorsResult = response.data.response.filter(
          (s) => s.current_status === status08.OBSERVED.value
        );
        // Total Días
        const sumDays = response.data.response
          .map((s) => s.diff_days)
          .reduce((a, b) => a + b, 0);
        setInfo({
          closed: closedResult.length,
          open: openResult.length,
          errors: errorsResult.length,
          daysAvg: (sumDays / response.data.count).toFixed(2),
          year,
          month,
          week,
          monday,
        });
        setClosedCases(closedResult);
        setOpenCases(openResult);

        // grafico de tortas
        setSeries([openResult.length, closedResult.length]);
        setPieData(true);
      } else {
        setPieData(false);
        setInfo(false);
        setClosedCases(false);
        setOpenCases(false);
      }
      setLoading(false);
    });
  }, [notaryOffice, year, month, week]);

  useEffect(() => {
    setLoadingDeadline(true);
    if (statusCheck.length > 0) {
      const deadlineGroups = [
        { name: "<=2", items: [] },
        { name: "3-4", items: [] },
        { name: "5-6", items: [] },
        { name: "7-8", items: [] },
        { name: ">=9", items: [] },
      ];
      const deadlineData = openCases
        .concat(closedCases)
        .filter((c) => statusCheck.includes(c.current_status));
      for (let index = 0; index < deadlineData.length; index++) {
        const element = deadlineData[index];
        const group = groups[element.diff_days] ?? 4;
        deadlineGroups[group].items.push(element);
      }
      setDeadlineTotal(deadlineData.length);
      setDeadlines(deadlineGroups);
    } else {
      setDeadlines(false);
    }
    setLoadingDeadline(false);
  }, [statusCheck, openCases, closedCases]);

  return (
    <div className="content content-dashboard">
      <Container fluid>
        <div className="form-group content-search-input">
          <div>
            <Dropdown
              className="form-control"
              name="year"
              placeholder="Año"
              onSelect={handleSearchYear}
              selected={year}
              options={years}
            />
          </div>
          <div>
            <Dropdown
              className="form-control"
              name="month"
              placeholder="Mes"
              onSelect={handleSearchMonth}
              selected={month}
              options={months}
            />
          </div>
          <div>
            <Dropdown
              className="form-control"
              name="week"
              placeholder="Semana"
              onSelect={handleSearchWeek}
              selected={week}
              options={weeks}
            />
          </div>
        </div>

        <div className="content-dashboard-information">
          <InformationNotary data={info} loading={loading} />
          {pieData ? (
            <Chart
              options={donutOptions}
              series={series}
              type="donut"
              width={500}
              height={320}
            />
          ) : null}
        </div>
        <div className="content-dashboard-information">
          <ListStatusNotary
            title="Abiertas"
            data={openCases}
            onChangeStatus={onChangeStatus}
            loading={loading}
          />
          <ListStatusNotary
            title="Cerradas"
            data={closedCases}
            onChangeStatus={onChangeStatus}
            loading={loading}
          />
        </div>
        <div className="content-dashboard-information">
          <DeadlineNotary
            data={deadlines}
            parcial={deadlineTotal}
            total={info ? info.closed + info.open : 0}
            loading={loadingDeadline}
          />
        </div>
      </Container>
    </div>
  );
};

export default Dashboard;
