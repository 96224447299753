import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const KEY_USER_LOCAL_STORAGE = "user";
const USER_ADMIN = process.env.REACT_APP_SUPER_ADMIN;

const login = (email, password) => {
    return axios.post(`${API_URL}/auth/login`, {
        email,
        password,
    })
    .then((response) => {
        if (response.data.success) {
            localStorage.setItem(KEY_USER_LOCAL_STORAGE, JSON.stringify(response.data));
            return response.data;
        } else {
            throw new Error(response.data.error_code);
        }

    });
};

const register = (first_name, last_name, username, email, password) => {
    return axios.post(`${API_URL}/users`, {
        first_name,
        last_name,
        username,
        email,
        password
    }).then((response) => {
        if (!response.data.success) {
            throw new Error(response.data.message);
        }
    });
};

const logout = () => {
    localStorage.removeItem(KEY_USER_LOCAL_STORAGE);
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem(KEY_USER_LOCAL_STORAGE));
};

const isRolAdmin = () => {
    const data = JSON.parse(localStorage.getItem(KEY_USER_LOCAL_STORAGE));    
    if (data.data && data.data.user.profile) {
        if (data.data.user.profile.name === USER_ADMIN) {
            return true;
        }
    }
    return false;
}

const getCurrentPermissions = (section) => {
    const data = JSON.parse(localStorage.getItem(KEY_USER_LOCAL_STORAGE));
    if (data.data && data.data.permissions.length > 0) {
        return data.data.permissions.find(permission => permission.section === section);
    }
    return null;
};

export default {
  register,
  login,
  logout,
  getCurrentUser,
  getCurrentPermissions,
  isRolAdmin
};
