import React, { useEffect, useState } from "react";
import StatusForm08Service from "../../../services/form08/statusForm08.service";
import { useConfirmationAlert, useToast } from "../../../hooks";
import { status08Badge } from "../../../utils/Badges";
import { constants, form_type, status08 } from "../../../variables/constants";
import List from "../../Table/List";
import ButtonIcon from "../../ButtonIcon";
import {
  headInquiryPurchase,
  headInquirySell,
} from "../../../views/StatusForm08/constants/constants_columns";
import ConfirmationAlert from "components/ConfirmationAlert/confirmationAlert";

const FormRelationship = (props) => {
  const {
    data: { vehicle_id, plate_number, opportunity_id },
    handleClose,
    refresh,
  } = props;

  const [forms, setForms] = useState([]);
  const addToast = useToast();
  const { confirmationModal, handleConfirmationModal } = useConfirmationAlert();

  useEffect(() => {
    const getForms = async () => {
      // Reusamos el buscador de formularios
      const params = {
        page: 0,
        limit: 100,
        filters: {
          plateNumber: {
            value: plate_number,
          },
          formType: {
            value: Object.entries(form_type)
              .filter(
                (f) =>
                  (!!vehicle_id && f[1].purchase) ||
                  (!!opportunity_id && f[1].sell)
              )
              .map((t) => t[1].value),
          },
          status: {
            value: Object.entries(status08)
              .filter((f) => f[1].inquiry)
              .map((t) => ({ text: t[1].text, value: t[1].value })),
          },
        },
        sortField: "id",
        sortType: "ASC",
        sortModel: null,
      };
      try {
        const response = await StatusForm08Service.getAll(params);
        if (response.status === 200) {
          setForms(
            response.data.response.map((e) => ({
              id: e.id,
              form_number: e.form_number,
              external_id: e.vehicle_id || e.opportunity_id,
              form_type: e.form_type,
              current_status: status08Badge(e.current_status),
              signed_date: e.signed_date,
              notary_office: e.notary_office,
              _vehicle_id: e.vehicle_id,
              _opportunity_id: e.opportunity_id,
            }))
          );
        }
      } catch (e) {
        console.error(e);
      }
    };
    getForms();
  }, [plate_number]);

  const actions = (props) => {
    return (!!vehicle_id && props._vehicle_id !== vehicle_id) ||
      (!!opportunity_id && props._opportunity_id !== opportunity_id) ? (
      <ButtonIcon
        onClick={() =>
          handleConfirmationModal(
            "",
            () =>
              submit({
                ...props,
                vehicle_id: vehicle_id || null,
                opportunity_id: opportunity_id || null,
              }),
            "¿Desea modificar la relación?",
            "Al confirmar, se eliminará la relación actual y se asociara la nueva."
          )
        }
        name="circle-check"
        title="Relacionar"
        color="info"
      />
    ) : null;
  };

  const submit = async (data) => {
    const response = await StatusForm08Service.changeRelationship(data);
    if (response.status === 200) {
      addToast({
        variant: "success",
        header: "Formulario 08 - Cambio relación",
        body: "Se actualizó la información correctamente",
      });
      handleClose();
      refresh();
    } else {
      addToast({
        variant: "danger",
        header: "Formulario 08 - Cambio relación",
        body: "Se ha producido un error durante la actualización, intente nuevamente.",
      });
    }
  };

  return (
    <div className="modalChecklist">
      <div className="container">
        <List
          thead={
            (!!vehicle_id && headInquiryPurchase) ||
            (!!opportunity_id && headInquirySell)
          }
          tbody={forms}
          actions={actions}
          next={false}
          totalItems={forms.length}
          currentPage={1}
          totalPage={1}
        />
      </div>
      {confirmationModal.open && (
        <ConfirmationAlert
          show={confirmationModal.open}
          handleAction={confirmationModal.handleAction}
          handleClose={confirmationModal.handleClose}
          message={confirmationModal.message}
          title={confirmationModal.title}
        />
      )}
    </div>
  );
};

export default FormRelationship;
