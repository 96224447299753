import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import Filters from "../../components/Filters";
import List from "../../components/Table/List.js";
import ButtonIcon from "../../components/ButtonIcon";
import ExcelButton from "../../components/ExcelButton/index.js";
import Add from "../../components/Modal/Additionals/add";
import Edit from "../../components/Modal/Additionals/edit";
import Reject from "../../components/Modal/Additionals/reject";

import {
  useFilters,
  useGrid,
  usePermission,
  useStatusModal,
} from "../../hooks/index";
import Modal from "../../layouts/ModalDisplay";
import additionalService from "../../services/additional/additional.service";
import notaryService from "../../services/notary.service";
import { badgeGenericBoolean, badgeGenericDiv } from "../../utils/Badges.js";
import { formatAmount } from "../../utils/Numbers.js";
import { head } from "./constants/columns";
import { filterFields } from "./constants/filters";
import { additional_status, constants } from "../../variables/constants";

const finalStatus = additional_status
  .filter((f) => f.final)
  .map((m) => m.value);

const AdditionalsList = () => {
  const { currentPermissions } = usePermission("ADICIONALES");
  const [profile, setProfile] = useState(null);

  const selectFilters = () => {
    const local_profile = JSON.parse(
      localStorage.getItem("user")
    ).data.user.profile.name.toLowerCase();
    if (local_profile === constants.notaryProfile) {
      filterFields.notaryOffice.hide = true;
      filterFields.receptionPlace.size = 3;
      filterFields.concept.size = 3;
    }
    return filterFields;
  };

  const {
    body,
    cleanPage,
    countItems,
    currentPage,
    currentSort,
    handleBody,
    handleSort,
    handleToPage,
    handleTotalItems,
    handleTotalPage,
    refresh,
    refreshGrid,
    totalItems,
    totalPage,
  } = useGrid();

  const { filters, onFilterChange } = useFilters(selectFilters(), cleanPage);
  const [emptyFilters, setEmptyFilters] = useState(true);

  const {
    handleCloseAddModal,
    handleCloseEditModal,
    handleCloseRejectModal,
    handleOpenAddModal,
    handleOpenEditModal,
    handleShowRejectModal,
    showAddModal,
    showEditModal,
    showRejectModal,
  } = useStatusModal();

  const [dataModal, setDataModal] = useState([]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user")) || null;
    const local_profile = user.data.user.profile.name.toLowerCase();
    if (local_profile === constants.notaryProfile) {
      notaryService
        .getAllUsers({
          notary_office_id: null,
          user_id: user.data.user.id,
        })
        .then((response) => {
          if (response.data.success && response.data.response.length > 0) {
            filters.notaryOffice.value =
              response.data.response[0].notary_office.id;
          }
          setProfile(local_profile);
        })
        .catch((error) => {
          console.error(error);
          setProfile(local_profile);
        });
    } else {
      setProfile(local_profile);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: countItems,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
      sortModel: currentSort?.model,
      filters: filters,
    };
    const emptyFilters = Object.keys(params.filters).every(
      (key) =>
        params.filters[key].value === "" ||
        params.filters[key].value === null ||
        params.filters[key].value?.length === 0
    );

    setEmptyFilters(emptyFilters);
    if (!emptyFilters) {
      additionalService.getAll(params).then((response) => {
        handleTotalItems(response.data.totalItems);
        handleTotalPage(Math.ceil(response.data.totalItems / countItems));
        handleBody(sortColumns(response.data.response));
      });
    } else {
      handleTotalItems(0);
      handleTotalPage(0);
      handleBody([]);
    }
    //eslint-disable-next-line
  }, [currentPage, currentSort, refresh, filters]);

  const sortColumns = (body) =>
    body.map((e) => ({
      id: e.id,
      vehicle_id: e.vehicle_id,
      opportunity_id: e.opportunity_id ?? "",
      plate_number: e.plate_number,
      status: badgeGenericDiv(
        additional_status[e.status_id].color,
        additional_status[e.status_id].text
      ),
      type: e.type,
      concept: e.concept,
      quantity: e.quantity,
      amount: e.amount ? formatAmount(e.amount) : "",
      amount_iva: e.amount_iva ? formatAmount(e.amount_iva) : "",
      total: formatAmount(
        parseInt(e.quantity || 0) *
          (parseFloat(e.amount || 0.0) + parseFloat(e.amount_iva || 0.0))
      ),
      reception_place: e.reception_place,
      notary_office: e.notary_office,
      request_date: e.request_date,
      received_date: e.received_date,
      _status_id: e.status_id,
    }));

  const handleShowModal = (id, e) => {
    e.preventDefault();
    additionalService.getById(id).then((response) => {
      if (response.data.success) {
        setDataModal(response.data);
        handleOpenEditModal();
      }
    });
  };

  const actions = (props) => {
    return (
      <>
        {currentPermissions && currentPermissions.permissions.hasSelect ? (
          <ButtonIcon
            onClick={(e) => handleShowModal(props.id, e)}
            name="file-alt"
            title="Editar"
          />
        ) : null}
        {currentPermissions &&
        currentPermissions.permissions.hasDelete &&
        profile !== constants.notaryProfile &&
        !finalStatus.includes(props._status_id) ? (
          <ButtonIcon
            onClick={() => handleShowRejectModal(props.id)}
            name="times"
            color="danger"
            size="lg"
            title="Anular"
          />
        ) : null}
      </>
    );
  };

  return (
    <div className="content">
      <div className={`content-filters form-group`}>
        {constants.notaryProfile !== profile &&
        currentPermissions &&
        currentPermissions.permissions.hasCreate ? (
          <Button
            className="btn-fill btn btn-primary capitalize"
            onClick={handleOpenAddModal}
          >
            Agregar Adicionales
          </Button>
        ) : null}
        <ExcelButton type="adicionales" filters={filters} />
      </div>
      <Filters filterFields={filters} onFilterChange={onFilterChange} />
      <List
        thead={head}
        tbody={body}
        actions={actions}
        // next={next}
        totalItems={totalItems}
        currentPage={currentPage}
        totalPage={totalPage}
        toPage={handleToPage}
        handleSort={handleSort}
        sortable
        currentSort={currentSort}
        emptyFilters={emptyFilters}
      />
      {showAddModal && (
        <Modal
          size="xl"
          content={
            <Add handleClose={handleCloseAddModal} refresh={refreshGrid} />
          }
          showModal={showAddModal}
          handleShow={handleOpenAddModal}
          handleClose={handleCloseAddModal}
        />
      )}
      {showEditModal && (
        <Modal
          content={
            <Edit
              data={dataModal}
              profile={profile}
              handleClose={handleCloseEditModal}
              refresh={refreshGrid}
            />
          }
          showModal={showEditModal}
          handleShow={handleOpenEditModal}
          handleClose={handleCloseEditModal}
        />
      )}
      {showRejectModal.show && (
        <Modal
          content={
            <Reject
              id={showRejectModal.id}
              handleClose={handleCloseRejectModal}
              refresh={refreshGrid}
            />
          }
          showModal={showRejectModal.show}
          handleShow={showRejectModal}
          handleClose={handleCloseRejectModal}
          headerTitle={<div>Anulación de un adicional</div>}
        />
      )}
    </div>
  );
};

export default AdditionalsList;
