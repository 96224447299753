import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getHistory = async (id, type) => {
  return await axios.get(`${API_URL}/audit/purchase_files/${id}/${type}`, { headers: authHeader() });
};

const getFormComments = async (id, type) => {
  return await axios.get(`${API_URL}/audit/purchase_file_forms/${id}/${type}`, { headers: authHeader() });
};

export default {
  getHistory,
  getFormComments,
};
