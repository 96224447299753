import ConfirmationAlert from "components/ConfirmationAlert/confirmationAlert";
import { useEffect, useRef, useState } from "react";
import { Button, ModalFooter } from "react-bootstrap";
import Form from "react-validation/build/form";
import vehicleService from "services/vehicle.service";
import { plateNumberBadge, statusBadge } from "utils/Badges";
import { InputField } from "../../../components/Form/index";
import tradeIds from "../../../services/settings/tradeIds.service";
import { useConfirmationAlert, useToast } from "../.././../hooks/index";
import React from "react";
import { useDebounce } from "use-debounce";

const initialFormState = {
  first_id: "",
  second_id: "",
  first_id_found: false,
  second_id_available: false,
};

const TradeIds = (props) => {
  const [fields, setFields] = useState(initialFormState);
  const {
    confirmationModal,
    handleConfirmationModal,
    handleCloseConfirmationModal,
  } = useConfirmationAlert();
  const firstVehicleIdInputRef = useRef();
  const newVehicleIdInput = useRef();

  const secondVehicleIdInputRef = useRef();
  const currentVehicleIdInput = useRef();

  const [value_first_id] = useDebounce(fields.first_id, 600);
  const [value_second_id] = useDebounce(fields.second_id, 600);

  const addToast = useToast();

  useEffect(() => {
    if (value_first_id) {
      firstVehicleIdInputRef.current.hideError(currentVehicleIdInput.current);
      vehicleService
        .getVehicleById(value_first_id)
        .then((response) => {
          if (response.data.success) {
            onSimpleFieldChange("first_id_found", true);
            firstVehicleIdInputRef.current.showError(
              currentVehicleIdInput.current,
              <div>
                <div className="alert alert-success" role="alert">
                  ID Existente
                </div>
                <div>
                  {plateNumberBadge(response.data.data.plate_number)}
                  {statusBadge(response.data.data.purchase_file_status)}
                </div>
              </div>
            );
          }
        })
        .catch((error) => {
          onSimpleFieldChange("first_id_found", false);
          firstVehicleIdInputRef.current.showError(
            currentVehicleIdInput.current,
            <div className="alert alert-danger" role="alert">
              El ID no existe
            </div>
          );
        });
    }
    if (value_first_id.length === 0) {
      firstVehicleIdInputRef.current.hideError(currentVehicleIdInput.current);
    }

    //eslint-disable-next-line
  }, [value_first_id]);

  useEffect(() => {
    if (value_second_id?.length === 0) {
      secondVehicleIdInputRef.current.hideError(newVehicleIdInput.current);
      return;
    }
    if (value_second_id) {
      if (value_second_id?.length !== 6) {
        onSimpleFieldChange("second_id_available", false);
        secondVehicleIdInputRef.current.showError(
          newVehicleIdInput.current,
          <div>
            <div className="alert alert-warning" role="alert">
              Debe ingresar 6 dígitos
            </div>
          </div>
        );
        return;
      }

      secondVehicleIdInputRef.current.hideError(newVehicleIdInput.current);
      vehicleService
        .getVehicleById(value_second_id)
        .then((response) => {
          if (response.data.success) {
            onSimpleFieldChange("second_id_available", false);
            secondVehicleIdInputRef.current.showError(
              newVehicleIdInput.current,
              <div>
                <div className="alert alert-danger" role="alert">
                  ID no disponible.
                </div>
                <div>
                  {plateNumberBadge(response.data.data.plate_number)}
                  {statusBadge(response.data.data.purchase_file_status)}
                </div>
              </div>
            );
          }
        })
        .catch((error) => {
          onSimpleFieldChange("second_id_available", true);
          secondVehicleIdInputRef.current.showError(
            newVehicleIdInput.current,
            <div className="alert alert-success" role="alert">
              ID disponible.
            </div>
          );
        });
    }
    //eslint-disable-next-line
  }, [value_second_id]);

  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const handleSubmit = async () => {
    try {
      const data = {
        ...fields,
      };

      const response = await tradeIds.updateId(data);
      if (response.status === 200) {
        handleCloseConfirmationModal();
        addToast({
          variant: "success",
          header: "Configuración",
          body: "Se han actualizado los IDs correctamente",
        });
        setFields(initialFormState);
        firstVehicleIdInputRef.current.hideError(currentVehicleIdInput.current);
        secondVehicleIdInputRef.current.hideError(newVehicleIdInput.current);
      }
    } catch (error) {
      addToast({
        variant: "danger",
        header: "Configuración",
        body: "Ha ocurrido un error",
      });
    }
  };

  const handlePreConfirmation = (new_id) => {
    handleConfirmationModal(
      new_id,
      handleSubmit,
      "¿Desea actualizar el ID?",
      "Al confirmar, el ID cambiará al"
    );
  };

  return (
    <div>
      <Form ref={firstVehicleIdInputRef}>
        <InputField
          label={"ID Actual"}
          name={"first_id"}
          onChange={(e) => onSimpleFieldChange("first_id", e.target.value)}
          value={fields.first_id}
          type="number"
          refs={currentVehicleIdInput}
        />
      </Form>
      <Form ref={secondVehicleIdInputRef}>
        <InputField
          label={"ID Nuevo"}
          name={"second_id"}
          onChange={(e) => onSimpleFieldChange("second_id", e.target.value)}
          value={fields.second_id}
          type="number"
          refs={newVehicleIdInput}
        />
      </Form>
      <div className="p-2">
        <ModalFooter>
          <Button
            className="btn-fill pull-right btn btn-info btn-sm capitalize"
            disabled={!(fields.first_id_found && fields.second_id_available)}
            onClick={() => handlePreConfirmation(fields.second_id)}
          >
            Actualizar
          </Button>
        </ModalFooter>
      </div>
      {confirmationModal.open && (
        <ConfirmationAlert
          show={confirmationModal.open}
          handleAction={confirmationModal.handleAction}
          handleClose={confirmationModal.handleClose}
          message={confirmationModal.message}
          title={confirmationModal.title}
        />
      )}
    </div>
  );
};

export default TradeIds;
