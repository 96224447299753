import { constants } from "variables/constants";

export const head = [
  { text: "ID", field: "id", model: null },
  { text: "Stock ID", field: "vehicle_id", model: null },
  { text: "Opp ID", field: "opportunity_id", model: null },
  { text: constants.domain, field: "plate_number", model: null },
  { text: "Estado", field: "status_id", model: null },
  { text: "Tipo", field: "type_id", model: null },
  { text: "Concepto", field: "concept_id", model: null },
  { text: "Cantidad", field: "quantity", model: null },
  { text: "Monto No Gravado Unitario", field: "amount", model: null },
  { text: "Monto Gravado Unitario", field: "amount_iva", model: null },
  { text: "Total", field: null, model: null },
  { text: "Lugar de Toma", field: "reception_place_id", model: null },
  { text: "Lugar de Firma", field: "notary_office_id", model: null },
  { text: "Fecha Solicitud", field: "request_date", model: null },
  { text: "Fecha Recibido", field: "updatedAt", model: "additional_received" },
  { text: "", field: null, model: null },
];
