import axios from "axios";
import authHeader from "../auth-header";
const API_URL = process.env.REACT_APP_API_URL;

const getAll = async (data) => {
  const url = `${API_URL}/vtv/list`;
  return await axios.post(url, { ...data }, { headers: authHeader() });
};

const getById = async (id) => {
  return await axios.get(`${API_URL}/vtv/${id}`, {
    headers: authHeader(),
  });
};

const update = async (data) => {
  return await axios.put(
    `${API_URL}/vtv`,
    { ...data },
    {
      headers: authHeader(),
    }
  );
};

const toggleHidden = async (data) => {
  return await axios.put(
    `${API_URL}/vtv/toggle-hidden`,
    { ...data },
    {
      headers: authHeader(),
    }
  );
};

const getCancelReasons = async () => {
  return await axios.get(`${API_URL}/vtv-schedule-cancel-reason/list`, {
    headers: authHeader(),
  });
};

const getCommentReasons = async () => {
  return await axios.get(`${API_URL}/vtv-schedule-comment-reason/list`, {
    headers: authHeader(),
  });
};

const download = async (props) => {
  const url = `${API_URL}/vtv-download?limit=${props.countItems}&page=${props.currentPage}&schedule_id=${props.schedule_id}`;
  return await axios.get(url, { headers: authHeader() });
};

const save = (data) => {
  return axios
    .post(`${API_URL}/vtv-image`, data, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const vtvReceptionPlaceGetAll = async () => {
  return await axios.get(`${API_URL}/vtv-reception-place`, {
    headers: authHeader(),
  });
};

const auditVtvSchedule = async (id) => {
  return await axios.get(`${API_URL}/audit-vtv/${id}`, {
    headers: authHeader(),
  });
};

export default {
  getAll,
  getById,
  update,
  toggleHidden,
  getCancelReasons,
  getCommentReasons,
  download,
  save,
  vtvReceptionPlaceGetAll,
  auditVtvSchedule,
};
