import { constants, yesNoOptions } from "../../../variables/constants";

export const filterFields = {
  status: {
    type: "select",
    name: "status",
    options: yesNoOptions,
    placeholder: "Estado",
    value: constants.yesValue,
    size: 3,
  },
};
