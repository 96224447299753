import React from "react";

const Pagination = (props) => {

  const LEFT_PAGE = 'LEFT';
  const RIGHT_PAGE = 'RIGHT';
  const pageNeighbours = 2;
  /**
   * totalNumbers: the total page numbers to show on the control
   * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
   */
  const totalNumbers = (pageNeighbours * 2) + 3;
  const totalBlocks = totalNumbers + 2;
  const { totalPage, currentPage, toPage } = props;

  const range = (from, to, step = 1) => {
    let i = from;
    const range = [];
    while (i <= to) {
      range.push(i);
      i += step;
    }
    return range;
  }

  const fetchPageNumbers = () => {
    if (totalPage > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPage - 1, currentPage + pageNeighbours);
      let pages = range(startPage, endPage);
      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = (totalPage - endPage) > 1;
      const spillOffset = totalNumbers - (pages.length + 1);
      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case (hasLeftSpill && !hasRightSpill): {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }
        // handle: (1) {2 3} [4] {5 6} > (10)
        case (!hasLeftSpill && hasRightSpill): {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }
        // handle: (1) < {4 5} [6] {7 8} > (10)
        case (hasLeftSpill && hasRightSpill):
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }
      return [1, ...pages, totalPage];
    }
    return range(1, totalPage);
  }

  const pages = fetchPageNumbers();

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination pagination-sm mt-3">
        {pages.map((page, index) => {

          if (page === LEFT_PAGE) return (
            <li key={index} className="page-item">
              <a className="page-link" href="!#" aria-label="Anterior" onClick={(e) => toPage((currentPage - (pageNeighbours * 2) - 1), e)}>
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Anterior</span>
              </a>
            </li>
          );

          if (page === RIGHT_PAGE) return (
            <li key={index} className="page-item">
              <a className="page-link" href="!#" aria-label="Siguiente" onClick={(e) => toPage((currentPage + (pageNeighbours * 2) + 1), e)}>
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Siguiente</span>
              </a>
            </li>
          );

          return (
            <li key={index} className={`page-item${currentPage === page - 1 ? ' active' : ''}`}>
              <a className="page-link" href="!#" onClick={(e) => toPage((page - 1), e)}>{page}</a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Pagination;
