import axios from "axios";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_SELLS_URL;

const getAll = async (params) => {
  return await axios.post(`${API_URL}/sell-financed/list`, params, {
    headers: authHeader(),
  });
};

const getById = async (id) => {
  return await axios.get(`${API_URL}/sell-financed/${id}`, {
    headers: authHeader(),
  });
};

const create = async (data) => {
  return await axios.put(`${API_URL}/sell-financed`, data, {
    headers: authHeader(),
  });
};

const update = async (id, data) => {
  return await axios.put(`${API_URL}/sell-financed/${id}`, data, {
    headers: authHeader(),
  });
};

const getAllReasons = async () => {
  return await axios.get(`${API_URL}/sell-financed-comment-reason`, {
    headers: authHeader(),
  });
};

const getCancelReasons = async () => {
  return await axios.get(`${API_URL}/sell-financed-cancel-reason`, {
    headers: authHeader(),
  });
};

export default {
  getAll,
  getById,
  create,
  update,
  getAllReasons,
  getCancelReasons,
};
