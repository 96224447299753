import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getStatsGlobal = async (params) => {
  return await axios.post(`${API_URL}/agent/stats/global`, params, {
    headers: authHeader(),
  });
};

const getObjetive = async (params) => {
  return await axios.post(`${API_URL}/agent/stats/objetive`, params, {
    headers: authHeader(),
  });
};

export default {
  getStatsGlobal,
  getObjetive,
};
