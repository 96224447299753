import React, { useEffect, useState } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { Button } from "react-bootstrap";
import NotaryService from "../../../services/notary.service";
import UserService from "../../../services/user.service";
import { constants } from "../../../variables/constants";

const Content = (props) => {
  const {
    data: { id },
    handleClose,
  } = props;

  const [notaryOffice, setNotaryOffice] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const onSelectUser = async (selectedList, selectedItem) => {
    const payload = {
      notary_office_id: id,
      user_id: selectedItem.id,
    };
    try {
      await NotaryService.addUser(payload);
    } catch (err) {
      console.log(err);
    }
  };

  const onRemoveUser = async (selectedList, removedItem) => {
    try {
      await NotaryService.delUser(id, removedItem.id);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    //datos de la escribania
    NotaryService.getById(id).then((notary_office) => {
      if (notary_office.data.success) {
        setNotaryOffice(notary_office.data.data);
      }
    });
    //usuarios relacionados
    NotaryService.getAllUsers({ notary_office_id: id, user_id: null }).then(
      (notary_users) => {
        if (notary_users.data.success) {
          setSelectedUser(notary_users.data.response.map((u) => u.user));
        }
      }
    );
    //todos los usuarios con el perfil de escribano (filtrados por los ya relacionados)
    const params = { countItems: 1000, currentPage: 0, profile: "notary" };
    UserService.getAllByProfile(params).then((filterUsers) => {
      if (filterUsers.data.success) {
        setUsers(filterUsers.data.response);
      }
    });
  }, [id]);

  return (
    <div className="modalChecklist">
      <div className="container">
        <div className="row">
          <div className="col">
            <label className="capitalize">
              <strong>{constants.notaryOffice}</strong>
            </label>
            <div>
              <span>{notaryOffice?.name ?? ""}</span>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="row">
          <div className="col">
            <label className="capitalize">
              <strong>Usuarios</strong>
            </label>
            <Multiselect
              options={users}
              selectedValues={selectedUser}
              onSelect={onSelectUser}
              onRemove={onRemoveUser}
              displayValue="name"
              placeholder="Usuarios"
              closeIcon="cancel"
              showCheckbox={false}
              singleSelect={false}
            />
          </div>
        </div>
      </div>
      <br />

      <div className="modal-footer">
        <div className="row">
          <Button
            className="btn-fill pull-right btn btn-primary btn-sm"
            onClick={() => {
              handleClose();
            }}
          >
            {constants.close}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Content;
