import React, { useEffect, useState } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import { Button } from "react-bootstrap";
import NotaryService from "../../../services/notary.service";
import StatusForm08Service from "../../../services/form08/statusForm08.service";
import { constants } from "../../../variables/constants";
import { useToast } from "../../../hooks";

const Assigment = (props) => {
  const { data, handleClose, setRefresh } = props;

  const initialFormState = {
    firstNumber: data + 1,
    lastNumber: data + 10,
    notary_office_id: null,
    form_code: "08",
  };

  const [fields, setFields] = useState(initialFormState);
  const [, setAllowSubmit] = useState(false);
  const [submitAction, setSubmitAction] = useState(false);
  const [notaryOffices, setNotaryOffices] = useState([]);
  const addToast = useToast();

  // Al presionar un botón
  useEffect(() => {
    const simplesFields =
      Object.entries(fields).filter((f) => f[1] === null || f[1] === "")
        .length === 0;
    const diff = fields.lastNumber - fields.firstNumber + 1 > 0;
    setAllowSubmit(simplesFields && diff);

    if (submitAction) {
      setSubmitAction(false);
      StatusForm08Service.addBatch(fields)
        .then((response) => {
          if (response.success) {
            addToast({
              variant: "success",
              header: "Formularios 08 - Creación",
              body: "Se asignaron los formularios correctamente",
            });
          } else {
            addToast({
              variant: "danger",
              header: "Formularios 08 - Creación",
              body: "No se pudieron asignar los formularios correctamente",
            });
          }
          setRefresh(Date.now());
          handleClose();
        })
        .catch((err) => {
          addToast({
            variant: "danger",
            header: "Formularios 08 - Creación",
            body: "No se pudieron asignar los formularios correctamente",
          });
        });
    }
  }, [submitAction, fields, handleClose, setRefresh]);

  useEffect(() => {
    NotaryService.getAll({ sortField: "name", sortType: "ASC" }).then(
      (response) => {
        if (response.data.count > 0) {
          setNotaryOffices(response.data.response);
        }
      }
    );
  }, []);

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <div className="row">
            <div className="col">
              <label className="capitalize">
                <strong>{constants.notaryOffice}</strong>
              </label>
              <div>
                <Select
                  className="form-control"
                  name="notary_office_id"
                  onChange={(e) =>
                    onSimpleFieldChange(
                      "notary_office_id",
                      parseInt(e.target.value, 10)
                    )
                  }
                >
                  <option value="null">Seleccione</option>
                  {notaryOffices.map((prop, key) => {
                    return (
                      <option key={key} value={prop.id}>
                        {prop.name}
                      </option>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div className="col">
              <label className="capitalize">
                <strong>Cantidad</strong>
              </label>
              <div>
                <span>{fields.lastNumber - fields.firstNumber + 1}</span>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="row">
            <div className="col">
              <label className="capitalize">
                <strong>Número inicial</strong>
              </label>
              <div>
                <span>
                  <Input
                    type="number"
                    className="form-control"
                    name="firstNumber"
                    placeholder="Número inicial"
                    autoComplete="false"
                    value={fields.firstNumber}
                    onChange={(e) =>
                      onSimpleFieldChange("firstNumber", e.target.value)
                    }
                  />
                </span>
              </div>
            </div>
            <div className="col">
              <label className="capitalize">
                <strong>Número final</strong>
              </label>
              <div>
                <span>
                  <Input
                    type="number"
                    className="form-control"
                    name="lastNumber"
                    placeholder="Número final"
                    autoComplete="false"
                    value={fields.lastNumber}
                    onChange={(e) =>
                      onSimpleFieldChange("lastNumber", e.target.value)
                    }
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </Form>
      <br />

      <div className="modal-footer">
        <div>
          <Button
            className="btn-fill pull-right btn btn-primary btn-sm"
            onClick={() => {
              handleClose();
            }}
          >
            {constants.close}
          </Button>
        </div>
        <div className="row">
          <Button
            className="btn-fill pull-right btn btn-warning btn-sm"
            onClick={(e) => {
              setSubmitAction(true);
            }}
            disabled={
              fields.lastNumber - fields.firstNumber + 1 >
                constants.limitAssigment08 ||
              fields.lastNumber - fields.firstNumber + 1 < 1
            }
          >
            {constants.accept}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Assigment;
