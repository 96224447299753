import React, { Component } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { login } from "variables/constants";


class AdminNavbarLinks extends Component {
  render() {
    const { logged } = this.props;
    const user = JSON.parse(localStorage.getItem('user')) || null;
    const name = (user && user.data && user.data.user) 
    ? `${user.data.user.first_name} ${user.data.user.last_name}`
    : null;
    const userId = (user && user.data && user.data.user) 
    ? `${user.data.user.id}`
    : null;
    return (
      <div style={{display: "flex", justifyContent: "flex-end"}}>                        
        <Nav >         
          {!logged && (
            <NavDropdown.Item href={login}>
              Login
            </NavDropdown.Item>
          )}
          {logged && (
            <div style={{display: "flex", marginRight: 20}}>              
              <NavDropdown.Item href={`/admin/users/${userId}/edit`}>                
                {name}
              </NavDropdown.Item>
              <NavDropdown.Item style={{marginLeft: 50}} href={login} onClick={() => localStorage.clear()}>
                Logout
              </NavDropdown.Item>
            </div>
          )}
        </Nav>
      </div>
    );
  }
}

export default AdminNavbarLinks;
