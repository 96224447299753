export const purchaseComment = (comments) =>
  comments.map((c) => ({
    auditTimestamp: c.comment_date,
    status: c.purchase_file_status,
    reason: c.reason,
    comment: c.comment,
    first_name: c.updatedUser,
  }));

export default {};
