import image from "assets/img/sidebar-3.jpg";
import Footer from "components/Footer/Footer";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";
import { Component } from "react";
import routes from "routes.js";
import { style } from "variables/Variables.jsx";
import AuthService from "../services/auth.service";
import { RoutesMapped } from "./wrappers";
import React from "react";
class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLogged: {
        email: "",
        name: "",
        profile: "",
        isRolAdmin: false,
        active: false,
      },
      image: image,
      color: "black",
      hasImage: false,
      fixedClasses: "dropdown show-dropdown open",
    };
  }

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "KAVAK";
  };

  componentDidMount() {
    this.checkLoginStatus();
  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainPanel.scrollTop = 0;
  }

  checkLoginStatus = () => {
    const userLogin = AuthService.getCurrentUser();

    if (userLogin === null || userLogin === undefined) {
      this.props.navigate("/auth/login");
    } else {
      this.setState({
        userLogged: {
          email: userLogin.data.user.email,
          name: `${userLogin.data.user.first_name} ${userLogin.data.user.last_name}`,
          profile: userLogin.data.user.profile.name,
          isRolAdmin:
            process.env.REACT_APP_SUPER_ADMIN ===
            userLogin.data.user.profile.name,
          active: true,
        },
      });
    }
  };

  render() {
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={routes}
          image={this.state.image}
          color={this.state.color}
          hasImage={this.state.hasImage}
        />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <RoutesMapped
            userLogged={this.state.userLogged}
          />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Admin;
