import React, { useState, useRef, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Container, Row, Col, Button } from "react-bootstrap";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import { Card } from "components/Card/Card.jsx";
import PasswordStrengthMeter from "components/PasswordStrengthMeter/PasswordStrengthMeter";
import Dropdown from "components/Table/Dropdown";
import UserService from "../../services/user.service";
import ProfileService from "../../services/profile.service";
import { passwordTracker } from "utils/User";
import { useToast } from "../../hooks";
import { updateLocalStorageCache, profiles } from "utils/Service";
import { constants } from "variables/constants";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        El campo es obligatorio
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!validator.isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        El correo electronico es incorrecto
      </div>
    );
  }
};

const UsersCreate = (props) => {
  const navigate = useNavigate();
  const [profile_id, setProfileId] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [user_name, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const form = useRef();
  const checkBtn = useRef();
  const addToast = useToast();

  const passwordStrength = Object.values(passwordTracker(password)).filter(
    (value) => value
  ).length;

  const onChangeUserName = (e) => {
    const userName = e.target.value;
    setUserName(userName);
  };

  const onChangeLastName = (e) => {
    const lastName = e.target.value;
    setLastName(lastName);
  };

  const onChangeFirstName = (e) => {
    const firstName = e.target.value;
    setFirstName(firstName);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      UserService.create(
        profile_id,
        first_name,
        last_name,
        user_name,
        email,
        password
      ).then(
        () => {
          addToast({
            variant: "success",
            header: "Usuarios",
            body: "Se ha creado el nuevo usuario correctamente",
          });
          updateLocalStorageCache().then(() => {
            navigate("/admin/users/list");
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          addToast({
            variant: "danger",
            header: "Usuarios",
            body: resMessage,
          });
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <Container fluid>
        <Button href="/admin/users/list" className="btn-fill btn btn-info">
          Volver
        </Button>
        <br />
        <br />
        <Row>
          <Col md={12}>
            <Card
              content={
                <Form onSubmit={handSubmit} ref={form}>
                  <div className="form-group">
                    <label htmlFor="email">Tipo de usuario</label>
                    <Dropdown
                      className="form-control"
                      name="user_type"
                      placeholder="Seleccionar..."
                      onSelect={(e) =>
                        setProfileId(
                          e.target.value === constants.dropdownDefault
                            ? null
                            : e.target.value
                        )
                      }
                      options={profiles()}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Nombre</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="first_name"
                      placeholder="Nombre"
                      onChange={onChangeFirstName}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Apellido</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="last_name"
                      placeholder="Apellido"
                      onChange={onChangeLastName}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="email"
                      placeholder="email"
                      onChange={onChangeEmail}
                      validations={[required, validEmail]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Nombre de usuario</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="user_name"
                      placeholder="Usuario"
                      onChange={onChangeUserName}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Contraseña</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Contraseña"
                      onChange={onChangePassword}
                      validations={[required]}
                    />
                  </div>
                  <PasswordStrengthMeter
                    password={password}
                    passwordStrength={passwordStrength}
                  />

                  <div className="form-group mt-3">
                    <button
                      className="btn btn-primary btn-fill pull-right"
                      disabled={loading || passwordStrength < 5}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Crear Usuario</span>
                    </button>
                  </div>

                  <CheckButton style={{ display: "none" }} ref={checkBtn} />

                  {/* <Button bsStyle="info" pullRight fill type="submit">
                      Crear Usuario
                    </Button> */}
                  <div className="clearfix" />
                </Form>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UsersCreate;
