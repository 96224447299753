import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import moment from "moment";
import Filters from "../../components/Filters/index.js";
import List from "../../components/Table/List.js";
import RejectSell from "components/Modal/Sells/rejectSell.js";
import Edit from "../../components/Modal/Sells/general/edit";
import ExcelButton from "../../components/ExcelButton/index.js";
import ButtonIcon from "../../components/ButtonIcon/index.js";
import ConfirmationAlert from "../../components/ConfirmationAlert/confirmationAlert.js";
import {
  useConfirmationAlert,
  useFilters,
  useGrid,
  useStatusModal,
  useToast,
} from "../../hooks/index";
import AuthService from "../../services/auth.service";
import SellsGeneralService from "../../services/sells/sellsGeneral.service";
import SellsWithdrawDocumentation from "../../services/sells/sellsWithdrawDocumentation.service.js";
import {
  badgeGenericBoolean,
  diffDateBadge,
  genericBadge,
  sinceDaysBadge,
  twoBadges,
} from "../../utils/Badges.js";
import { categories, sellEmployees } from "../../utils/Service.js";
import {
  constants,
  meses,
  sells_status,
  yesNoOptions,
  sells_status_select,
} from "../../variables/constants";
import {
  FINANCED_STATUS_NAME,
  providerOptions,
} from "../../variables/sells/index";
import Modal from "../../layouts/ModalDisplay";
import styles from "../General/styles.module.scss";
import { headGeneral } from "./constants/constants_columns";

const years = [];
for (let index = moment().year(); index >= 2021; index--) {
  years.push({ value: index, text: index });
}

const months = [];
for (let index = 1; index <= 12; index++) {
  months.push({ value: index, text: meses[index] });
}

const filterFields = {
  plate_number: {
    type: "text",
    name: "plate_number",
    placeholder: "Dominio",
    value: "",
    size: 3,
  },
  year: {
    type: "select",
    name: "year",
    options: years,
    placeholder: "Año",
    value: "",
    size: 2,
  },
  month: {
    type: "select",
    name: "month",
    options: months,
    placeholder: "Mes",
    value: "",
    size: 2,
  },
  suspended: {
    type: "select",
    name: "suspended",
    options: yesNoOptions,
    placeholder: "Suspendido",
    value: "",
    size: 2,
  },
  person_in_charge: {
    type: "select",
    name: "person_in_charge",
    options: sellEmployees(),
    placeholder: "Persona Responsable",
    value: "",
    size: 3,
  },
  status_ns: {
    type: "select",
    name: "status_ns",
    options: categories(),
    placeholder: "Estado NS",
    value: undefined,
    size: 3,
  },
  provider: {
    type: "select",
    name: "provider",
    options: providerOptions,
    placeholder: "Proveedor",
    value: "",
    size: 3,
  },
  status: {
    type: "multiSelect",
    name: "status",
    options: sells_status_select,
    placeholder: "Estado",
    value: [],
    size: 6,
  },
};

const finalStatus = [sells_status.COMPLETED.value, sells_status.REJECTED.value];

let local_profile = "";
const userData = localStorage.getItem("user");
if (userData) {
  try {
    local_profile = JSON.parse(userData).data.user.profile.name.toLowerCase();
  } catch (error) {
    console.error("Failed to parse user data from localStorage:", error);
    // Handle the error accordingly, maybe set a default profile or redirect
  }
} else {
  // Handle the absence of user data, maybe set a default profile or redirect
}

const allowProfilesHubAction = [
  constants.onboardProfile,
  constants.adminProfile,
].includes(local_profile);

const GeneralList = (props) => {
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const [dataModal, setDataModal] = useState([]);
  const addToast = useToast();

  const {
    body,
    countItems,
    currentPage,
    currentSort,
    handleBody,
    handleSort,
    handleToPage,
    handleTotalItems,
    handleTotalPage,
    refresh,
    refreshGrid,
    totalItems,
    totalPage,
    cleanPage,
  } = useGrid({
    field: "opportunity_id",
    type: "ASC",
  });

  const {
    handleCloseEditModal,
    handleCloseRejectModal,
    handleOpenEditModal,
    handleShowRejectModal,
    showEditModal,
    showRejectModal,
  } = useStatusModal();

  const {
    confirmationModal,
    handleConfirmationModal,
    handleCloseConfirmationModal,
  } = useConfirmationAlert();

  const selectFilters = () => {
    if (local_profile === constants.onboardProfile) {
      filterFields.suspended.hide = true;
      filterFields.status_ns.hide = true;
      filterFields.plate_number.size = 2;
      filterFields.year.size = 1;
      filterFields.month.size = 1;
      filterFields.person_in_charge.size = 2;
      filterFields.provider.size = 2;
      filterFields.status.size = 4;
      // filterFields.status.hide = true;
      // filterFields.status.value = sells_status_select.filter(f => f.onboard);
      filterFields.status.options = sells_status_select.filter(
        (f) => f.onboard
      );
    }
    return filterFields;
  };

  const { filters, onFilterChange } = useFilters(selectFilters(), cleanPage);
  const [emptyFilters, setEmptyFilters] = useState(true);

  const next = {
    link: "/admin/rejected/list",
    text: "Rechazados",
  };
  // PAGINATE:FIN

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions("GENERAL VENTAS");
    setCurrentPermissions(permissions);
  }, []);

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: countItems,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
      sortModel: currentSort?.model,
      filters,
    };
    const emptyFilters = Object.keys(params.filters).every(
      (key) =>
        params.filters[key].value === "" ||
        params.filters[key].value === undefined ||
        params.filters[key].value.length === 0
    );
    setEmptyFilters(emptyFilters);
    if (emptyFilters) {
      handleTotalItems(0);
      handleTotalPage(0);
      handleBody(sortColumns([]));
      return;
    }

    SellsGeneralService.getAll(params)
      .then((response) => {
        handleTotalItems(response.data.totalItems);
        handleTotalPage(Math.ceil(response.data.totalItems / countItems));
        handleBody(sortColumns(response.data.response));
      })
      .catch((error) => {
        console.error("Error fetching sell details:", error);
        handleTotalItems(0);
        handleTotalPage(0);
        handleBody(sortColumns([]));
        addToast({
          variant: "danger",
          header: "Ventas",
          body: "Error al obtener los detalles de la venta. Por favor, intente nuevamente.",
        });
      });
    //eslint-disable-next-line
  }, [currentPage, currentSort, filters, refresh]);

  const sortColumns = (body) =>
    body.map((e) => ({
      opportunity_id: e.opportunity_id,
      plate_number: twoBadges(
        e.plate_number,
        e.financed ? FINANCED_STATUS_NAME.FINANCED : null
      ),
      stock_id: e.stock_id,
      status_ns: e.status_ns,
      financing_provider: e.financing_provider,
      year: e.year,
      month: e.month,
      week: e.week,
      delivery_date: e.delivery_date,
      signing_form_08_date: e.signing_form_08_date,
      receive_form_08_date: e.receive_form_08_date,
      financed_date: e.financed_date,
      folio_in_progress_date: e.folio_in_progress_date,
      presentation_date: e.presentation_date,
      presentation_real_date: e.presentation_real_date,
      presentation_retired_real_date: e.presentation_retired_real_date,
      reception_cat_date: e.reception_cat_date,
      take_licence_date: e.take_licence_date,
      hub_reception_date: e.hub_reception_date,
      status: genericBadge(sells_status, e.status),
      estimated_delivery_date: e.estimated_delivery_date,
      estimated_end_trial: e.estimated_end_trial,
      person_in_charge_id: e.person_in_charge_id,
      suspended_date: e.suspended_date,
      observed_date: e.observed_date,
      active_days: diffDateBadge(e.active_days),
      aging: diffDateBadge(e.aging),
      diff_days: diffDateBadge(e.diff_days),
      // since_days: sinceDaysBadge(e.since_days),
      bacs_id: badgeGenericBoolean(e.bacs_id),
      bacs_desafectado: badgeGenericBoolean(e.bacs_desafectado),
      imagenes: e.imagenes,
      _status_id: e.status_id,
      _sells_withdraw_documentation_id: e.sells_withdraw_documentation_id,
    }));

  const handleShowModal = (id, e) => {
    e.preventDefault();
    SellsGeneralService.getById(id)
      .then((response) => {
        if (response.data.success) {
          setDataModal(response.data);
          handleOpenEditModal();
        }
      })
      .catch((error) => {
        console.error("Error fetching sell details:", error);
        addToast({
          variant: "danger",
          header: "Ventas",
          body: "Error al obtener los detalles de la venta. Por favor, intente nuevamente.",
        });
      });
  };

  const markHubReception = (id) => {
    SellsWithdrawDocumentation.update(id, {
      hub_reception_date: moment(),
    })
      .then((response) => {
        if (response.data.success) {
          addToast({
            variant: "success",
            header: "Ventas",
            body: "Se ha actualizado el dato exitosamente.",
          });
        } else {
          addToast({
            variant: "danger",
            header: "Ventas",
            body: "No se ha podido guardar el dato, por favor intente nuevamente.",
          });
        }
        refreshGrid();
        handleCloseConfirmationModal();
      })
      .catch((error) => {
        console.error("Error updating hub reception date:", error);
        addToast({
          variant: "danger",
          header: "Ventas",
          body: "Error al actualizar la fecha de recepción del HUB. Por favor, intente nuevamente.",
        });
        handleCloseConfirmationModal();
      });
  };

  const actions = (props) => {
    return (
      <>
        {currentPermissions?.permissions?.hasSelect ? (
          <ButtonIcon
            onClick={(e) => handleShowModal(props.opportunity_id, e)}
            name="file-alt"
            title="Editar"
          />
        ) : null}
        {currentPermissions?.permissions?.hasUpdate &&
        props._status_id ===
          sells_status.PENDING_DOCUMENTATION_DELIVERED.value &&
        !props.hub_reception_date &&
        allowProfilesHubAction ? (
          <ButtonIcon
            onClick={() =>
              handleConfirmationModal(
                "",
                () => markHubReception(props._sells_withdraw_documentation_id),
                "Recepción en el HUB",
                "Al confirmar, se guardará la fecha actual. ¿Esta seguro?"
              )
            }
            name="building-circle-check"
            title="HUB Recibido"
            color="info"
          />
        ) : null}
        {currentPermissions?.permissions?.hasDelete &&
        !finalStatus.includes(props._status_id) ? (
          <ButtonIcon
            onClick={() => handleShowRejectModal(props.opportunity_id)}
            name="times"
            color="danger"
            size="lg"
            title="Rechazar"
          />
        ) : null}
      </>
    );
  };

  return (
    <div className="content">
      <div className={styles.export}>
        <ExcelButton type="ventasGeneral" filters={filters} />
      </div>
      <Filters filterFields={filterFields} onFilterChange={onFilterChange} />
      <List
        thead={headGeneral}
        tbody={body}
        actions={actions}
        next={next}
        totalItems={totalItems}
        currentPage={currentPage}
        totalPage={totalPage}
        toPage={handleToPage}
        handleSort={handleSort}
        sortable
        currentSort={currentSort}
        emptyFilters={emptyFilters}
      />
      <Modal
        content={
          <Edit
            data={dataModal}
            handleClose={handleCloseEditModal}
            refresh={refreshGrid}
          />
        }
        showModal={showEditModal}
        handleClose={handleCloseEditModal}
      />
      {showRejectModal.show && (
        <Modal
          content={
            <RejectSell
              handleClose={handleCloseRejectModal}
              refresh={refreshGrid}
              id={showRejectModal.id}
            />
          }
          showModal={showRejectModal.show}
          handleShow={showRejectModal}
          handleClose={handleCloseRejectModal}
          headerTitle={<div>Motivos del rechazo</div>}
        />
      )}
      {confirmationModal.open && (
        <ConfirmationAlert
          show={confirmationModal.open}
          handleAction={confirmationModal.handleAction}
          handleClose={confirmationModal.handleClose}
          message={confirmationModal.message}
          title={confirmationModal.title}
        />
      )}
    </div>
  );
};

export default GeneralList;
