import { bacs_status, banks_status } from "../../../variables/bacs_constants";
import { agents, issuerEntities } from "../../../utils/Service";

export const bacs_filters = {
  vehicle_id: {
    type: "text",
    name: "vehicle_id",
    placeholder: "Stock ID",
    value: null,
    size: 2,
  },
  plateNumber: {
    type: "text",
    name: "plateNumber",
    placeholder: "Dominio",
    value: null,
    size: 2,
  },
  adenda: {
    type: "text",
    name: "adenda",
    placeholder: "Adenda",
    value: null,
    size: 2,
  },
  agents: {
    type: "select",
    name: "agents",
    options: agents(),
    placeholder: "Gestor que lo presenta",
    value: "",
    size: 2,
  },
  status_bank: {
    type: "multiSelect",
    name: "status_bank",
    options: Object.entries(banks_status).map((s, index) => ({
      value: s[1].value,
      text: s[1].text,
    })),
    placeholder: "Estado del banco",
    value: "",
    size: 4,
  },
  status_bacs: {
    type: "multiSelect",
    name: "status_bacs",
    options: Object.entries(bacs_status).map((s, index) => ({
      value: s[1].value,
      text: s[1].text,
    })),
    placeholder: "Estado del BAC",
    value: [],
    size: 6,
  },
  issuerEntityId: {
    type: "multiSelect",
    name: "issuerEntityId",
    options: issuerEntities(),
    placeholder: "Seleccione un registro",
    value: [],
    size: 6,
  },
};
