import { notaries } from "utils/Service";
import {
  formType,
  legalizeStatus,
  status08,
} from "../../../variables/constants";

const legalizeStatusOptions = Object.entries(legalizeStatus).map((l) => {
  return { text: l[1].text, value: l[1].value };
});
const dropdownStatus = Object.entries(status08).map((s) => {
  return { text: s[1].text, value: s[1].value };
});

export const filtersStatusForm08 = {
  plateNumber: {
    type: "text",
    name: "plateNumber",
    placeholder: "Dominio",
    value: null,
    size: 2,
  },
  formNumber: {
    type: "text",
    name: "formNumber",
    placeholder: "Número Formulario",
    value: null,
    size: 2,
  },
  formType: {
    type: "select",
    name: "formType",
    options: formType,
    placeholder: "Tipo",
    value: "",
    size: 2,
  },
  notaryOffice: {
    type: "select",
    name: "notaryOffice",
    options: notaries(),
    placeholder: "Lugar de firma",
    value: "",
    size: 2,
  },
  startSignedDate: {
    type: "date",
    name: "startSignedDate",
    placeholder: "Firma Desde",
    value: null,
    size: 2,
  },
  endSignedDate: {
    type: "date",
    name: "endSignedDate",
    placeholder: "Firma Hasta",
    value: null,
    size: 2,
  },
  status: {
    type: "multiSelect",
    name: "status",
    options: dropdownStatus,
    placeholder: "Estado",
    value: [],
    size: 12,
  },
};
