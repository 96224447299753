import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAll = async (params) => {
  const url = `${API_URL}/users/list`;
  return await axios.post(url, params, { headers: authHeader() });
};

const getAllByProfile = async (props) => {
  const url = `${API_URL}/users/profiles?limit=${props.countItems}&page=${props.currentPage}&profile=${props.profile}&type=profiles`;
  return await axios.get(url, { headers: authHeader() });
};

const getAllByProfileId = async (props) => {
  const url = `${API_URL}/users/profiles?limit=${props.countItems}&page=${props.currentPage}&profileId=${props.profile}`;
  return await axios.get(url, { headers: authHeader() });
};

const getUserById = async (id) => {
  const url = `${API_URL}/users/${id}`;
  return await axios.get(url, { headers: authHeader() });
};

const create = (
  profile_id,
  first_name,
  last_name,
  user_name,
  email,
  password
) => {
  const url = `${API_URL}/users`;
  return axios
    .post(
      url,
      {
        profile_id,
        first_name,
        last_name,
        user_name,
        email,
        password,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const edit = (
  id,
  profile_id,
  first_name,
  last_name,
  user_name,
  email,
  password
) => {
  const url = `${API_URL}/users`;
  return axios
    .put(
      url,
      {
        id,
        profile_id,
        first_name,
        last_name,
        user_name,
        email,
        password,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const changePassword = (id, password) => {
  const url = `${API_URL}/users/password`;
  return axios
    .put(url, { id, password }, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const toggleActive = (id, active) => {
  const url = `${API_URL}/users/toggleActive`;
  return axios
    .post(url, { id, active }, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

export default {
  getAll,
  getAllByProfile,
  getAllByProfileId,
  create,
  getUserById,
  edit,
  changePassword,
  toggleActive,
};
