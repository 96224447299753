import { UserContext } from "context/UserContext";
import React from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import routes from "../routes";
import AdminLayout from "./Admin";
import AuthLayout from "./Auth";

const WrapperLayoutAdmin = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return <AdminLayout navigate={navigate} location={location} />;
};

export const WrapperLayoutAuth = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return <AuthLayout navigate={navigate} location={location} />;
};

export const RoutesMapped = ({ userLogged }) => {
  return (
    <Routes>
      {routes.map((route, index) => {
        const { layout, path, Component } = route;

        if (layout !== "/admin") {
          return <React.Fragment key={index + 1} />;
        }

        return (
          <Route
            path={path}
            element={
              <UserContext.Provider value={userLogged}>
                <Component />
              </UserContext.Provider>
            }
            key={index}
          />
        );
      })}
    </Routes>
  );
};

export const RoutesAuthMapped = ({ userLogged }) => {
  return (
    <Routes>
      {routes.map((route, index) => {
        const { layout, path, Component } = route;

        return layout !== "/auth" ? null : (
          <Route
            path={path}
            element={
              <UserContext.Provider value={userLogged}>
                <Component />
              </UserContext.Provider>
            }
            key={index}
          />
        );
      })}
    </Routes>
  );
};

export default WrapperLayoutAdmin;
