import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { Container, Row, Col } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import AuthService from "../../services/auth.service";
import { useToast } from "../../hooks";

const Register = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const addToast = useToast();

  const [firstname, setFirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          El campo es obligatorio!
        </div>
      );
    }
  };

  const validEmail = (value) => {
    if (!isEmail(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          This is not a valid email.
        </div>
      );
    }
  };

  const vlastname = (value) => {
    if (value.length < 3 || value.length > 20) {
      return (
        <div className="alert alert-danger" role="alert">
          The field must be between 3 and 20 characters.
        </div>
      );
    }
  };

  const vfirstname = (value) => {
    if (value.length < 3 || value.length > 20) {
      return (
        <div className="alert alert-danger" role="alert">
          The field must be between 3 and 20 characters.
        </div>
      );
    }
  };

  const vpassword = (value) => {
    if (value.length < 6 || value.length > 20) {
      return (
        <div className="alert alert-danger" role="alert">
          The password must be between 6 and 20 characters.
        </div>
      );
    }
  };

  const onChangeFirstname = (e) => {
    const username = e.target.value;
    setFirstname(username);
  };

  const onChangeLastname = (e) => {
    const username = e.target.value;
    setlastname(username);
  };

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.register(firstname, lastname, username, email, password).then(
        (response) => {
          // setMessage(response.data.message);
          // setSuccessful(true);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setSuccessful(false);
          addToast({
            variant: "danger",
            header: "Registro",
            body: resMessage,
          });
        }
      );
    }
  };

  return (
    <div className="content">
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card
              content={
                <Form onSubmit={handleRegister} ref={form}>
                  {!successful && (
                    <div>
                      <div className="form-group">
                        <label htmlFor="firstname">First Name</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="firstname"
                          value={firstname}
                          onChange={onChangeFirstname}
                          validations={[required, vfirstname]}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="lastname">Last Name</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="lastname"
                          value={lastname}
                          onChange={onChangeLastname}
                          validations={[required, vlastname]}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="username"
                          value={username}
                          onChange={onChangeUsername}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="email"
                          value={email}
                          onChange={onChangeEmail}
                          validations={[required, validEmail]}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <Input
                          type="password"
                          className="form-control"
                          name="password"
                          value={password}
                          onChange={onChangePassword}
                          validations={[required, vpassword]}
                        />
                      </div>

                      <div className="form-group">
                        <button className="btn btn-primary btn-block">
                          Crear usuario
                        </button>
                      </div>
                    </div>
                  )}

                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Register;
