
import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss'

const TitleAndHide = ({handlerHide,title,hide,total,showNumber = false}) => {
    return(
      <div className={styles.container}>
      <label className="capitalize">
        <strong>{title} {showNumber && `- (${total})`}</strong>
      </label>
      <NavLink
        activeClassName="selected"
        onClick={handlerHide}
        className={styles.navLink}
      >
        {hide ? (
          <div className="fas fa-chevron-circle-up" style={{fontSize:"20px"}}/>
        ) : (
          <div className="fas fa-chevron-circle-down" style={{fontSize:"20px"}}/>
        )}
      </NavLink>
    </div>
    )
}

export default TitleAndHide;