import axios from "axios";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const addOrUpdateDetail = async (id,data) => {
  return await axios.put(`${API_URL}/rendition-detail/${id}`,data, {
    headers: authHeader(),
  });
}

const getAll = async (params) => {
  return await axios.post(`${API_URL}/rendition/list`, params, {
    headers: authHeader(),
  });
};

const getById = async (id) => {
  return await axios.get(`${API_URL}/rendition/${id}`, {
    headers: authHeader(),
  });
};

const getConcepts = async () => {
  return await axios.get(`${API_URL}/rendition-concepts`, {
    headers: authHeader(),
  });
};

const getOrigins = async () => {
  return await axios.get(`${API_URL}/rendition-origin`, {
    headers: authHeader(),
  });
};

const update = async (data) => {
  return await axios.put(`${API_URL}/rendition`,data, {
    headers: authHeader(),
  });
}

export default {
  addOrUpdateDetail,
  getAll,
  getById,
  getConcepts,
  getOrigins,
  update,
};
