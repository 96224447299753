import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import List from "../../components/Table/List";
import Filters from "../../components/Filters";
import ButtonIcon from "../../components/ButtonIcon";
import ConfirmationAlert from "../../components/ConfirmationAlert/confirmationAlert";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import {
  useConfirmationAlert,
  useFilters,
  useGrid,
  useToast,
} from "../../hooks";
import { updateLocalStorageCache } from "../../utils/Service";
import { badgeGenericBoolean } from "../../utils/Badges";
import { headSort } from "./constants/constants_columns";
import { filterFields } from "./constants/constants_filter";

const UsersList = (props) => {
  const navigate = useNavigate();
  const addToast = useToast();
  const {
    confirmationModal,
    handleConfirmationModal,
    handleCloseConfirmationModal,
  } = useConfirmationAlert();
  const [currentPermissions, setCurrentPermissions] = useState(null);

  const {
    body,
    cleanPage,
    countItems,
    currentPage,
    currentSort,
    handleBody,
    handleSort,
    handleToPage,
    handleTotalItems,
    handleTotalPage,
    refresh,
    refreshGrid,
    totalItems,
    totalPage,
  } = useGrid(
    {
      model: null,
      field: "id",
      type: "ASC",
    },
    15
  );
  const { filters, onFilterChange } = useFilters(filterFields, cleanPage);

  const sortColumns = (body) =>
    body.map((e) => ({
      id: e.id,
      first_name: e.first_name,
      last_name: e.last_name,
      email: e.email,
      role: e.role,
      active: badgeGenericBoolean(e.active),
      _active: e.active,
    }));

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions("USUARIOS");
    setCurrentPermissions(permissions);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: countItems,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
      sortModel: currentSort?.model,
      filters: filters,
    };
    UserService.getAll(params).then((response) => {
      if (response.data.success) {
        handleTotalItems(response.data.totalItems);
        handleTotalPage(Math.ceil(response.data.totalItems / countItems));
        handleBody(sortColumns(response.data.response));
      }
    });
  }, [currentPage, currentSort, filters, refresh]);

  const handleToggleActive = (id, active) => {
    UserService.toggleActive(id, active).then(() => {
      addToast({
        variant: "success",
        header: "Usuarios",
        body: "Se actualizó el usuario correctamente",
      });
      refreshGrid();
      handleCloseConfirmationModal();
      updateLocalStorageCache();
    });
  };

  const actions = (props) => {
    return (
      <>
        {currentPermissions && currentPermissions.permissions.hasUpdate ? (
          <ButtonIcon
            onClick={() => navigate(`/admin/users/${props.id}/edit`)}
            name="file-alt"
            title="Editar"
          />
        ) : null}

        {currentPermissions && currentPermissions.permissions.hasDelete ? (
          <ButtonIcon
            onClick={() =>
              handleConfirmationModal(
                "",
                () => handleToggleActive(props.id, !props._active),
                "¿Desea modificar el estado del usuario?",
                "Al confirmar, se actualizará el estado del usuario."
              )
            }
            name={props._active ? "user-xmark" : "user-check"}
            color={props._active ? "danger" : "success"}
            title={props._active ? "Desactivar" : "Activar"}
          />
        ) : null}
      </>
    );
  };

  return (
    <div className="content">
      <div className="content-filters ps-3">
        <Button href="/admin/users/create" className="btn-fill btn btn-primary">
          Nuevo Usuario
        </Button>
      </div>
      <Container fluid>
        <Filters filterFields={filters} onFilterChange={onFilterChange} />
        <List
          thead={headSort}
          tbody={body}
          actions={actions}
          totalItems={totalItems}
          currentPage={currentPage}
          totalPage={totalPage}
          toPage={handleToPage}
          handleSort={handleSort}
          sortable
          currentSort={currentSort}
        />
      </Container>
      {confirmationModal.open && (
        <ConfirmationAlert
          show={confirmationModal.open}
          handleAction={confirmationModal.handleAction}
          handleClose={confirmationModal.handleClose}
          message={confirmationModal.message}
          title={confirmationModal.title}
        />
      )}
    </div>
  );
};

export default UsersList;
