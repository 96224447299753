import axios from "axios";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_SELLS_URL;

const getAll = async (params) => {
  return await axios.post(`${API_URL}/sells-rejected/list`, params, { headers: authHeader() });
};

export default {
  getAll,
};
