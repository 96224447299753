import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import moment from "moment";
import Card from "components/Card/Card.jsx";
import { constants, meses } from "../../variables/constants";

const InformationNotary = (props) => {
  const [information, setInformation] = useState(false);
  const [title, setTitle] = useState("Periodo");

  useEffect(() => {
    setInformation(props.data);
    let curTitle = 'Periodo ';
    if (props.data && props.data.year !== constants.dropdownDefault && props.data.month !== constants.dropdownDefault) {
      curTitle += meses[props.data.month];
    } else if (props.data && props.data.year !== constants.dropdownDefault && props.data.week !== constants.dropdownDefault) {
      curTitle += `${moment(props.data.monday).format('DD/MM/YYYY')} - ${moment(props.data.monday).add(6, 'd').format('DD/MM/YYYY')}`;
    } else if (props.data && props.data.year !== constants.dropdownDefault) {
      curTitle += 'Anual';
    }
    setTitle(curTitle);
  }, [props.data]);

  return (
    <div className="information">
      <Card
        ctTableFullWidth
        ctTableResponsive
        title={title}
        // legend={<strong>Tomas Totales: {information ? information.closed + information.open + information.others : '-'}</strong>}
        content={
          (props.loading)
            ? 'Loading...'
            : <Table striped hover>
              <tbody>
                <tr style={{ backgroundColor: '#87CB16' }}>
                  <td><strong>Cerradas</strong></td>
                  <td>
                    {information ? information.closed : '-'}
                  </td>
                  <td>
                    {(information)
                      ? `(${((information.closed / (information.closed + information.open)) * 100).toFixed(2)}%)`
                      : ''}
                  </td>
                </tr>
                <tr style={{ backgroundColor: '#FB404B' }}>
                  <td><strong>Abiertas</strong></td>
                  <td>
                    {information ? information.open : '-'}
                  </td>
                  <td>
                    {(information)
                      ? `(${((information.open / (information.closed + information.open)) * 100).toFixed(2)}%)`
                      : ''}
                  </td>
                </tr>
                <tr>
                  <td><strong>Total</strong></td>
                  <td colSpan="2">
                    {information ? information.closed + information.open : '-'}
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    &nbsp;
                  </td>
                </tr>
                <tr>
                  <td><strong>Promedio días</strong></td>
                  <td colSpan="2">
                    {information ? information.daysAvg : '-'}
                  </td>
                </tr>
                <tr>
                  <td><strong>Errores</strong></td>
                  <td colSpan="2">
                    {information ? information.errors : '-'}
                  </td>
                </tr>
              </tbody>
            </Table>
        }
      />
    </div>
  );
};

export default InformationNotary;
