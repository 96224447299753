import { useEffect, useState } from "react";
import authService from "services/auth.service";

function useConfiguration(gridPermission) {
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const [profile, setProfile] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const permissions = authService.getCurrentPermissions(gridPermission);
    const user = JSON.parse(localStorage.getItem("user")) || null;
    setProfile(user.data.user.profile.name.toLowerCase());
    setCurrentPermissions(permissions);
    setUserId(user.data.user.id);
    //eslint-disable-next-line
  }, []);

  return { currentPermissions, profile, userId };
}

export default useConfiguration;
