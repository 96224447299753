import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import { formPDF } from "../../../utils/pdf";
import StatusForm08Service from "../../../services/form08/statusForm08.service";
import { constants, form08 } from "../../../variables/constants";
import FormOptions from "./formOptions";

const PrintForm = (props) => {
  const { data, handleClose, functionId } = props;

  const initialFormState = {
    id: data.id,
    form: null,
    reception_place_id: null,
  };
  const [form, setForm] = useState(null);
  const [id, setId] = useState(null);
  const [submitAction, setSubmitAction] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [fields, setFields] = useState(initialFormState);

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  // Al presionar un botón
  useEffect(() => {
    const print08PDF = ({ id, form, reception_place_id }) => {
      StatusForm08Service.getDataById(id)
        .then((response) => {
          if (response.data.success) {
            let data = null;
            if ([1, 2, 8].includes(form)) {
              data = form === 2 ? form08.bacsInfo : form08.buyerInfo;
            }
            formPDF(
              data,
              response.data.response,
              form,
              reception_place_id,
              functionId
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    setAllowSubmit(!!fields.reception_place_id && !!fields.form);

    if (submitAction) {
      setSubmitAction(false);
      setAllowSubmit(false);
      print08PDF(fields);
    }
  }, [submitAction, fields]);

  // Manejo generico del cambio de un dato simple de un formulario

  return (
    <div className="modalChecklist">
      <FormOptions onSimpleFieldChange={onSimpleFieldChange} />
      <br />

      <div className="modal-footer">
        <div>
          <Button
            className="btn-fill pull-right btn btn-primary btn-sm"
            onClick={() => {
              handleClose();
            }}
          >
            {constants.close}
          </Button>
        </div>
        <div className="row">
          <Button
            className="btn-fill pull-right btn btn-warning btn-sm"
            onClick={(e) => {
              setSubmitAction(true);
            }}
            disabled={!allowSubmit}
          >
            {constants.accept}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PrintForm;
