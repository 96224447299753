import React from "react";
import Modal from "react-bootstrap/Modal";

const ModalDisplay = ({
  showModal,
  handleClose,
  headerTitle,
  content,
  size = "lg",
}) => {
  return (
    <Modal size={size} show={showModal} onHide={handleClose}>
      <Modal.Header closeButton style={{ alignItems: "center" }}>
        <Modal.Title>{headerTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
    </Modal>
  );
};

export default ModalDisplay;
