import axios from "axios";
import authHeader from "../auth-header";

const API_URL_SELLS = process.env.REACT_APP_API_SELLS_URL;
const API_URL_PURCHASE = process.env.REACT_APP_API_URL;

const getAll = async (params) => {
  return await axios.post(`${API_URL_SELLS}/sells-presentation/list`, params, { headers: authHeader() });
};

const getById = async (id) => {
  return await axios.get(`${API_URL_SELLS}/sells-presentation/${id}`, { headers: authHeader() });
};

const update = async (id,data) => {
  return await axios.put(`${API_URL_SELLS}/sells-presentation/${id}`,data,{
    headers: authHeader(),
  })
}

const getSellsForms = async (opportunity_id) => {
  return await axios.get(`${API_URL_PURCHASE}/form/sells/${opportunity_id}`, { headers: authHeader() });
};

export default {
  getAll,
  getById,
  update,
  getSellsForms
};
