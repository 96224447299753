import React from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmationAlert = ({ show, handleClose, title, message, handleAction }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-fill pull-right btn btn-danger btn-sm"
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          className="btn-fill pull-right btn btn-success btn-sm"
          onClick={handleAction}
        >
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationAlert;
