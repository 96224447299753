import React from "react";

const CheckboxField = ({
  label,
  name,
  checked,
  onChange,
  disabled,
  className = null,
}) => {
  return (
    <div className={className ? className : "col"}>
      <label className="capitalize">
        <strong>{label}</strong>
      </label>
      {checked != null ? (
        <div>
          <span>
            <input
              type="checkbox"
              name={name}
              onChange={(e) => onChange(name, e.target.checked)}
              checked={checked}
              disabled={disabled}
            />
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default CheckboxField;
