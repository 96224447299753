import axios from "axios";
import authHeader from "../auth-header";
const API_URL = process.env.REACT_APP_API_URL;

const getAll = async (data) => {
  const url = `${API_URL}/bacs/list`;
  return await axios.post(url, { ...data }, { headers: authHeader() });
};

const getById = async (id) => {
  return await axios.get(`${API_URL}/bacs/${id}`, {
    headers: authHeader(),
  });
};

const update = async (data) => {
  return await axios.put(
    `${API_URL}/bacs`,
    { ...data },
    {
      headers: authHeader(),
    }
  );
};

const getReasons = async () => {
  return await axios.get(`${API_URL}/bacs-comment-reasons`, {
    headers: authHeader(),
  });
};

const getCancelReasons = async () => {
  return await axios.get(`${API_URL}/bacs-cancel-reasons`, {
    headers: authHeader(),
  });
};

const getComments = async (id) => {
  return await axios.get(`${API_URL}/bacs/${id}/comments`, {
    headers: authHeader(),
  });
};

export default {
  getAll,
  getById,
  update,
  getReasons,
  getComments,
  getCancelReasons,
};
