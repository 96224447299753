import React from "react";
import moment from "moment";
import { sells_status } from "variables/constants";
import { genericBadge } from "utils/Badges";

const CommentsGenericBox = ({ comments }) => {
  return comments?.length === 0 ? (
    <div className="row">
      <div className="col">
        <label className="capitalize">
          <strong>-</strong>
        </label>
      </div>
    </div>
  ) : (
    comments.map((c, i) => (
      <div key={i}>
        <div className="row" key={i}>
          <div className="col-sm-3" key={i + "a"}>
            {moment(c.auditTimestamp)
              .utcOffset("-0300")
              .format("DD/MM/YYYY H:mm:ss")}
          </div>
          {c.status ? (
            <div className="col-sm-3" key={i + "b"}>
              {c.status}
            </div>
          ) : null}
          <div className="col" key={i + "c"}>
            {`${c.reason || ""} ${c.comment}`.trim()}
          </div>
          <div className="col-sm-3" key={i + "d"}>
            {c.first_name}
          </div>
        </div>
        <hr style={{ marginTop: "0.2rem", marginBottom: "0.2rem" }} />
      </div>
    ))
  );
};

const CommentsNoGenericBox = ({ comments, statusList }) => {
  return comments?.length === 0 ? (
    <div className="row">
      <div className="col">
        <label className="capitalize">
          <strong>-</strong>
        </label>
      </div>
    </div>
  ) : (
    comments.map((c, i) => (
      <div key={i}>
        <div className="row">
          <div className="col-sm-3" key={"date-" + i}>
            {moment(c.auditTimestamp)
              .utcOffset("-0300")
              .format("DD/MM/YYYY H:mm:ss")}
          </div>
          <div className="col-sm-3" key={"status-" + i}>
            {genericBadge(statusList, c.status)}
          </div>
          <div className="col-sm-3" key={"comment-" + i}>{`${
            c.reason ? `${c.reason}: ` : ""
          }${c.comment}`}</div>
          <div className="col-sm-3" key={"author-" + i}>
            {c.first_name}
          </div>
        </div>
        <hr style={{ marginTop: "0.2rem", marginBottom: "0.2rem" }} />
      </div>
    ))
  );
};

const CommentsBox = ({ comments, generic, statusList }) => {
  return generic ? (
    <CommentsGenericBox comments={comments} />
  ) : (
    <CommentsNoGenericBox comments={comments} statusList={statusList} />
  );
};

export default CommentsBox;
