import axios from "axios";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getByPlateNumber = async (plate_number) => {
  return await axios.get(
    `${API_URL}/purchase/inquiry/plate-number/${plate_number}`,
    { headers: authHeader() }
  );
};

const update = async (id, data) => {
  return await axios.put(`${API_URL}/purchase/inquiry/update/${id}`, data, {
    headers: authHeader(),
  });
};

export default {
  getByPlateNumber,
  update,
};
