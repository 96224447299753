import { Navigate, Route, Routes } from "react-router-dom";
import axios from "axios";
import WrapperLayoutAdmin, { WrapperLayoutAuth } from "./layouts/wrappers";
import { ToastContextProvider } from "./context/ToastContext";
import { login } from "variables/constants";

// Add a 401 response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      // handle error: inform user, go to login, etc
      window.location.assign(login);
      return;
    } else {
      return Promise.reject(error);
    }
  }
);

const ErrorPage = () => {
  return <h1>TEST Error page</h1>;
};

function App() {
  return (
    <ToastContextProvider>
      <Routes>
        <Route path="/admin/*" element={<WrapperLayoutAdmin />} />
        <Route path="/auth/*" element={<WrapperLayoutAuth />} />
        <Route path="/" element={<Navigate to="/admin/dashboard" />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </ToastContextProvider>
  );
}

export default App;
