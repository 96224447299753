import React from "react";
import {
  agentProfiles,
  sells_status,
} from "../../../../../variables/constants";
import { BadgeField } from "../../components";
import { diffDateFormula } from "utils/Badges";
const LogisticInfo = ({
  profile,
  fields,
  onSimpleFieldChange,
  constantData,
}) => {
  return (
    <div>
      <div className="row">
        <BadgeField
          label={"Dominio"}
          text={fields.plate_number}
          badgeClass={"plate_number"}
          variant={"warning"}
        />
        <BadgeField
          label={"Stock ID"}
          text={fields.stock_id}
          badgeClass={`plate_number`}
          variant={"info"}
        />
        <BadgeField
          label={"Días en tramite"}
          text={constantData.diff_days}
          badgeClass={`plate_number`}
          variant={diffDateFormula(constantData.diff_days)}
        />
         <BadgeField
          label={"Días suspendidos"}
          text={constantData.suspended_days}
          badgeClass={`plate_number`}
          variant={"info"}
        />
        <BadgeField
          label={"BACS"}
          text={fields.bacs_id ? "SI" : "NO"}
          badgeClass={`plate_number`}
          variant={fields.bacs_id ? "success" : "danger"}
        />
      </div>
      {!agentProfiles.includes(profile) ? (
        <div className="row">
          <div className="col">
            <label className="capitalize">
              <strong>registro radicación</strong>
            </label>
            <div>{constantData.current_location}</div>
          </div>
          <BadgeField
            label={"días en logístico"}
            text={constantData.diff_days_presentation}
            badgeClass={`plate_number`}
            variant={"info"}
          />
          <div className="col">
            <label className="capitalize">
              <strong>Solicitud De Extravío</strong>
            </label>
            <div>
              <input
                type="text"
                className="form-control"
                name="loss_request"
                placeholder={"Solicitud De Extravío"}
                autoComplete="false"
                onChange={(e) =>
                  onSimpleFieldChange("loss_request", e.target.value)
                }
                value={fields.loss_request}
                disabled={
                  fields.status_id === sells_status.PRESENTATION_RETIRED.value
                }
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default LogisticInfo;
