import React, {useEffect, useState} from "react";
import { Table } from "react-bootstrap";
import Card from "components/Card/Card.jsx";

const Penalties = (props) => {  
    const [penalties, setPenalties] = useState(false);

    useEffect(() => {
        setPenalties(props.data);
    }, [props.data]);

    return (            
        <div>
            <Card
                ctTableFullWidth
                ctTableResponsive
                title="Multas"
                content={
                    (props.loading) 
                    ? 'Loading...'
                    : <Table striped hover>
                        <tbody>
                            <tr>
                            <td><strong>Multas tipo 4 - CABA</strong></td>
                            <td>
                                {(penalties && penalties.categoria_4) 
                                ? penalties.categoria_4
                                : '-'}
                            </td>
                            </tr>
                            <tr>
                            <td><strong>Multas totales - CABA</strong></td>
                            <td>
                                {(penalties && penalties.tieneInfracciones) 
                                ? penalties.tieneInfracciones
                                : '-'}
                            </td>
                            </tr>                    
                        </tbody>
                    </Table>                          
                }
            />
        </div>
  );
};

export default Penalties;
