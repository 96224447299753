import { useEffect, useState } from "react";
import authService from "services/auth.service";

function usePermission(permission) {
  const [currentPermissions, setCurrentPermissions] = useState(null);

  useEffect(() => {
    const permissions = authService.getCurrentPermissions(permission);
    setCurrentPermissions(permissions);
  }, []);

  return { currentPermissions };
}

export default usePermission;
