import { issuerEntities, zones } from "utils/Service";

export const filtersRejected = {
  plateNumber: {
    type: "text",
    name: "plateNumber",
    placeholder: "Dominio",
    value: null,
    size: 2,
  },
  receptionDate: {
    type: "date",
    name: "receptionDate",
    placeholder: "dd/mm/yyyy",
    value: null,
    size: 2,
  },
  issuerEntityId: {
    type: "multiSelect",
    name: "issuerEntityId",
    options: issuerEntities(),
    placeholder: "Seleccione un registro",
    value: [],
    size: 6,
  },
  zone: {
    type: "select",
    name: "zone",
    options: zones(),
    placeholder: "Seleccione una zona",
    value: "",
    size: 2,
  },
};
