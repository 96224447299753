import React, { useEffect, useState } from "react";
import { Badge, Button, Container } from "react-bootstrap";
import Add from "../../components/Modal/Sells/add";
import RejectSell from "../../components/Modal/Sells/rejectSell";
import SellsService from "../../services/sells/sells.service";
import Filters from "../../components/Filters/index";
import Edit from "../../components/Modal/Sells/edit";
import List from "../../components/Table/List";
import ExcelButton from "../../components/ExcelButton";
import ButtonIcon from "../../components/ButtonIcon";
import {
  delivery_types,
  sells_status,
  sells_status_select,
  yesNoOptions,
} from "../../variables/constants";
import {
  badgeGenericBoolean,
  diffDateBadge,
  genericBadge,
  twoBadges,
} from "../../utils/Badges";
import { useGrid, useStatusModal } from "../../hooks/index";
import Modal from "../../layouts/ModalDisplay";
import {
  FINANCED_STATUS_NAME,
  providerOptions,
} from "../../variables/sells/index";
import authService from "../../services/auth.service";
import { headSellsList } from "./constants/constants_columns";
import styles from "../General/styles.module.scss";
import { categories } from "utils/Service";

const filterFields = {
  plate_number: {
    type: "text",
    name: "plate_number",
    placeholder: "Dominio",
    value: "",
    size: 3,
  },
  owner: {
    type: "text",
    name: "owner",
    placeholder: "Cliente",
    value: "",
    size: 3,
  },
  delivery_date: {
    type: "date",
    name: "delivery_date",
    placeholder: "Fecha de entrega",
    value: null,
    size: 3,
  },
  financed: {
    type: "select",
    name: "financed",
    options: yesNoOptions,
    placeholder: "Financiado",
    value: "",
    size: 3,
  },
  status_ns: {
    type: "select",
    name: "status_ns",
    options: categories(),
    placeholder: "Estado NS",
    value: "",
    size: 2,
  },
  delivery_type: {
    type: "select",
    name: "delivery_type",
    options: delivery_types,
    placeholder: "Tipo de entrega",
    value: "",
    size: 2,
  },
  provider: {
    type: "select",
    name: "provider",
    options: providerOptions,
    placeholder: "Proveedor",
    value: "",
    size: 3,
  },
  status: {
    type: "multiSelect",
    name: "status",
    options: sells_status_select.filter((x) => x.group === "SELLS"),
    placeholder: "Estado",
    value: [],
    size: 5,
  },
};

const sortColumns = (body) => {
  return body.map((e) => ({
    opportunity_id: e.opportunity_id,
    plate_number: twoBadges(
      e.plate_number,
      e.financed ? FINANCED_STATUS_NAME.FINANCED : null
    ),
    stock_id: e.stock_id,
    status_ns: e.status_ns,
    status: genericBadge(sells_status, e.status),
    financing_provider: e.financing_provider,
    createdAtSell: e.createdAtSell,
    delivery_date: e.delivery_date,
    delivery_type: e.delivery_type,
    owner: e.owner,
    signing_form_08_date: e.signing_form_08_date,
    notary_office: e.notary_office,
    week_delivery_sell: e.week_delivery_sell,
    aging: diffDateBadge(e.aging),
    diff_days: diffDateBadge(e.diff_days),
    suspended_date: e.suspended_date,
    bacs_id: badgeGenericBoolean(e.bacs_id),
  }));
};

const sort = {
  model: null,
  field: "opportunity_id",
  type: "ASC",
};

const StatusForm08List = (props) => {
  const {
    handleCloseAddModal,
    handleCloseEditModal,
    handleCloseRejectModal,
    handleOpenAddModal,
    handleOpenEditModal,
    handleShowRejectModal,
    showAddModal,
    showEditModal,
    showRejectModal,
  } = useStatusModal();

  const {
    body,
    countItems,
    currentPage,
    currentSort,
    totalItems,
    totalPage,
    handleBody,
    handleSort,
    handleToPage,
    handleTotalItems,
    handleTotalPage,
    cleanPage,
  } = useGrid(sort);

  const [refresh, setRefresh] = useState(new Date());
  const [dataModal, setDataModal] = useState([]);
  const [filters, setFilters] = useState(filterFields);
  const [currentPermissions, setCurrentPermissions] = useState(null);

  const next = {
    link: "/admin/management/toma-vehiculo",
    text: "Toma",
  };

  useEffect(() => {
    const permissions = authService.getCurrentPermissions("VENTAS");
    setCurrentPermissions(permissions);
  }, []);

  const onFilterChange = (filterName, filterValue) => {
    setFilters({
      ...filters,
      [filterName]: { ...filters[filterName], value: filterValue },
    });
    cleanPage();
  };

  const refreshGrid = () => {
    setRefresh(new Date());
  };

  const openEditModal = (id) => {
    SellsService.getById(id).then((response) => {
      if (response.data.success) {
        setDataModal(response.data);
        handleOpenEditModal();
      }
    });
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: countItems, // like countItems
      sortModel: currentSort?.model,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
      filters,
    };

    SellsService.getAll(params).then((response) => {
      handleTotalItems(response.data.totalItems);
      handleTotalPage(Math.ceil(response.data.totalItems / countItems));
      handleBody(sortColumns(response.data.response));
    });
    //eslint-disable-next-line
  }, [currentPage, currentSort, countItems, filters, refresh]);

  const actions = (props) => (
    <>
      {currentPermissions && currentPermissions.permissions.hasSelect && (
        <ButtonIcon
          onClick={(e) => openEditModal(props.opportunity_id, e)}
          name="file-alt"
          title="Editar"
        />
      )}
      {currentPermissions && currentPermissions.permissions.hasDelete && (
        <ButtonIcon
          onClick={() => handleShowRejectModal(props.opportunity_id)}
          name="times"
          color="danger"
          size="lg"
          title="Rechazar"
        />
      )}
    </>
  );

  return (
    <div className="content">
      <div className={`content-filters form-group ${styles.lessHeight}`}>
        <Button
          className="btn-fill btn btn-primary capitalize"
          onClick={handleOpenAddModal}
        >
          Agregar una venta
        </Button>
        <ExcelButton type="ventas" filters={filters} />
      </div>
      <Filters filterFields={filters} onFilterChange={onFilterChange} />
      <List
        thead={headSellsList}
        tbody={body}
        actions={actions}
        next={next}
        totalItems={totalItems}
        currentPage={currentPage}
        totalPage={totalPage}
        toPage={handleToPage}
        handleSort={handleSort}
        sortable
        currentSort={currentSort}
      />
      {showEditModal && (
        <Modal
          content={
            <Edit
              data={dataModal}
              handleClose={handleCloseEditModal}
              refresh={refreshGrid}
            />
          }
          showModal={showEditModal}
          handleShow={openEditModal}
          handleClose={handleCloseEditModal}
          headerTitle={
            <Badge bg="warning">
              Metodo de pago{" "}
              {dataModal.response.financed
                ? FINANCED_STATUS_NAME.FINANCED
                : FINANCED_STATUS_NAME.CASH}
            </Badge>
          }
        />
      )}

      {showAddModal && (
        <Modal
          content={
            <Add handleClose={handleCloseAddModal} refresh={refreshGrid} />
          }
          showModal={showAddModal}
          handleShow={handleOpenAddModal}
          handleClose={handleCloseAddModal}
        />
      )}

      {showRejectModal.show && (
        <Modal
          content={
            <RejectSell
              handleClose={handleCloseRejectModal}
              refresh={refreshGrid}
              id={showRejectModal.id}
            />
          }
          showModal={showRejectModal.show}
          handleShow={showRejectModal}
          handleClose={handleCloseRejectModal}
          headerTitle={<div>Motivos del rechazo</div>}
        />
      )}
    </div>
  );
};

export default StatusForm08List;
