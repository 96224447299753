import { Multiselect } from "multiselect-react-dropdown";
import React, { useState } from "react";
import { issuerEntities } from "utils/Service";

const MultiSelectField = ({
  name,
  saveChanges,
  selectedValues,
  placeholder = "",
  handleOnSelected,
  disable = false,
}) => {
  const onSelect = (selectedList, selectedItem) => {
    handleOnSelected(selectedItem);
    saveChanges(name, selectedItem);
  };

  // Eliminación del emisor
  const onRemove = () => {
    saveChanges(name, null);
  };

  return (
    <Multiselect
      name={name}
      options={issuerEntities()}
      selectedValues={selectedValues}
      onSelect={onSelect}
      onRemove={onRemove}
      displayValue="text"
      placeholder={placeholder}
      closeIcon="cancel"
      selectionLimit="1"
      showCheckbox={false}
      singleSelect={false}
      disable={disable}
      selectedValueDecorator={(v) => v.substr(0, 40)}
    />
  );
};

export default MultiSelectField;
