import ConfirmationAlert from "components/ConfirmationAlert/confirmationAlert";
import { useEffect, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import Form from "react-validation/build/form";
import Multiselect from "multiselect-react-dropdown";

import { agents, bacsRequirements } from "../../../utils/Service";
import {
  CheckboxField,
  DatePickerField,
  InputField,
  MultiSelectField,
  SelectField,
} from "../../../components/Form";
import { useConfirmationAlert, useToast } from "../../../hooks/";
import bacsService from "../../../services/bacs/bacs.service";
import bacsRequirementsService from "../../../services/bacs/bacsRequirements.service";
import {
  bacs_status,
  get_status_name,
} from "../../../variables/bacs_constants";
import { constants } from "../../../variables/constants";
import { Comments } from "../../Comments/index";
import { BadgeField } from "../Sells/components";
import CustomAccordion from "components/Accordion";
import Requirement from "components/RequirementCheck";

const isRejected = [
  bacs_status.DESAFECTADO_VENTA.value,
  bacs_status.DESAFECTADO_TIEMPO.value,
];

const showBibliorato = [
  bacs_status.RETIRO_CEDULA.value,
  bacs_status.RETIRO_CEDULA_OBSERVADO.value,
  bacs_status.COMPLETADO.value,
];

const allowCompleteStatus = [
  bacs_status.RETIRO_CEDULA.value,
  bacs_status.RETIRO_CEDULA_OBSERVADO.value,
];

const readOnlyProfile = [constants.readOnlyProfile];

const observedStatus = [bacs_status.RETIRO_CEDULA.value];

const BadgeBacsFields = ({ fields }) => (
  <div className="row">
    <BadgeField
      label={"Dominio"}
      text={fields.plate_number}
      badgeClass={"plate_number"}
      variant={"warning"}
    />
    <BadgeField
      label={"Stock ID"}
      text={fields.vehicle_id}
      badgeClass={`plate_number`}
      variant={"info"}
    />
    <BadgeField
      label={"Estado"}
      text={bacs_status[get_status_name[fields.status_id]].text}
      badgeClass={`badge ${
        bacs_status[get_status_name[fields.status_id]].color
      } capitalize`}
      style={{ width: "100%", padding: "13px" }}
    />
    {/* <BadgeField
        label={"Estado del banco"}
        text={status.text}
        badgeClass={`badge ${status.color} capitalize`}
        style={{ width: "100%", padding: "13px" }}
      /> */}
  </div>
);

const EditBacsField = ({ fields }) => {
  return (
    <div>
      <div className="row">
        <InputField label={`Adenda`} value={fields.adenda} disabled={true} />
        <InputField
          label={`Chasis`}
          value={fields.vehicle_chasis}
          disabled={true}
        />
        <InputField
          label={`Precio`}
          value={fields.vehicle_price}
          disabled={true}
        />
        <InputField
          label={`Antigüedad (meses)`}
          value={`${fields.months_seniority}`}
          disabled={true}
        />
      </div>
    </div>
  );
};

const BacsDateFields = ({ fields, onChange, disabled }) => {
  return (
    <div>
      <div className="row">
        <DatePickerField
          selected={
            fields.turn_take_date ? new Date(fields.turn_take_date) : null
          }
          label={"Turno retiro cédula"}
          onChange={(date) => onChange("turn_take_date", date)}
          disabled={disabled || allowCompleteStatus.includes(fields.status_id)}
        />
        <DatePickerField
          selected={
            fields.license_reception_date
              ? new Date(fields.license_reception_date)
              : null
          }
          label={"Recepción cédula"}
          onChange={(date) => onChange("license_reception_date", date)}
          disabled={disabled}
        />
        <DatePickerField
          selected={
            fields.turn_title_reception_date
              ? new Date(fields.turn_title_reception_date)
              : null
          }
          label={`Recepción título`}
          onChange={(date) => onChange("turn_title_reception_date", date)}
          disabled={disabled}
        />
      </div>
      <div className="row">
        <DatePickerField
          selected={
            fields.logistica_date ? new Date(fields.logistica_date) : null
          }
          disabled={true}
          label={"Entrega logística"}
        />
        <DatePickerField
          selected={
            fields.presentation_date ? new Date(fields.presentation_date) : null
          }
          disabled={true}
          label={"Presentación registro"}
        />
        <DatePickerField
          selected={fields.retiro_date ? new Date(fields.retiro_date) : null}
          label={"Retiro de cédula"}
          disabled={true}
        />
      </div>
    </div>
  );
};

const Edit = (props) => {
  const {
    data: {
      response: {
        id,
        vehicle_id,
        plate_number,
        adenda,
        status_id, //status bacs
        bank_status_id, //bank status,
        vehicle_chasis, //Chasis
        vehicle_price, //Precio
        documentation_link,
        person_in_charge_id,
        person_in_charge,
        issuer_entity_tentative_id,
        issuer_entity_tentative,
        turn_request_date, //Turno de presentación
        turn_take_date, // Turno retiro cédula
        turn_title_reception_date, // Recepción titulo
        license_reception_date, // Recepción cédula
        logistica_date, //Entrega logistica, se completa cuando se presiona un boton,
        presentation_date, //presentación registro real
        retiro_date, //retiro de cedula
        purchase_form08_signed, //fecha de firma 08 compra
        months_seniority, //Antiguedad
        bibliorato,
        comments,
      },
    },
    handleClose,
    refresh,
  } = props;

  const current_status = bacs_status[get_status_name[status_id]];
  const next_status = current_status.next
    ? bacs_status[current_status.next]
    : null;

  const initialFormState = {
    id,
    vehicle_id,
    plate_number,
    adenda,
    status_id, //status bacs
    bank_status_id, //bank status,
    vehicle_chasis,
    vehicle_price,
    documentation_link,
    person_in_charge_id,
    person_in_charge,
    issuer_entity_tentative_id,
    issuer_entity_tentative,
    turn_request_date,
    turn_title_reception_date, // Recepción titulo
    turn_take_date, // Turno retiro cédula
    license_reception_date, // Recepción cédula
    logistica_date, // Entrega logística, se completa cuando se presiona un boton,
    presentation_date, // Presentación registro real
    retiro_date, // Retiro de cédula
    purchase_form08_signed, // Fecha de firma 08 compra
    months_seniority, // Antigüedad
    bibliorato,
  };

  const [fields, setFields] = useState(initialFormState);
  const [reasons, setReasons] = useState([]);
  const [selectedIssuer, setSelectedIssuer] = useState();
  const { confirmationModal, handleConfirmationModal } = useConfirmationAlert();
  const [profile, setProfile] = useState();
  const [currentRequirements, setCurrentRequirements] = useState([]);
  const [totalRequirements, setTotalRequirements] = useState();

  const addToast = useToast();

  const readOnly =
    readOnlyProfile.includes(profile) || isRejected.includes(status_id);

  const updateRequirements = (id, status) => {
    const idx = currentRequirements.findIndex((r) => r.requirement_id === id);
    currentRequirements[idx].status = status;
    setTotalRequirements(currentRequirements.filter((r) => r.status).length);
  };

  const getReasons = async () => {
    try {
      const response = await bacsService.getReasons();
      if (response.status === 200) {
        setReasons(response.data.response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user")) || null;
    setProfile(user.data.user.profile.name.toLowerCase());

    getReasons();

    bacsRequirementsService.getAllByBac(id).then((response) => {
      if (response.data.success) {
        setCurrentRequirements(response.data.response);
        setTotalRequirements(
          response.data.response.filter((r) => r.status).length
        );
      }
    });

    setSelectedIssuer(
      issuer_entity_tentative_id === null
        ? null
        : [
            {
              value: issuer_entity_tentative_id,
              text: issuer_entity_tentative,
            },
          ]
    );
  }, []);

  const submit = async (isStatus = false, next_status) => {
    const data = {
      id: id,
      status_id: isStatus ? next_status : status_id,
      adenda: fields.adenda,
      comment: fields.observation,
      comment_reason_id: fields.cancel_reason_id,
      documentation_link: fields.documentation_link,
      person_in_charge_id: fields.person_in_charge_id,
      turn_take_date: fields.turn_take_date,
      turn_title_reception_date: fields.turn_title_reception_date,
      license_reception_date: fields.license_reception_date,
      issuer_entity_tentative_id: fields.issuer_entity_tentative_id,
      turn_request_date: fields.turn_request_date,
      logistica_date: fields.logistica_date,
      presentation_date: fields.presentation_date,
      purchase_form08_signed: fields.purchase_form08_signed,
      bibliorato: fields.bibliorato,
    };

    const arrPromises = [];

    const promise_bacs = new Promise((resolve, reject) => {
      bacsService.update(data).then((response) => {
        if (response.status === 200) {
          resolve("Se actualizaron los datos");
        } else {
          reject("Fallo la actualización de los datos");
        }
      });
    });
    arrPromises.push(promise_bacs);

    // Si estoy observando y cargo requisitos
    if (
      data.status_id === bacs_status.RETIRO_CEDULA_OBSERVADO.value &&
      fields.requirements?.length
    ) {
      const promise_requirements = new Promise((resolve, reject) => {
        bacsRequirementsService
          .insert(id, fields.requirements)
          .then((response) => {
            if (response.success) {
              resolve("Se crearon los requisitos");
            } else {
              reject("Fallo la creación de los requisitos");
            }
          });
      });

      arrPromises.push(promise_requirements);
    }

    Promise.all(arrPromises)
      .then((values) => {
        values.map((v) =>
          addToast({
            variant: "success",
            header: "BACS",
            body: v,
          })
        );
        refresh();
        handleClose();
      })
      .catch((reason) => {
        addToast({
          variant: "danger",
          header: "BACS",
          body: reason,
        });
        refresh();
      });
  };

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const saveChanges = (name, selectedItem) => {
    onSimpleFieldChange(name, selectedItem ? selectedItem.value : null); //.value
  };

  const handleOnSelected = (selectedItem) => {
    setSelectedIssuer([selectedItem]);
  };

  // Estados sin campos requeridos para avanzar
  const allow_just_status = [
    bacs_status.CONFECCION_TRAMITE.value,
    bacs_status.PRESENTACION_ENTREGADO.value,
    bacs_status.PRESENTACION_REGISTRO.value,
    bacs_status.RECEPCION_CAT.value,
  ].includes(status_id);

  // En estado "entregado a logística" y con los datos de turno presentación,
  // registro y el gestor responsable
  const allow_presentacion_entregado =
    status_id === bacs_status.ENTREGADO_LOGISTICA.value &&
    !!fields.person_in_charge_id &&
    !!fields.turn_request_date &&
    !!selectedIssuer;

  // En estado "Retiro de Cédula" y con la fecha de recepción de cédula
  const allow_completado =
    allowCompleteStatus.includes(status_id) &&
    !!fields.license_reception_date &&
    !!fields.documentation_link &&
    currentRequirements.length === totalRequirements;

  const allow_submit =
    allow_just_status || allow_presentacion_entregado || allow_completado;

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <BadgeBacsFields fields={fields} />
          <EditBacsField
            fields={fields}
            onSimpleFieldChange={onSimpleFieldChange}
          />
          <div className="row">
            <InputField
              label={`Link del drive`}
              value={fields.documentation_link}
              onChange={(e) =>
                onSimpleFieldChange("documentation_link", e.target.value)
              }
              disabled={readOnly}
              type="url"
            />
            <DatePickerField
              selected={
                fields.purchase_form08_signed
                  ? new Date(fields.purchase_form08_signed)
                  : null
              }
              disabled={true}
              label={"Fecha de firma 08 compra"}
            />
            {showBibliorato.includes(fields.status_id) ? (
              <CheckboxField
                checked={fields.bibliorato}
                onChange={onSimpleFieldChange}
                label={"Bibliorato"}
                name={"bibliorato"}
                disabled={
                  readOnly || fields.status_id === bacs_status.COMPLETADO.value
                }
              />
            ) : null}
          </div>
          {status_id !== bacs_status.CONFECCION_TRAMITE.value ? (
            <div className="row">
              <div className="col-3">
                <DatePickerField
                  selected={
                    fields.turn_request_date
                      ? new Date(fields.turn_request_date)
                      : null
                  }
                  label={"Turno de presentación"}
                  onChange={(date) =>
                    onSimpleFieldChange("turn_request_date", date)
                  }
                  disabled={
                    readOnly ||
                    status_id !== bacs_status.ENTREGADO_LOGISTICA.value
                  }
                />
              </div>
              <div className="col-6">
                <label className="capitalize">
                  <strong>Registro donde se Presenta</strong>
                </label>
                <MultiSelectField
                  name={"issuer_entity_tentative_id"}
                  selectedValues={selectedIssuer}
                  saveChanges={saveChanges}
                  handleOnSelected={handleOnSelected}
                  disable={
                    readOnly ||
                    status_id !== bacs_status.ENTREGADO_LOGISTICA.value
                  }
                />
              </div>
              <div className="col-3">
                <SelectField
                  label={"Gestores"}
                  options={agents()}
                  value={fields.person_in_charge_id}
                  onChange={(e) =>
                    onSimpleFieldChange(
                      "person_in_charge_id",
                      !!e.target.value ? e.target.value : null
                    )
                  }
                  disabled={
                    readOnly ||
                    status_id !== bacs_status.ENTREGADO_LOGISTICA.value
                  }
                />
              </div>
            </div>
          ) : null}
          <BacsDateFields
            fields={fields}
            onChange={onSimpleFieldChange}
            disabled={readOnly || profile === constants.agentInternalProfile}
          />
          <br />
          <Comments
            reasons={reasons}
            comments={comments}
            statusList={bacs_status}
            onChangeSelect={(e) =>
              onSimpleFieldChange("cancel_reason_id", e.target.value)
            }
            onChangeObs={(e) =>
              onSimpleFieldChange("observation", e.target.value)
            }
          />
          {status_id === bacs_status.RETIRO_CEDULA_OBSERVADO.value ? (
            <>
              <CustomAccordion
                title={`Requisitos Pendientes (${totalRequirements}/${currentRequirements.length})`}
              >
                <Stack direction="horizontal" gap={3} className="d-flex flex-wrap">
                  {currentRequirements.map((c) => (
                    <div>
                      <Requirement
                        id={id}
                        data={c}
                        onChange={updateRequirements}
                        serviceName={bacsRequirementsService.updateOne}
                      />
                    </div>
                  ))}
                </Stack>
              </CustomAccordion>
              <div className="row">
                <div className="col">
                  <label className="capitalize">
                    <strong>Requisitos Adicionales</strong>
                  </label>
                  <Multiselect
                    options={bacsRequirements().filter(
                      (r) =>
                        !currentRequirements
                          .map((c) => c.requirement_id)
                          .includes(r.value)
                    )}
                    onSelect={(selectedItem) => {
                      onSimpleFieldChange("requirements", selectedItem);
                    }}
                    onRemove={(selectedItem) => {
                      onSimpleFieldChange("requirements", selectedItem);
                    }}
                    displayValue="text"
                    placeholder="Requisitos"
                    closeIcon="cancel"
                    selectionLimit="-1"
                    showCheckbox={true}
                    singleSelect={false}
                  />
                </div>
              </div>
            </>
          ) : null}
          {observedStatus.includes(status_id) ? (
            <div className="row">
              <div className="col">
                <label className="capitalize">
                  <strong>Requisitos pendientes</strong>
                </label>
                <Multiselect
                  options={bacsRequirements()}
                  onSelect={(selectedItem) => {
                    onSimpleFieldChange("requirements", selectedItem);
                  }}
                  onRemove={(selectedItem) => {
                    onSimpleFieldChange("requirements", selectedItem);
                  }}
                  displayValue="text"
                  placeholder="Requisitos"
                  closeIcon="cancel"
                  selectionLimit="-1"
                  showCheckbox={true}
                  singleSelect={false}
                />
              </div>
            </div>
          ) : null}
        </div>
        <br />
        {!readOnly ? (
          <div className="modal-footer">
            <div>
              <Button
                className="btn-fill pull-right btn btn-primary btn-sm"
                onClick={() => submit(false)}
              >
                Actualizar
              </Button>
            </div>
            {observedStatus.includes(status_id) ? (
              <div>
                <Button
                  className="btn-fill pull-right btn btn-danger btn-sm capitalize"
                  onClick={() =>
                    handleConfirmationModal(
                      bacs_status.RETIRO_CEDULA_OBSERVADO.text,
                      () =>
                        submit(true, bacs_status.RETIRO_CEDULA_OBSERVADO.value)
                    )
                  }
                  disabled={
                    // !fields.observation?.length ||
                    // fields.observation == null ||
                    // fields.cancel_reason_id == null ||
                    !fields.requirements?.length
                  }
                >
                  Observar
                </Button>
              </div>
            ) : null}
            {next_status &&
            next_status?.allowed.includes(profile) &&
            next_status?.value ? (
              <div className="row">
                <Button
                  className="btn-fill pull-right btn btn-warning btn-sm"
                  onClick={() =>
                    handleConfirmationModal(next_status?.text, () =>
                      submit(true, next_status?.value)
                    )
                  }
                  disabled={!allow_submit}
                >
                  {next_status && next_status?.text}
                </Button>
              </div>
            ) : null}
          </div>
        ) : null}
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
      </Form>
    </div>
  );
};

export default Edit;
