import {
  DatePickerField,
  InputField,
  SelectField,
} from "components/Form/index";
import Form from "react-validation/build/form";
import {
  delivery_types,
  yesNoOptions,
  yesOrNotFinancedOptions,
} from "../../../../variables/constants";
import CustomAccordion from "../../../Accordion/index";
import { DomainAndStatus } from "../components/index";
import { CheckboxField } from "components/Form";
import { providerOptions } from "variables/sells";

const CarInfo = ({
  fields,
  onSimpleFieldChange,
  disabledAll = false,
  diff_days = false,
  fieldsEnabled = [],
}) => {
  return (
    <div>
      {!fields.stock_id ? (
        <div className="alert alert-danger" role="alert">
          <p className="h5 my-2 d-flex align-items-center gap-2">
            <i className="fas fa-exclamation-triangle"></i>
            El vehículo no cuenta con stock ID
          </p>
        </div>
      ) : null}
      <CustomAccordion title={"Información del vehículo"} default>
        <Form>
          <div className="row">
            <DomainAndStatus fields={fields} />
          </div>
          <div className="row">
            <SelectField
              label={"Tipo de entrega"}
              name={"type_of_delivery"}
              value={fields.delivery_type}
              disabled={true}
              options={delivery_types}
            />
            <SelectField
              label={"Prendado"}
              options={yesOrNotFinancedOptions}
              onChange={(e) =>
                onSimpleFieldChange(
                  "prendado",
                  !!e.target.value ? e.target.value : null
                )
              }
              value={fields.prendado}
              name={"prendado"}
            />
            <DatePickerField
              selected={fields.estimated_delivery_date}
              disabled={true}
              label={"fecha est. de documentac."}
            />
            <InputField
              label={"Status NS"}
              value={fields.status_ns}
              disabled={true}
              name={"status_ns"}
            />
          </div>
          <div className="row">
            <DatePickerField
              onChange={(date) => onSimpleFieldChange("delivery_date", date)}
              selected={fields.delivery_date}
              disabled={
                fieldsEnabled.includes("delivery_date") ? false : disabledAll
              }
              label={"Fecha de entrega"}
            />
            <DatePickerField
              onChange={(date) => onSimpleFieldChange("payment_date", date)}
              selected={fields.payment_date}
              disabled={!disabledAll ? false : true}
              label={"Fecha de cobro"}
            />
            <SelectField
              label={"Proveedor"}
              options={providerOptions}
              onChange={(e) =>
                onSimpleFieldChange(
                  "provider",
                  !!e.target.value ? e.target.value : null
                )
              }
              value={fields.provider}
              name={"provider"}
            />
            <InputField
              label={"Tipo de Operación"}
              value={fields.transaction_type}
              disabled={true}
              name={"transaction_type"}
            />
            <InputField
              label={"Valor del auto"}
              name={"price"}
              onChange={(e) => onSimpleFieldChange("price", e.target.value)}
              value={fields.price}
              disabled={!disabledAll ? false : true}
            />
          </div>
          <div className="row">
            <SelectField
              disabled={!disabledAll ? false : true}
              label={"Auto trade In"}
              options={yesNoOptions}
              onChange={(e) =>
                onSimpleFieldChange("car_trade_in", e.target.value)
              }
              value={fields.car_trade_in}
              name={"AutoTradeIn"}
            />
            <DatePickerField
              label={"Fin periodo de prueba"}
              disabled={true}
              selected={fields.finish_date_test}
            />
            {diff_days && (
              <InputField
                label={"Días de trámite"}
                name={"diff_days"}
                value={fields.diff_days}
                disabled={true}
              />
            )}
          </div>
        </Form>
      </CustomAccordion>
      <br />
    </div>
  );
};

export default CarInfo;
