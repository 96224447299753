import React, { useState } from "react";
import { Form } from "react-bootstrap";

import PdfViewer from "./PdfViewer";
import CertificateService from "../../../../services/purchase/certificate.service";
import { useToast } from "../../../../hooks";

const VehicleReport = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const addToast = useToast();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const sentCertificate = (content) => {
    CertificateService.insert(props.data.id, content).then((response) => {
      if (response.success) {
        addToast({
          variant: "success",
          header: "Toma - Pantalla General",
          body: "Se actualizó la información correctamente",
        });
        props.handleClose();
        props.refreshGrid();
      } else {
        addToast({
          variant: "danger",
          header: "Toma - Pantalla General",
          body: "No se pudo actualizar correctamente, por favor intente nuevamente.",
        });
      }
    });
  };

  return (
    <div>
      <Form.Group controlId="formFileLg" className="m-3">
        <Form.Label>Seleccione un PDF para procesar</Form.Label>
        <Form.Control
          type="file"
          size="lg"
          onChange={handleFileChange}
          accept="application/pdf"
        />
      </Form.Group>
      {selectedFile && (
        <PdfViewer
          file={selectedFile}
          data={props.data}
          onPress={sentCertificate}
        />
      )}
    </div>
  );
};

export default VehicleReport;
