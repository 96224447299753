import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-validation/build/form";
import { Comments } from "components/Comments";
import ConfirmationAlert from "components/ConfirmationAlert/confirmationAlert";
import { useConfirmationAlert, useToast } from "../../../hooks";
import { processFiles } from "utils/File";
import {
  DatePickerField,
  InputField,
  SelectField,
  UploadFileField,
} from "../../../components/Form/index";
import vtvService from "../../../services/vtv/vtv.service";
import { receptionPlaces } from "../../../utils/Service";
import { vtv_schedule_status } from "../../../variables/vtv_constants";
import { VehicleInfo } from "./components";
import { constants } from "../../../variables/constants";

const SCHEDULE_STATUS = {
  APTO: 1,
  RECHAZADO: 2,
  CONDICIONAL: 3,
  SOLICITADO: 5,
};

const driverProfile = constants.driverProfile;

const showNewShift = [SCHEDULE_STATUS.APTO, SCHEDULE_STATUS.RECHAZADO];

const allowProfiles = [constants.operatorProfile, constants.adminProfile];

const VehicleDates = ({ fields, onSimpleFieldChange, profile }) => {
  return (
    <div>
      <div className="row">
        <DatePickerField
          selected={
            fields.expired_date != null ? new Date(fields.expired_date) : null
          }
          disabled={allowProfiles.includes(profile) ? false : true}
          label={"Fecha de vencimiento"}
        />
        {fields.original_schedule_date ? (
          <DatePickerField
            selected={new Date(fields.original_schedule_date)}
            disabled={true}
            label={"Fecha Primer turno"}
          />
        ) : null}
        <DatePickerField
          selected={
            fields.schedule_date != null ? new Date(fields.schedule_date) : null
          }
          disabled={allowProfiles.includes(profile) ? false : true}
          name={"schedule_date"}
          onChange={(date) => onSimpleFieldChange("schedule_date", date)}
          label={"Fecha turno VTV"}
        />
        <DatePickerField
          selected={
            fields.schedule_real_date != null
              ? new Date(fields.schedule_real_date)
              : null
          }
          disabled={true}
          label={"Fecha turno real"}
        />
      </div>
    </div>
  );
};

const CreateScheduleButton = ({ submit, currentPermissions }) =>
  currentPermissions && currentPermissions.permissions.hasCreate ? (
    <div className="row">
      <Button
        className="btn-fill pull-right btn-success btn-primary btn-sm"
        onClick={submit}
      >
        Crear turno
      </Button>
    </div>
  ) : null;

const Edit = (props) => {
  const {
    data: {
      response: {
        schedule_id,
        plate_number,
        vehicle_id,
        status_sell,
        expired_date,
        vtv_reception_place_id,
        delivery_place_id,
        schedule_date,
        schedule_status_id,
        status_vtv,
        id,
        comments,
        schedule_real_date,
        original_schedule_date,
      },
    },
    profile,
    currentPermissions,
    handleClose,
    reasons,
    refresh,
  } = props;

  // Objeto con todos los campos editables del popup
  const initialFormState = {
    schedule_id,
    vehicle_id,
    domain: plate_number,
    comment_reason_id: null,
    observation: null,
    images: [],
    schedule_date,
    expired_date,
    vtv_reception_place_id,
    delivery_place_id,
    schedule_status: schedule_status_id,
    status_vtv,
    status_sell,
    vtv_id: id,
    schedule_real_date,
    original_schedule_date,
  };

  const [fields, setFields] = useState(initialFormState);
  const [files, setFiles] = useState(null);
  const [VtvReceptionPlace, setVtvReceptionPlace] = useState([]);
  const { confirmationModal, handleConfirmationModal } = useConfirmationAlert();
  const addToast = useToast();

  const getAllVtvReceptionsPlaces = async () => {
    const response = await vtvService.vtvReceptionPlaceGetAll();
    if (response.status === 200) {
      setVtvReceptionPlace(response.data.response);
    }
  };

  useEffect(() => {
    getAllVtvReceptionsPlaces();
    if (!schedule_id) {
      onSimpleFieldChange("schedule_status", SCHEDULE_STATUS.SOLICITADO);
    }
    // eslint-disable-next-line
  }, []);

  const handleFiles = async (e) => {
    const newImages = await processFiles(e.target.files, "");
    setFiles({
      images: [...newImages],
    });
  };

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue != null ? fieldValue : null,
    });
  };

  const handlerCreateSchedule = () => {
    cleaningValues();
    handleConfirmationModal(
      plate_number,
      createSchedule,
      "¿Desea crear un nuevo turno?",
      "Al confirmar, se creara un nuevo turno para la patente: "
    );
  };

  const cleaningValues = () => {
    setFields(initialFormState);
  };

  const createSchedule = () => {
    const promises = [];

    const isCreating = true;

    const promise_form = updateOrCreatePromise(isCreating);

    promises.push(promise_form);

    Promise.all(promises)
      .then((values) => {
        addToast({
          variant: "success",
          header: "VTV",
          body: "Se ha creado el turno",
        });
        refresh();
        handleClose();
      })
      .catch((reason) => {
        addToast({
          variant: "danger",
          header: "VTV",
          body: reason,
        });
      });
  };

  const validateAndSubmit = () => {
    if (
      fields.delivery_place_id &&
      fields.schedule_date &&
      fields.vtv_reception_place_id
    ) {
      submit();
    } else {
      addToast({
        variant: "danger",
        header: "VTV",
        body: "Todos los campos requeridos deben estar completos",
      });
    }
  };

  const updateOrCreatePromise = (isCreating = false) => {
    if (isCreating) {
      delete fields.schedule_id;
    }

    return new Promise((resolve, reject) => {
      vtvService.update(fields).then((response) => {
        if (response.status === 200) {
          resolve("Se actualizaron los datos");
        }
        reject("Fallo la actualizacion de los datos");
      });
    });
  };

  const savePromise = () => {
    const images = {
      schedule_id: fields.id,
      images: [...files.images],
    };

    return new Promise((resolve, reject) => {
      vtvService.save(images).then((response) => {
        if (response.success) {
          resolve("Se guardaron los documentos");
        }
        reject("Fallo el guardado de documentos");
      });
    });
  };

  const submit = async () => {
    const promises = [];

    if (files && files.images.length > 0) {
      const promise_images = savePromise();
      promises.push(promise_images);
    }

    const promise_form = updateOrCreatePromise();

    promises.push(promise_form);

    Promise.all(promises)
      .then((values) => {
        addToast({
          variant: "success",
          header: "VTV",
          body: "Se actualizaron los datos correctamente",
        });
        refresh();
        handleClose();
      })
      .catch((reason) => {
        addToast({
          variant: "danger",
          header: "VTV",
          body: reason,
        });
      });
  };

  return (
    <div className="modalChecklist">
      <Form>
        <div>
          <div className="container">
            <VehicleInfo fields={fields} />
            <div className="row">
              <InputField
                label={"Status venta"}
                name={"status_sell"}
                disabled={true}
                value={fields.status_sell}
              />
              <SelectField
                label={"Ubicación"}
                name={"delivery_place_id"}
                value={fields.delivery_place_id}
                onChange={(e) =>
                  onSimpleFieldChange(
                    "delivery_place_id",
                    !!e.target.value ? e.target.value : null
                  )
                }
                options={receptionPlaces()}
                disabled={
                  schedule_real_date || !allowProfiles.includes(profile) ? true : false
                }
              />
            </div>
            <div>
              <VehicleDates
                fields={fields}
                onSimpleFieldChange={onSimpleFieldChange}
                profile={profile}
              />
            </div>
            <div className="row">
              <SelectField
                label={"Estado"}
                name={"schedule_status"}
                onChange={(e) =>
                  onSimpleFieldChange(
                    "schedule_status",
                    !!e.target.value ? e.target.value : null
                  )
                }
                value={fields.schedule_status}
                options={vtv_schedule_status}
                disabled={schedule_real_date != null ? true : false}
              />
              <SelectField
                label={"Planta VTV"}
                name={"vtv_reception_place_id"}
                value={fields.vtv_reception_place_id}
                onChange={(e) =>
                  onSimpleFieldChange(
                    "vtv_reception_place_id",
                    !!e.target.value ? e.target.value : null
                  )
                }
                options={VtvReceptionPlace}
                disabled={
                  schedule_real_date || !allowProfiles.includes(profile) ? true : false
                }
              />
              <UploadFileField
                accept={".jpg"}
                handleFiles={handleFiles}
                title={"Comprobante VTV"}
              />
            </div>
          </div>
          <br />
          <Comments
            generic
            reasons={reasons}
            comments={comments}
            onChangeSelect={(e) =>
              onSimpleFieldChange("comment_reason_id", e.target.value)
            }
            onChangeObs={(e) =>
              onSimpleFieldChange("observation", e.target.value)
            }
          />
        </div>
        <br />
        <div className="modal-footer">
          {fields.schedule_id ? (
            <>
              <div>
                <Button
                  className="btn-fill pull-right btn btn-primary btn-sm"
                  onClick={submit}
                  disabled={showNewShift.includes(
                    parseInt(fields.schedule_status)
                  )}
                >
                  Actualizar
                </Button>
              </div>
              {!showNewShift.includes(parseInt(schedule_status_id)) ? (
                <div className="row">
                  <Button
                    className="btn-fill pull-right btn btn-primary btn-sm"
                    onClick={validateAndSubmit}
                    disabled={
                      !showNewShift.includes(parseInt(fields.schedule_status))
                    }
                  >
                    Finalizar turno
                  </Button>
                </div>
              ) : (
                <CreateScheduleButton
                  submit={handlerCreateSchedule}
                  currentPermissions={currentPermissions}
                />
              )}
            </>
          ) : (
            <CreateScheduleButton
              submit={handlerCreateSchedule}
              currentPermissions={currentPermissions}
            />
          )}
        </div>
      </Form>
      {confirmationModal.open && (
        <ConfirmationAlert
          show={confirmationModal.open}
          handleAction={confirmationModal.handleAction}
          handleClose={confirmationModal.handleClose}
          message={confirmationModal.message}
          title={confirmationModal.title}
        />
      )}
    </div>
  );
};

export default Edit;
