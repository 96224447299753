import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import supercluster from "points-cluster";
import Marker from "../Marker";
import ClusterMarker from "../ClusterMarker";
import mapStyles from "./mapStyles.json";
import { googleMaps } from "../../../variables/constants";

const GoogleMap = (props) => {
  const { markersData, callback } = props;

  const MAP = {
    defaultZoom: googleMaps.defaultZoom,
    defaultCenter: {
      lat: googleMaps.defaultLatitude,
      lng: googleMaps.defaultLongitude,
    },
    options: {
      styles: mapStyles,
      maxZoom: googleMaps.defaultMaxZoom,
    },
  };

  const [mapOptions, setMapOptions] = useState({
    center: MAP.defaultCenter,
    zoom: MAP.defaultZoom,
  });
  const [clusters, setClusters] = useState([]);
  const [pinSelected, setPinSelected] = useState(null);

  useEffect(() => {
    const getClusters = () => {
      const data = supercluster(markersData, {
        minZoom: 0,
        maxZoom: mapOptions.zoom,
        radius: 60,
      });
      return data(mapOptions);
    };

    const createClusters = (props) => {
      const c = mapOptions.bounds
        ? getClusters(props).map(({ wx, wy, numPoints, points }) => ({
            lat: wy,
            lng: wx,
            numPoints,
            id: `${numPoints}_${points[0].id}`,
            points,
          }))
        : [];

      setClusters(c);
    };

    createClusters({});
  }, [mapOptions, markersData]);

  useEffect(() => {
    setMapOptions(callback);
    setPinSelected(callback.pinSelected);
  }, [callback]);

  const handleMapChange = ({ center, zoom, bounds }) => {
    setMapOptions({ center, zoom, bounds });
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <GoogleMapReact
        zoom={mapOptions.zoom}
        center={mapOptions.center}
        options={MAP.options}
        onChange={handleMapChange}
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
      >
        {clusters.map((item) => {
          if (item.numPoints === 1) {
            return (
              <Marker
                key={item.id}
                issuer_entity_id={item.points[0].issuer_entity_id}
                lat={item.points[0].lat}
                lng={item.points[0].lng}
                description={item.points[0].description}
                address_complete={item.points[0].address_complete}
                city_province={item.points[0].city_province}
                phone={item.points[0].phone}
                pinSelected={pinSelected}
              />
            );
          }

          return (
            <ClusterMarker
              key={item.id}
              lat={item.lat}
              lng={item.lng}
              description={item.description}
              address={item.address}
              phone={item.phone}
              points={item.points}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMap;
