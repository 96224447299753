import React from "react";
import Form from "react-validation/build/form";
import Select from "react-validation/build/select";
import { constants } from "../../variables/constants";

const Dropdown = ({
  className,
  name,
  onSelect,
  selected,
  placeholder,
  options,
  ...rest
}) => (
  <Form>
    <Select
      className={className}
      name={name}
      onChange={onSelect}
      value={selected}
      {...rest}
    >
      <option value={constants.dropdownDefault} className="capitalize">
        {placeholder}
      </option>
      {options.map((o) => (
        <option
          key={`${name}-${o.value}`}
          value={o.value}
          className="capitalize"
        >
          {o.text}
        </option>
      ))}
    </Select>
  </Form>
);

export default Dropdown;
