import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

class ClusterMarker extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  state = {
    clusterFaceMarkers: this.props.points.slice(0, 2)
  };

  render() {
    return (
      <div length={this.props.points.length}>
        <div className="google-cluster-point">          
            {this.props.points.length}
          </div>
      </div>
    );
  }
}

ClusterMarker.propTypes = {
  points: PropTypes.array,
  users: PropTypes.instanceOf(List),
  selected: PropTypes.bool,
};

export default ClusterMarker;