import React, { useEffect, useState } from "react";
import Form from "react-validation/build/form";
import Textarea from "react-validation/build/textarea";
import { Button } from "react-bootstrap";
import Dropdown from "../../../components/Table/Dropdown";
import { constants, purchaseStatus } from "../../../variables/constants";
import { useConfirmationAlert } from "../../../hooks/index";
import ConfirmationAlert from "components/ConfirmationAlert/confirmationAlert";

const Content = (props) => {
  const {
    data: { id },
    handleDelete,
    handleClose,
  } = props;

  const initialFormState = {
    vehicle_id: id,
    rejected_reason: null,
    comment: "",
  };

  const rejectOptions = [
    { value: "Inhibido", text: "Inhibido" },
    { value: "Embargado", text: "Embargado" },
    { value: "Prendado sin Prenda", text: "Prendado sin Prenda" },
    { value: "Otros", text: "Otros" },
  ];

  const [submitAction, setSubmitAction] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [fields, setFields] = useState(initialFormState);

  const { confirmationModal, handlePreConfirmationPurchaseModal } =
    useConfirmationAlert();

  // Al presionar un botón
  useEffect(() => {
    if (submitAction) {
      handleDelete(fields);
      setSubmitAction(false);
      handleClose();
    }
    setDisabledSubmit(
      fields.rejected_reason === null ||
        (fields.rejected_reason === "Otros" && !fields.comment.length)
    );
  }, [submitAction, fields, handleDelete, handleClose]);

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  return (
    <div className="modalChecklist">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>¿Esta seguro que desea rechazar el tramite?</h2>
            <p>Por favor seleccione el motivo del rechazo.</p>
          </div>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="row">
          <div className="col">
            <label className="capitalize">
              <strong>Motivo de rechazo</strong>
            </label>
            <div>
              <Dropdown
                className="form-control"
                name="rejected_reason"
                placeholder="Motivo de rechazo"
                onSelect={(e) =>
                  onSimpleFieldChange(
                    "rejected_reason",
                    e.target.value === constants.dropdownDefault
                      ? null
                      : e.target.value
                  )
                }
                options={rejectOptions}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="capitalize">
              <strong>{constants.comment}</strong>
            </label>
            <div>
              <Form>
                <Textarea
                  className="form-control"
                  rows={3}
                  name="comment"
                  placeholder="Ingrese un comentario"
                  disabled={fields.rejected_reason !== "Otros"}
                  onChange={(e) =>
                    onSimpleFieldChange("comment", e.target.value)
                  }
                />
              </Form>
            </div>
          </div>
        </div>
        <br />
      </div>

      <div className="modal-footer">
        <Button
          className="btn-fill pull-right btn btn-primary btn-sm capitalize"
          onClick={() => {
            handleClose();
          }}
        >
          {constants.close}
        </Button>
        <Button
          className="btn-fill pull-right btn btn-danger btn-sm capitalize"
          value={purchaseStatus.REJECTED.value}
          disabled={disabledSubmit}
          onClick={(e) => {
            handlePreConfirmationPurchaseModal(
              "current_status",
              purchaseStatus.REJECTED.value,
              purchaseStatus.REJECTED.text,
              () => setSubmitAction(true)
            );
          }}
        >
          {constants.accept}
        </Button>
      </div>
      {confirmationModal.open && (
        <ConfirmationAlert
          show={confirmationModal.open}
          handleAction={confirmationModal.handleAction}
          handleClose={confirmationModal.handleClose}
          message={confirmationModal.message}
          title={confirmationModal.title}
        />
      )}
    </div>
  );
};

export default Content;
