import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getList = async (data) => {
  const url = `${API_URL}/folio/list`;
  return await axios.post(url,{...data},
    { headers: authHeader() }
  );
}

const getFolioById = async (id) => {
  return await axios.get(`${API_URL}/folio/${id}`, { headers: authHeader() });
};


export default {
  getList,
  getFolioById,
};
