import React, { useEffect, useState } from "react";
import GenericReject from "../GenericReject/reject";
import SellsService from "../../../services/sells/sells.service";
import { useToast } from "../../../hooks";

const STATUS_SELLS_REJECTED = 14;

const initalFormState = {
  status_id: STATUS_SELLS_REJECTED,
  cancel_reason_id: "",
  cancel_reason: "",
};

const RejectSell = (props) => {
  const [reasons, setReasons] = useState([]);
  const [fields, setFields] = useState(initalFormState);
  const addToast = useToast();
  const {
    handleClose,
    refresh,
    id,
    getCancelReasons = () => SellsService.getCancelReason(),
  } = props;

  const getAllCancelReasons = async () => {
    try {
      const response = await getCancelReasons();
      if (response.status === 200) {
        setReasons(response.data.response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submit = async () => {
    const data = {
      status_id: fields.status_id,
      cancel_reason_id: fields.cancel_reason_id,
      cancel_reason: fields.cancel_reason,
    };

    const response = await SellsService.updateCancelReason(id, data);
    if (response.status === 200) {
      addToast({
        variant: "success",
        header: "Ventas - Rechazo",
        body: "Se rechazo correctamente",
      });
      handleClose();
      refresh();
    }
  };

  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  useEffect(() => {
    getAllCancelReasons();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {reasons.length > 0 && (
        <GenericReject
          reasons={reasons}
          handleOnChange={onSimpleFieldChange}
          submit={submit}
          fields={fields}
          {...props}
        />
      )}
    </div>
  );
};

export default RejectSell;
