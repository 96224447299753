import React, { useState } from "react";
import { Container, Button } from "react-bootstrap";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Information from "./information";
import Penalties from "./penalties";
import ExtraData from "./extraData";
import PlateService from "../../services/plate.service";
import Energas from "./energas";

const PlateValidate = () => {  
  const [info, setInfo] = useState(false);
  const [plate, setPlate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [penalties, setPenalties] = useState(false);
  const [extraData, setExtraData] = useState(false);
  const [energas, setEnergas] = useState(false);

  const onChangePlate = (e) => {
    setPlate(e.target.value);
  }

  const handleValidate = () => {    
    setLoading(true);
    const params = { plate_number: plate };
    PlateService.getPlateData(params).then(response => {
      if (response.success) {
        setInfo(response.response.info);
        setPenalties(response.response.penalties);
        setExtraData(response.response.extraData);
        setEnergas(response.response.energas);
        setLoading(false);
      }
    });
  }

  return (
    <div className="content content-plate-validation">
      <Container fluid>
        <Form>
          <div className="form-group content-search-input mb-3">      
            <div>
              <Input
                  type="text"
                  className="form-control"
                  name="plate"
                  placeholder="Patente"
                  autoComplete="false"
                  onChange={onChangePlate}
              />
            </div>
            <div>
              <Button onClick={handleValidate} className="btn-fill pull-right btn btn-primary btn-sm">
                  Validar
              </Button> 
            </div>
          </div>
        </Form>
        <div className="content-information">
          <Information data={info} loading={loading} />
          <div className="plate-penalties">
            <ExtraData data={extraData} loading={loading} />
            <Penalties data={penalties} loading={loading} />
            <Energas data={energas} loading={loading} />
          </div>          
        </div>        
      </Container>
    </div>
  );
};

export default PlateValidate;
