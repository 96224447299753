import Select from "react-validation/build/select";
import Form from "react-validation/build/form";
import React from "react";

const SelectField = ({ label, name, onChange, value, disabled, options }) => {
  return (
    <Form>
      <div className="col">
        <label className="capitalize">
          <strong>{label}</strong>
        </label>
        <Select
          className="form-control"
          name={name}
          onChange={onChange}
          value={value}
          disabled={disabled}
        >
          <option value="">Seleccione</option>
          {options &&
            options.map((f) => (
              <option
                key={`${f.value}-${f.text}`}
                value={f.value}
                className="capitalize"
              >
                {f.text}
              </option>
            ))}
        </Select>
      </div>
    </Form>
  );
};

export default SelectField;
