import axios from "axios";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import excelService from "../services/excel.service";

export const downloadAllZip = (data, name) => {
  const zipFilename = `${name}.zip`;
  const buffer = Buffer.from(data.zipFile);
  const blob = new Blob([buffer]);
  saveAs(blob, zipFilename);
};

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const processFiles = async (files, processName = null) => {
  const images = [];
  if (files && files.length > 0) {
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const base64 = await convertBase64(file);
      // url: URL.createObjectURL(file),
      const image = {
        name: `${Date.now()}_${file.name}`,
        size: file.size,
        type: file.type,
        base64,
        processName: processName ? processName : "",
      };

      images.push(image);
    }
  }
  return images;
};

export const linkExternal = (link, icon = "fas fa-external-link-alt") =>
  !!link ? (
    <a href={link} target="_blank" className="text-warning">
      <i className={icon}></i>
    </a>
  ) : (
    ""
  );

export const excelDownload = async (type, filters) => {
  return excelService
    .build(type, { filters })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${type}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .finally(() => {
      return true;
    })
    .catch((error) => {
      console.error("An error occurred:", error);
      throw new Error(error);
    });
};

export default {};
