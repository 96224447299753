import React from "react";
import DatePicker from "react-datepicker";

const DatePickerField = ({
  label,
  onChange,
  selected,
  dateFormat = "dd/MM/yyyy",
  disabled = false,
  maxDate = null,
  minDate = null,
  colClassName = "col",
  placeholderText = "",
}) => {
  return (
    <div className={colClassName}>
      {label ? (
        <label className="capitalize">
          <strong>{label}</strong>
        </label>
      ) : null}
      <span>
        <DatePicker
          className="form-control"
          selected={selected}
          onChange={onChange}
          dateFormat={dateFormat}
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
          placeholderText={placeholderText}
        />
      </span>
    </div>
  );
};

export default DatePickerField;
