import React, { useState, useEffect, useRef } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Select from "react-validation/build/select";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Card } from "../../components/Card/Card.jsx";
import Dropdown from "../../components/Table/Dropdown.js";
import IssuerEntity from "../../services/issuerEntity.service";
import VehicleService from "../../services/vehicle.service";
import ReceptionPlaceService from "../../services/receptionPlace.service";
import {
  constants,
  purchaseStatus,
  yesNoOptions,
} from "../../variables/constants";
import {
  issuerEntitiesAll,
  updateLocalStorageCache,
} from "../../utils/Service.js";
import { useToast } from "../../hooks";

const required = (value) => {
  if (!value || value === constants.dropdownDefault) {
    return (
      <div className="alert alert-danger" role="alert">
        El campo es obligatorio
      </div>
    );
  }
};

const positiveNumber = (value) => {
  if (value <= 0) {
    return (
      <div className="alert alert-danger" role="alert">
        El número debe ser mayor a 0 (cero).
      </div>
    );
  }
};

const inpuLength = (value) => {
  const min = 6;
  const max = 6;
  if (value.length < min) {
    return (
      <div className="alert alert-danger" role="alert">
        {`El número debe tener al menos ${min} dígitos.`}
      </div>
    );
  }
  if (value.length > max) {
    return (
      <div className="alert alert-danger" role="alert">
        {`El número debe tener menos de ${max} dígitos.`}
      </div>
    );
  }
};

// Estado inicial del formulario - Todos los campos
const initialState = {
  plate_number: "",
  purchase_file_status: null,
  price: null,
  issuer_entity_id: null,
  management_type_id: null,
  reception_place_id: null,
  sdp: false,
  // inhibited: false,
  pledge: constants.dropdownDefault,
  // judicial_measures: false,
  comment: "",
  pronto_forms: {
    inspector: "",
    has_form_08: false,
    has_form_03: false,
    has_vpa: false,
    has_green_licence: false,
    has_purchase_form: false,
  },
};

const dropdownStatus = Object.entries(purchaseStatus)
  .filter((s) => s[1].group === "take")
  .map((s) => s[1]);

const VehicleCreate = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState(initialState);
  const [vehicleId, setVehicleId] = useState(null);

  const form = useRef();
  const checkBtn = useRef();
  const vehicleIdInput = useRef();
  const addToast = useToast();

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  // Manejo generico del cambio de un dato complejo de un formulario, como ser un objeto
  const onComplexFieldChange = (primaryKey, valueKey, value) => {
    const { [primaryKey]: item } = fields;
    const field = { [primaryKey]: { ...item, [valueKey]: value } };
    setFields({
      ...fields,
      ...field,
    });
  };

  // Selector de emisor
  const onSelect = (selectedList, selectedItem) => {
    IssuerEntity.getById(selectedItem.value).then((response) => {
      if (response.data.success) {
        setFields({
          ...fields,
          issuer_entity_id: selectedItem.value,
          management_type_id: response.data.data.management_type_id,
          issuer_entity_tentative_id:
            response.data.data.issuer_entity_tentative_id,
        });
      }
    });
  };

  // Eliminación del emisor
  const onRemove = () => {
    setFields({
      ...fields,
      issuer_entity_id: null,
      management_type_id: null,
      issuer_entity_tentative_id: null,
    });
  };

  // Submit del formulario
  const handSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      VehicleService.create(fields).then(
        () => {
          addToast({
            variant: "success",
            header: "Toma - Creación",
            body: "Se ha creado el nuevo vehículo correctamente",
          });
          // Definir a donde va una vez es exitoso el guardado
          updateLocalStorageCache().then(() => {
            navigate("/admin/management/toma-vehiculo");
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          addToast({
            variant: "danger",
            header: "Toma - Creación",
            body: resMessage,
          });
        }
      );
    } else {
      setLoading(false);
    }
  };

  const [placesOptions, setPlacesOptions] = useState([]);

  useEffect(() => {
    ReceptionPlaceService.getAll({ sortField: "name", sortType: "ASC" }).then(
      (response) => {
        if (response.data.count > 0) {
          setPlacesOptions(
            response.data.response.map((r) => ({ value: r.id, text: r.name }))
          );
        }
      }
    );
  }, []);

  useEffect(() => {
    if (vehicleId) {
      form.current.hideError(vehicleIdInput.current);
      VehicleService.getVehicleById(vehicleId)
        .then((response) => {
          if (response.data.success) {
            form.current.showError(
              vehicleIdInput.current,
              <div className="alert alert-danger" role="alert">
                ID no disponible.
              </div>
            );
          }
        })
        .catch((error) => {
          form.current.showError(
            vehicleIdInput.current,
            <div className="alert alert-success" role="alert">
              ID disponible.
            </div>
          );
        });
    }
  }, [vehicleId]);

  return (
    <div className="content">
      <Container fluid>
        <Button
          href="/admin/management/toma-vehiculo"
          className="btn-fill pull-left btn btn-info btn-sm"
        >
          Volver
        </Button>
        <br />
        <br />
        <Row>
          <Col md={12}>
            <Card
              content={
                <Form onSubmit={handSubmit} ref={form}>
                  <div className="row form-group">
                    <div className="col-sm-4">
                      <label htmlFor="vehicle_id">ID</label>
                      <Input
                        type="number"
                        className="form-control"
                        name="vehicle_id"
                        placeholder="ID del vehículo"
                        onChange={(e) => {
                          onSimpleFieldChange("vehicle_id", e.target.value);
                          setVehicleId(e.target.value);
                        }}
                        validations={[required, positiveNumber, inpuLength]}
                        ref={vehicleIdInput}
                        min="1"
                        step="1"
                      />
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="plate_number">
                        {constants.plateNumber}
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        name="plate_number"
                        placeholder={constants.plateNumber}
                        onChange={(e) =>
                          onSimpleFieldChange("plate_number", e.target.value)
                        }
                        validations={[required]}
                        style={{ textTransform: "uppercase" }}
                      />
                    </div>

                    <div className="col-sm-2">
                      <label htmlFor="sdp">{constants.sdp}</label>
                      <Dropdown
                        className="form-control"
                        name="sdp"
                        placeholder="Condición"
                        options={yesNoOptions}
                        onSelect={(e) =>
                          onSimpleFieldChange("sdp", e.target.value === "si")
                        }
                      />
                    </div>
                    <div className="col-sm-2">
                      <label htmlFor="pledge">{constants.pledge}</label>
                      <Select
                        className="form-control"
                        name="pledge"
                        onChange={(e) =>
                          onSimpleFieldChange(
                            "pledge",
                            e.target.value === constants.dropdownDefault
                              ? ""
                              : e.target.value?.toUpperCase()
                          )
                        }
                        validations={[required]}
                      >
                        <option
                          value={constants.dropdownDefault}
                          className="capitalize"
                        >
                          Condición
                        </option>
                        {yesNoOptions.map((o) => (
                          <option
                            key={o.value}
                            value={o.value}
                            className="capitalize"
                          >
                            {o.text}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-sm-4">
                      <label htmlFor="purchase_file_status">
                        {constants.status}
                      </label>
                      <Dropdown
                        className="form-control"
                        name="purchase_file_status"
                        placeholder="Seleccione un estado"
                        options={dropdownStatus}
                        onSelect={(e) =>
                          onSimpleFieldChange(
                            "purchase_file_status",
                            e.target.value
                          )
                        }
                      />
                    </div>

                    <div className="col-sm-4">
                      <label htmlFor="price">{constants.price}</label>
                      <Input
                        type="number"
                        step="0.01"
                        className="form-control"
                        name="price"
                        placeholder={constants.price}
                        onChange={(e) =>
                          onSimpleFieldChange(
                            "price",
                            parseFloat(e.target.value).toFixed(2)
                          )
                        }
                      />
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="inspector">{constants.inspector}</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="inspector"
                        placeholder={constants.inspector}
                        onChange={(e) =>
                          onComplexFieldChange(
                            "pronto_forms",
                            "inspector",
                            e.target.value
                          )
                        }
                        // validations={[required]}
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-sm-4">
                      <label className="capitalize">
                        {constants.receptionPlace}
                      </label>
                      <div>
                        <span>
                          <Dropdown
                            className="form-control"
                            name="reception_place_id"
                            placeholder={constants.receptionPlace}
                            onSelect={(e) =>
                              onSimpleFieldChange(
                                "reception_place_id",
                                e.target.value === constants.dropdownDefault
                                  ? null
                                  : e.target.value
                              )
                            }
                            options={placesOptions}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="issuer_entity_id">
                        {constants.issuerEntity}
                      </label>
                      <Multiselect
                        name="issuer_entity_id"
                        options={issuerEntitiesAll()}
                        onSelect={onSelect}
                        onRemove={onRemove}
                        displayValue="text"
                        placeholder="Seleccione un registro"
                        closeIcon="cancel"
                        selectionLimit="1"
                        showCheckbox={false}
                        singleSelect={false}
                      />
                    </div>

                    <div className="col-sm-4">
                      <label htmlFor="comment">{constants.comment}</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="comment"
                        placeholder={constants.comment}
                        onChange={(e) =>
                          onSimpleFieldChange("comment", e.target.value)
                        }
                        // validations={[required]}
                      />
                    </div>
                  </div>

                  {/* <div className="row form-group">
                    <div className="col-sm-2">
                      <label htmlFor="inhibited">{constants.inhibited}</label>
                      <input
                        className="formCheckbox"
                        type="checkbox"
                        name="inhibited"
                        checked={fields.inhibited}
                        onChange={e => onSimpleFieldChange('inhibited', e.target.checked)}
                      />
                    </div>


                    <div className="col-sm-2">
                      <label htmlFor="judicial_measures">{constants.judicial_measures}</label>
                      <input
                        className="formCheckbox"
                        type="checkbox"
                        name="judicial_measures"
                        checked={fields.judicial_measures}
                        onChange={e => onSimpleFieldChange('judicial_measures', e.target.checked)}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="purchase_forms">{constants.purchase_forms}</label>
                  </div>
                  <div className="row form-group">
                    {Object.entries(fields.purchase_forms).map(f => (
                      <div className="col-sm-2" key={f[0]}>
                        <label htmlFor={f[0]}>{f[0]}</label>
                        <input
                          className="formCheckbox"
                          type="checkbox"
                          name={f[0]}
                          checked={f[1]}
                          onChange={e => onComplexFieldChange('purchase_forms', f[0], e.target.checked)}
                        />
                      </div>
                    ))}
                  </div> */}
                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-fill pull-right capitalize"
                      disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Crear {constants.vehicle}</span>
                    </button>
                  </div>

                  <CheckButton style={{ display: "none" }} ref={checkBtn} />

                  <div className="clearfix" />
                </Form>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default VehicleCreate;
