import React, { useEffect, useState } from "react";
import Form from "react-validation/build/form";
import Select from "react-validation/build/select";

import { constants } from "../../../variables/constants";
import { printForms, receptionPlaces } from "../../../utils/Service";

const FormOptions = (props) => {
  return (
    <Form>
      <div className="container">
        <h2 className="text-danger">Por favor complete todos los campos</h2>
        <div className="row">
          <div className="col">
            <label className="capitalize">
              <strong>{constants.receptionPlace}</strong>
            </label>
            <Select
              className="form-control"
              name="notary_office_id"
              onChange={(e) =>
                props.onSimpleFieldChange(
                  "reception_place_id",
                  parseInt(e.target.value, 10)
                )
              }
            >
              <option value="">Seleccione</option>
              {receptionPlaces().map((place, key) => (
                <option key={key} value={place.value}>
                  {place.text}
                </option>
              ))}
            </Select>
          </div>
          <div className="col">
            <label className="capitalize">
              <strong>Formularios para imprimir</strong>
            </label>
            <Select
              className="form-control"
              name="notary_office_id"
              onChange={(e) =>
                props.onSimpleFieldChange("form", parseInt(e.target.value, 10))
              }
            >
              <option value="">Seleccione</option>
              {printForms().map((form, key) => {
                return (
                  <option key={key} value={form.value}>
                    {`Formulario ${form.text}`}
                  </option>
                );
              })}
            </Select>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default FormOptions;
