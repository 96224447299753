import React, { useEffect, useState } from "react";
import Form from "react-validation/build/form";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Multiselect } from "multiselect-react-dropdown";

import ConfirmationAlert from "../../ConfirmationAlert/confirmationAlert";
import { Comments } from "../../Comments";
import { BadgeField } from "../Sells/components";
import AuditService from "../../../services/audit.service";
import ManagementService from "../../../services/management.service";
import { diffDateFormula, plateNumberBadge } from "../../../utils/Badges";
import { purchaseRequirements } from "../../../utils/Service";
import { purchaseComment } from "../../../utils/Comments";
import { constants, purchaseStatus } from "../../../variables/constants";
import { useConfirmationAlert } from "../../../hooks";

const Observation = (props) => {
  const {
    data: {
      response: {
        vehicle_id,
        plate_number,
        status,
        current_location,
        loss_request,
        estimated_date,
        diff_days,
        diff_days_presentation,
      },
    },
    profile,
    handleUpdate,
    handleClose,
  } = props;

  // Objeto con todos los campos editables del popup
  const initialFormState = {
    vehicle_id,
    plate_number,
    purchase_file_status: purchaseStatus.PRESENTATION_OBSERVED.value,
    comment_reason_id: null,
    comment: null,
    images: [],
  };

  const [submitAction, setSubmitAction] = useState(false);
  const [fields, setFields] = useState(initialFormState);
  const [reasons, setReasons] = useState([]);
  const [comments, setComments] = useState([]);

  const { confirmationModal, handlePreConfirmationPurchaseModal } =
    useConfirmationAlert();

  useEffect(() => {
    AuditService.getHistory(vehicle_id, "comment_summary").then((response) => {
      setComments(response.data.response);
    });
    ManagementService.getCommentReasons().then((response) => {
      if (response.data.success) {
        setReasons(
          response.data.response.filter((r) => !r.text.includes("[SUSPENDER]"))
        );
      }
    });
    // eslint-disable-next-line
  }, []);

  // Al presionar un botón
  useEffect(() => {
    if (submitAction) {
      handleUpdate(fields);
      setSubmitAction(false);
      handleClose();
    }
  }, [submitAction, fields, status, handleUpdate, handleClose]);

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const handleChangeStatus = (status_name, value) => {
    onSimpleFieldChange(status_name, value);
    setSubmitAction(true);
  };

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <div className="row">
            <BadgeField
              label={constants.domain}
              text={plate_number}
              badgeClass={"plate_number"}
              variant={"warning"}
            />
            <BadgeField
              label={"Días en tramite"}
              text={diff_days ?? 0}
              badgeClass={`capitalize plate_number`}
              variant={diffDateFormula(diff_days)}
            />
            <div className="col">
              <label className="capitalize">
                <strong>{constants.estimatedDate}</strong>
              </label>
              <div>
                <DatePicker
                  selected={
                    estimated_date === null ? null : new Date(estimated_date)
                  }
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="capitalize">
                <strong>registro radicación</strong>
              </label>
              <div>{current_location}</div>
            </div>
            <BadgeField
              label={"Días en logístico"}
              text={diff_days_presentation}
              badgeClass={`capitalize plate_number`}
              variant={"info"}
            />
            <div className="col">
              <label className="capitalize">
                <strong>{constants.lossRequest}</strong>
              </label>
              <div>{fields.loss_request}</div>
            </div>
          </div>

          <Comments
            reasons={reasons}
            comments={purchaseComment(comments)}
            statusList={purchaseStatus}
            onChangeSelect={(e) =>
              onSimpleFieldChange("comment_reason_id", e.target.value)
            }
            onChangeObs={(e) => onSimpleFieldChange("comment", e.target.value)}
          />
          <div className="row">
            <div className="col">
              <label className="capitalize">
                <strong>Requisitos pendientes</strong>
              </label>
              <Multiselect
                options={purchaseRequirements()}
                onSelect={(selectedItem) => {
                  onSimpleFieldChange("requirements", selectedItem);
                }}
                onRemove={(selectedItem) => {
                  onSimpleFieldChange("requirements", selectedItem);
                }}
                displayValue="text"
                placeholder="Requisitos"
                closeIcon="cancel"
                selectionLimit="-1"
                showCheckbox={true}
                singleSelect={false}
              />
            </div>
          </div>
        </div>
        <br />

        <div className="modal-footer">
          <div className="w-100 d-flex justify-content-end">
            <Button
              className="btn-fill btn btn-danger btn-sm capitalize"
              onClick={() => {
                handlePreConfirmationPurchaseModal(
                  "purchase_file_status",
                  purchaseStatus.PRESENTATION_OBSERVED.value,
                  purchaseStatus.PRESENTATION_OBSERVED.text,
                  handleChangeStatus
                );
              }}
              disabled={
                !fields.comment?.length ||
                !fields.comment_reason_id ||
                !fields.requirements?.length
              }
            >
              {purchaseStatus.PRESENTATION_OBSERVED.text.substr(15)}
            </Button>
          </div>
        </div>
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
      </Form>
    </div>
  );
};

export default Observation;
