import { Multiselect } from "multiselect-react-dropdown";
import React from "react";
import DatePicker from "react-datepicker";
import Dropdown from "../../components/Table/Dropdown";
import Find from "../../components/Table/SearchInput";
import { constants } from "../../variables/constants";

//Agregar estilos en el filtro.

const Filters = ({ filterFields, onFilterChange, onSearch }) => (
  <div className="content-filters row mb-2 mt-2">
    {Object.entries(filterFields).map((fields, index) => {
      if (fields[1].type === "text") {
        return (
          <div
            className={`col-md-${fields[1].size} mb-1 mt-1 ${
              fields[1].hide ? "d-none" : ""
            }`}
            key={index}
          >
            <Find
              name={fields[1].name}
              placeholder={fields[1].placeholder}
              onTyping={(e) => onFilterChange(fields[1].name, e.target.value)}
            />
          </div>
        );
      }
      if (fields[1].type === "date") {
        return (
          <div
            className={`col-md-${fields[1].size} mb-1 mt-1 ${
              fields[1].hide ? "d-none" : ""
            }`}
            key={index}
          >
            <DatePicker
              selected={fields[1].value}
              name={fields[1].name}
              onChange={(date) => {
                onFilterChange(fields[1].name, date);
              }}
              dateFormat="dd/MM/yyyy"
              className="form-control"
              isClearable
              placeholderText={fields[1].placeholder}
            />
          </div>
        );
      }
      if (fields[1].type === "select") {
        return (
          <div
            className={`col-md-${fields[1].size} mb-1 mt-1 ${
              fields[1].hide ? "d-none" : ""
            }`}
            key={index}
          >
            <Dropdown
              className="form-control"
              name={fields[1].name}
              placeholder={fields[1].placeholder}
              onSelect={(e) =>
                onFilterChange(
                  fields[1].name,
                  e.target.value === constants.dropdownDefault
                    ? null
                    : e.target.value
                )
              }
              options={fields[1].options}
              disabled={fields[1].disabled}
              selected={fields[1].value}
            />
          </div>
        );
      }
      if (fields[1].type === "multiSelect") {
        return (
          <div
            className={`col-md-${fields[1].size} mb-1 mt-1 ${
              fields[1].hide ? "d-none" : ""
            }`}
            key={index}
          >
            <Multiselect
              options={fields[1].options}
              onSelect={(selectedItem) => {
                onFilterChange(fields[1].name, selectedItem);
              }}
              onRemove={(selectedItem) => {
                onFilterChange(fields[1].name, selectedItem);
              }}
              displayValue="text"
              placeholder={fields[1].placeholder}
              closeIcon="cancel"
              selectionLimit="-1"
              showCheckbox={true}
              singleSelect={false}
            />
          </div>
        );
      }
      if (fields[1].type === "multiSelect-search") {
        return (
          <div
            className={`col-md-${fields[1].size} mb-1 mt-1 ${
              fields[1].hide ? "d-none" : ""
            }`}
            key={index}
          >
            <Multiselect
              name="issuer_entity_id"
              options={fields[1].value}
              onSelect={(selectedItem) => {
                onFilterChange(fields[1].name, selectedItem);
              }}
              onRemove={(selectedItem) => {
                onFilterChange(fields[1].name, selectedItem);
              }}
              onSearch={onSearch}
              displayValue="name"
              placeholder={fields[1].placeholder}
              closeIcon="cancel"
              selectionLimit="1"
              showCheckbox={false}
              singleSelect={false}
            />
          </div>
        );
      }
    })}
  </div>
);

export default Filters;
