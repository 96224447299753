export const head = [
  { text: "id", field: "id", model: null },
  { text: "vehicle_id", field: "vehicle_id", model: null },
  { text: "Dominio", field: "plate_number", model: null },
  { text: "Estado venta", field: "status_sell", model: null },
  { text: "Ubicación", field: "delivery_place_id", model: null },
  { text: "Coordenada", field: "parking_lot", model: null },
  { text: "OT", field: "ot", model: null },
  { text: "Año", field: "vehicle_year", model: null },
  { text: "Marca", field: "vehicle_make", model: null },
  { text: "Modelo", field: "vehicle_model", model: null },
  { text: "KM", field: "vehicle_mileage", model: null },
  {
    text: "Chasis",
    field: "vehicle_chasis",
    model: null,
  },
  { text: "Fecha de vencimiento", field: "expired_date", model: null },
  { text: "status_vtv", field: null, model: null },
  { text: "Fecha primer turno", field: "original_schedule_date", model: null },
  {
    text: "Fecha turno vtv",
    field: "schedule_date",
    model: "vtv_purchase_schedule",
  },
  {
    text: "Planta VTV",
    field: "name",
    model: "vtv_purchase_schedule,vtv_schedule_reception_place",
  },
  { text: "Fecha turno real", field: "updatedAt", model: null },
  {
    text: "Estado turno vtv",
    field: "description",
    model: "vtv_purchase_schedule,vtv_purchase_schedule_status",
  },
  { text: "Nueva fecha de vencimiento", field: "", model: null },
  { text: "imagen", field: null, model: null },
  { text: "", field: null, model: null },
];

export const headOther = [
  { text: "id", field: "id", model: null },
  { text: "vehicle_id", field: "vehicle_id", model: null },
  { text: "Dominio", field: "plate_number", model: null },
  { text: "Estado venta", field: "status_sell", model: null },
  { text: "Ubicación", field: "delivery_place_id", model: null },
  { text: "Coordenada", field: "parking_lot", model: null },
  { text: "OT", field: "ot", model: null },
  { text: "Año", field: "vehicle_year", model: null },
  { text: "Marca", field: "vehicle_make", model: null },
  { text: "Modelo", field: "vehicle_model", model: null },
  { text: "Fecha de vencimiento", field: "expired_date", model: null },
  { text: "status_vtv", field: null, model: null },
  { text: "Fecha primer turno", field: "original_schedule_date", model: null },
  {
    text: "Fecha turno vtv",
    field: "schedule_date",
    model: "vtv_purchase_schedule",
  },
  { text: "Fecha turno real", field: "updatedAt", model: null },
  {
    text: "Estado turno vtv",
    field: "description",
    model: "vtv_purchase_schedule,vtv_purchase_schedule_status",
  },
  { text: "imagen", field: null, model: null },
  { text: "", field: null, model: null },
];
