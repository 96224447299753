import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import { purchaseStatus } from "../../variables/constants";

const ListStatus = (props) => {
  const [data, setData] = useState(false);
  const [total, setTotal] = useState('-');

  useEffect(() => {
    if (props.data) {
      let calcTotal = 0;
      const calcCount = Object.values(props.data.reduce((c, { purchase_file_status, count }) => {
        c[purchase_file_status] = c[purchase_file_status] || { purchase_file_status, count: 0 };
        c[purchase_file_status].count = c[purchase_file_status].count + count;
        calcTotal += count;
        return c;
      }, {}));

      setData(calcCount);
      setTotal(calcTotal);
    } else {
      setData(false);
      setTotal('-');
    }
  }, [props.data]);

  return (
    <div className="list-cases">
      <Card
        ctTableFullWidth
        ctTableResponsive
        title={props.title}
        legend={<strong>{`Total ${props.title}`}: {total}</strong>}
        content={
          (props.loading)
            ? 'Loading...'
            : <Table striped hover>
              <thead>
                <tr>
                  <th>Estado</th>
                  <th>Cantidad</th>
                  <th>Porcentaje</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {(data && data.length > 0)
                  ? data.map(s => (<tr key={`${props.title}-${s.purchase_file_status}`}>
                    <td>
                      <strong className="capitalize">
                        {Object.entries(purchaseStatus).find(o => o[1].value === s.purchase_file_status)[1].text}
                      </strong>
                    </td>
                    <td>
                      <span>{s.count}</span>
                    </td>
                    <td>
                      <span>{((s.count / total) * 100).toFixed(2)}%</span>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        name={s.purchase_file_status}
                        value={s.purchase_file_status}
                        onChange={props.onChangeStatus}
                      />
                    </td>
                  </tr>))
                  : (<tr>
                    <td><strong>Sin Datos</strong></td>
                    <td>-</td>
                    <td>-</td>
                  </tr>)
                }
              </tbody>
            </Table >
        }
      />
    </div >
  );
};

export default ListStatus;
