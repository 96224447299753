import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import List from "components/Table/List.js";
import GroupsService from "../../services/groups.service";
import AuthService from "../../services/auth.service";
import styles from "../General/styles.module.scss";
import { useToast } from "../../hooks";
import ModalDisplay from "layouts/ModalDisplay";

const headSort = [
  { text: "Id", field: "id", model: null },
  { text: "Nombre", field: "name", model: null },
  { text: "Descripción", field: "description", model: null },
  { text: "Creación", field: "createAt", model: null },
  { text: "Último cambio", field: "updatedAt", model: null },
  { text: "", field: null, model: null },
];

const GroupsList = (props) => {
  const [body, setBody] = useState([]);
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const addToast = useToast();

  //PAGINATE
  const countItems = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    model: null,
    field: "id",
    type: "ASC",
  });
  const [groupToDelete, setGroupToDelete] = useState(false);

  const handleToPage = (page, e) => {
    e.preventDefault();
    setCurrentPage(page);
  };

  const handleSort = (model, field, type) => {
    setCurrentSort({ model, field, type });
    setCurrentPage(0);
  };

  useEffect(() => {
    const params = {
      currentPage,
      countItems,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
    };
    GroupsService.getAll(params).then((response) => {
      const groups = response.data;
      if (groups.data.count > 0) {
        setTotalItems(groups.data.count);
        const result = Math.ceil(groups.data.count / countItems);
        setTotalPage(result);
        const groupsList = groups.data.rows.map((group) => ({
            id: group.id,
            name: group.name,
            description: group.description,
            createdAt: new Date(group.createdAt).toLocaleDateString("es-ES"),
            updatedAt: new Date(group.updatedAt).toLocaleDateString("es-ES"),
        }));
        setBody(groupsList);
      }
    });
  }, [currentPage, currentSort]);

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions("GRUPOS");
    setCurrentPermissions(permissions);
  }, []);

  const handleDelete = (id) => {
    GroupsService.del(id).then(() => {
      let list = body.filter((data) => data.id !== parseInt(id));
      setBody(list);
      setGroupToDelete(false);
      addToast({
        variant: "success",
        header: "Grupos",
        body: "Se eliminó el grupo correctamente",
      });
    });
  };

  const actions = (props) => {
    return (
      <>
        {currentPermissions && currentPermissions.permissions.hasUpdate ? (
          <a
            href={`/admin/groups/${props.id}/edit`}
            className="btn-simple btn btn-xs btn-info"
          >
            <i className="fas fa-edit"></i>
          </a>
        ) : null}

        {currentPermissions && currentPermissions.permissions.hasDelete ? (
          <button
            onClick={(e) => setGroupToDelete(props.id)}
            className="btn-simple btn btn-xs btn-danger"
          >
            <i className="fas fa-times"></i>
          </button>
        ) : null}
      </>
    );
  };

  return (
    <div className="content">
      <Container fluid>
        <div className={`content-filters form-group ${styles.lessHeight}`}>
          <Button
            href="/admin/groups/create"
            className="btn-fill btn btn-primary capitalize"
          >
            Nuevo Grupo
          </Button>
        </div>
        <br />
        <List
          thead={headSort}
          tbody={body}
          actions={actions}
          totalItems={totalItems}
          currentPage={currentPage}
          totalPage={totalPage}
          toPage={handleToPage}
          handleSort={handleSort}
          sortable
          currentSort={currentSort}
        />
      </Container>
      <ModalDisplay
        content={
          <div className="d-flex justify-content-end gap-4">
            <Button
              className="pull-right btn btn-danger"
              onClick={() => setGroupToDelete(false)}
            >
              Cerrar
            </Button>
            <Button
              className="btn-fill pull-right btn btn-danger"
              onClick={() => handleDelete(groupToDelete)}
            >
              Eliminar
            </Button>
          </div>
        }
        headerTitle='¿Desear eliminar el grupo?'
        size='md'
        showModal={groupToDelete}
        handleClose={() => setGroupToDelete(false)}
      />
    </div>
  );
};

export default GroupsList;
