import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import Filters from "../../components/Filters";
import List from "../../components/Table/List.js";
import ExcelButton from "../../components/ExcelButton/index.js";
import Edit from "../../components/Modal/renditions/index.js";
import ButtonIcon from "../../components/ButtonIcon";
import {
  useFilters,
  useGrid,
  usePermission,
  useStatusModal,
} from "../../hooks/index";
import Modal from "../../layouts/ModalDisplay";
import renditionService from "../../services/renditions/rendition.service";
import { badgeGenericBoolean } from "../../utils/Badges.js";
import { formatAmount } from "../../utils/Numbers.js";
import { head } from "./constants/columns";
import { filterFields } from "./constants/filters";

const RenditionList = () => {
  const { currentPermissions } = usePermission("RENDICIONES");

  const {
    body,
    cleanPage,
    countItems,
    currentPage,
    currentSort,
    handleBody,
    handleSort,
    handleToPage,
    handleTotalItems,
    handleTotalPage,
    refresh,
    refreshGrid,
    totalItems,
    totalPage,
  } = useGrid();

  const { filters, onFilterChange } = useFilters(filterFields, cleanPage);
  const [emptyFilters, setEmptyFilters] = useState(true);

  const { handleOpenEditModal, showEditModal, handleCloseEditModal } =
    useStatusModal();

  const [dataModal, setDataModal] = useState([]);

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: countItems,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
      sortModel: currentSort?.model,
      filters: filters,
    };
    const emptyFilters = Object.keys(params.filters).every(
      (key) =>
        params.filters[key].value === "" ||
        params.filters[key].value === null ||
        params.filters[key].value?.length === 0
    );

    setEmptyFilters(emptyFilters);
    if (!emptyFilters) {
      renditionService.getAll(params).then((response) => {
        handleTotalItems(response.data.totalItems);
        handleTotalPage(Math.ceil(response.data.totalItems / countItems));
        handleBody(sortColumns(response.data.response));
      });
    } else {
      handleTotalItems(0);
      handleTotalPage(0);
      handleBody([]);
    }
    //eslint-disable-next-line
  }, [currentPage, currentSort, refresh, filters]);

  const sortColumns = (body) =>
    body.map((e) => ({
      id: e.id,
      stock_id: e.stock_id,
      plate_number: e.plate_number,
      type: e.type,
      //ingreso
      ingresos: badgeGenericBoolean(e.ingresos),
      //egreso
      retiros: badgeGenericBoolean(e.retiros),
      monto: formatAmount(e.monto),
      //presented_date
      presented_date: e.presented_date,
      //retire_date
      retired_date: e.retired_date,
    }));

  const handleShowModal = (id, e) => {
    e.preventDefault();
    renditionService.getById(id).then((response) => {
      if (response.data.success) {
        setDataModal(response.data);
        handleOpenEditModal();
      }
    });
  };

  const actions = (props) => {
    return (
      <>
        <ButtonIcon
          onClick={(e) => handleShowModal(props.id, e)}
          name="file-alt"
          title="Editar"
        />
        {/* {currentPermissions && currentPermissions.permissions.hasDelete ? (
          <Button
            onClick={(e) => handleShowRejectModal(props.id, e)}
            className="btn-simple btn btn-xs btn-danger"
          >
            <i className="fas fa-times"></i>
          </Button>
        ) : null} */}
      </>
    );
  };

  return (
    <div className="content">
      <div className={`content-filters form-group`}>
        <ExcelButton type="rendiciones" filters={filters} />
      </div>
      <Filters filterFields={filters} onFilterChange={onFilterChange} />
      <List
        thead={head}
        tbody={body}
        actions={actions}
        // next={next}
        totalItems={totalItems}
        currentPage={currentPage}
        totalPage={totalPage}
        toPage={handleToPage}
        handleSort={handleSort}
        sortable
        currentSort={currentSort}
        emptyFilters={emptyFilters}
      />
      {showEditModal && (
        <Modal
          content={
            <Edit
              data={dataModal}
              handleClose={handleCloseEditModal}
              refresh={refreshGrid}
            />
          }
          showModal={showEditModal}
          handleShow={handleOpenEditModal}
          handleClose={handleCloseEditModal}
        />
      )}
    </div>
  );
};

export default RenditionList;
