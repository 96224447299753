import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";
import Form from "react-validation/build/form";

import { useConfirmationAlert, useToast } from "../../../hooks/index";
import additionalService from "../../../services/additional/additional.service";
import ManagementService from "../../../services/management.service";
import SellsService from "../../../services/sells/sells.service";
import {
  additionalConcepts,
  notaries,
  receptionPlaces,
} from "../../../utils/Service";
import {
  additionalType,
  additional_status,
} from "../../../variables/constants";
import ButtonIcon from "../../ButtonIcon";
import { DatePickerField, InputField, SelectField } from "../../Form/index";
import ConfirmationAlert from "../../ConfirmationAlert/confirmationAlert";
import { formatAmount } from "utils/Numbers";

const limit = 5;

const emptyItem = {
  plate_number: null,
  vehicle_id: null,
  opportunity_id: null,
  type_id: "",
  concept_id: "",
  quantity: null,
  amount: null,
  amount_iva: null,
};

const initialFormState = {
  status_id: 1,
  reception_place_id: "",
  notary_office_id: "",
  request_date: null,
  comment: null,
  items: [],
};

// Subcomponente que solo se va a usar aca
const ItemRow = ({
  data,
  onChangeItem,
  onDelete,
  onClone,
  reachLimit = false,
}) => {
  const [domains, setDomains] = useState([]);
  const [total, setTotal] = useState(0);

  const setStockId = ({ vehicle_id, opportunity_id, plate_number }) => {
    onChangeItem(data?.idx, "vehicle_id", vehicle_id);
    onChangeItem(data?.idx, "opportunity_id", opportunity_id);
    onChangeItem(data?.idx, "plate_number", plate_number);
  };

  // Selector de emisor
  const onSelect = (selectedList, selectedItem) => {
    setStockId({ ...selectedItem });
  };

  // Eliminación del emisor
  const onRemove = () => {
    setDomains([]);
    setStockId({ vehicle_id: null, opportunity_id: null, plate_number: "" });
  };

  // Buscador del emisor
  const onSearch = async (word) => {
    if (data?.type_id && word.length > 1) {
      const params = { limit: 1000, search: word };

      const response =
        data?.type_id == "1"
          ? await ManagementService.getAllDomains(params)
          : await SellsService.getAllDomains(params);

      let rows = [];
      if (response.data.success && response.data.count > 0) {
        // Compra
        if (data?.type_id == "1") {
          rows = response.data.response.map((data) => ({
            vehicle_id: data.vehicle_id,
            plate_number: data.plate_number,
            opportunity_id: null,
            name: `[Stock ID ${data.vehicle_id}] - ${data.plate_number}`,
          }));
        } else {
          // Venta
          rows = response.data.response.map((data) => ({
            vehicle_id: data.stock_id,
            plate_number: data.plate_number,
            opportunity_id: data.opportunity_id,
            name: `[Stock ID ${data.stock_id}] - ${data.plate_number} - [OPP ID ${data.opportunity_id}]`,
          }));
        }
      }
      setDomains(rows);
    }
  };

  useEffect(() => {
    setTotal(
      formatAmount(
        parseInt(data?.quantity) *
          (parseFloat(data?.amount || 0.0) +
            parseFloat(data?.amount_iva || 0.0))
      )
    );
  }, [data?.quantity, data?.amount, data?.amount_iva]);

  return (
    <>
      <div className="row" key={`top-${data?.idx}`}>
        <div className="col">
          <SelectField
            label={"Tipo"}
            name={"type_id"}
            value={data?.type_id}
            options={additionalType}
            onChange={(e) => {
              onChangeItem(
                data?.idx,
                "type_id",
                !!e.target.value ? e.target.value : null
              );
              onRemove();
            }}
          />
        </div>
        <div className="col">
          <label className="capitalize">
            <strong>Dominio</strong>
          </label>
          <Multiselect
            name="search"
            options={domains}
            onSelect={onSelect}
            onRemove={onRemove}
            onSearch={onSearch}
            displayValue="name"
            placeholder="Seleccione un dominio"
            closeIcon="cancel"
            selectionLimit="1"
            showCheckbox={false}
            singleSelect={false}
            selectedValues={
              data.plate_number
                ? [
                    {
                      vehicle_id: data.vehicle_id,
                      plate_number: data.plate_number,
                      opportunity_id: data.opportunity_id,
                      name: `[Stock ID ${data.vehicle_id}] - ${
                        data.plate_number
                      }${
                        data.opportunity_id
                          ? `[OPP ID ${data.opportunity_id}]`
                          : ""
                      }`,
                    },
                  ]
                : []
            }
          />
        </div>
        <div className="col">
          <SelectField
            label={"Concepto"}
            name={"concept_id"}
            value={data?.concept_id}
            options={additionalConcepts()}
            onChange={(e) =>
              onChangeItem(
                data?.idx,
                "concept_id",
                !!e.target.value ? e.target.value : null
              )
            }
          />
        </div>
      </div>
      <Form>
        <div className="row" key={`bottom-${data?.idx}`}>
          <div className="col">
            <InputField
              label={"Cantidad"}
              value={data?.quantity}
              name={"quantity"}
              onChange={(e) =>
                onChangeItem(data?.idx, "quantity", e.target.value)
              }
            />
          </div>
          <div className="col">
            <InputField
              label={"Monto No Gravado Unitario"}
              value={data?.amount}
              name={"amount"}
              onChange={(e) =>
                onChangeItem(data?.idx, "amount", e.target.value)
              }
            />
          </div>
          <div className="col">
            <InputField
              label={"Monto Gravado Unitario"}
              value={data?.amount_iva}
              name={"amount_iva"}
              onChange={(e) =>
                onChangeItem(data?.idx, "amount_iva", e.target.value)
              }
            />
          </div>
          <div className="col">
            <InputField label={"Total"} value={total} name={"total"} disabled />
          </div>
        </div>
      </Form>
      <div>
        <div className="col-1 d-flex align-items-end">
          <ButtonIcon
            onClick={onDelete}
            name="trash"
            color="danger"
            size="lg"
            title="Eliminar"
          />
          {!reachLimit && (
            <ButtonIcon
              onClick={onClone}
              name="clone"
              color="info"
              size="lg"
              title="Duplicar"
            />
          )}
        </div>
      </div>
    </>
  );
};

const Add = (props) => {
  const [fields, setFields] = useState(initialFormState);
  const [reachLimit, setReachLimit] = useState(false);
  const [allowSubmit, SetAllowSubmit] = useState(false);
  const [check, setCheck] = useState(+new Date());
  const addToast = useToast();
  const { confirmationModal, handleConfirmationModal } = useConfirmationAlert();

  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const onChangeItem = (idx, fieldName, fieldValue) => {
    const index = fields.items.findIndex((f) => f.idx === idx);
    if (idx !== -1) {
      fields.items[index][fieldName] = fieldValue;
      setCheck(+new Date());
    }
  };

  const onAdd = (newData = emptyItem) => {
    setFields({
      ...fields,
      items: [...fields.items, { ...newData, idx: +new Date() }],
    });
  };

  const onDelete = (idx) => {
    setFields({
      ...fields,
      items: fields.items.filter((f) => f.idx !== idx),
    });
  };

  const onClone = (idx) => {
    const index = fields.items.findIndex((f) => f.idx === idx);
    if (index !== -1) {
      // Al duplicar no mantenemos el concepto que es lo que debería cambiar
      onAdd({ ...fields.items[index], concept_id: "" });
    }
  };

  useEffect(() => {
    setReachLimit(fields.items.length >= limit);
  }, [fields.items.length]);

  useEffect(() => {
    SetAllowSubmit(
      !!fields.notary_office_id &&
        !!fields.reception_place_id &&
        !!fields.request_date &&
        !!fields.items.length &&
        !fields.items.some(
          (i) => !i.type_id || !i.plate_number || !i.concept_id
        )
    );
  }, [fields, check]);

  const submit = async () => {
    try {
      const response = await additionalService.insertBulk(fields);
      if (response.status === 200) {
        addToast({
          variant: "success",
          header: "Adicionales",
          body: "Se crearon las solicitudes correctamente",
        });
        props.handleClose();
        props.refresh();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="modalChecklist">
      <div className="row">
        <div className="col">
          <SelectField
            label={"Lugar de Toma"}
            name={"reception_place_id"}
            value={fields.reception_place_id ?? ""}
            options={receptionPlaces()}
            onChange={(e) =>
              onSimpleFieldChange(
                "reception_place_id",
                !!e.target.value ? e.target.value : null
              )
            }
          />
        </div>
        <div className="col">
          <SelectField
            label={"Lugar de Firma"}
            name={"notary_office_id"}
            value={fields.notary_office_id ?? ""}
            options={notaries()}
            onChange={(e) =>
              onSimpleFieldChange(
                "notary_office_id",
                !!e.target.value ? e.target.value : null
              )
            }
          />
        </div>
        <div className="col-2">
          <DatePickerField
            onChange={(date) => onSimpleFieldChange("request_date", date)}
            selected={fields.request_date}
            label={"Fecha solicitud"}
            minDate={new Date()}
          />
        </div>
      </div>
      <h4>
        <span className="me-2">Adicionales</span>
        {!reachLimit && (
          <ButtonIcon
            onClick={() => onAdd()}
            name="square-plus"
            color="info"
            size="lg"
            title="Agregar"
          />
        )}
      </h4>
      {fields.items.map((i) => (
        <ItemRow
          key={i.idx}
          data={i}
          onChangeItem={onChangeItem}
          onDelete={() => onDelete(i.idx)}
          onClone={() => onClone(i.idx)}
          reachLimit={reachLimit}
        />
      ))}
      <br />
      <div className="modal-footer">
        <div>
          <Button
            className="btn-fill pull-right btn btn-warning btn-sm"
            onClick={props.handleClose}
          >
            Cancelar
          </Button>
        </div>
        <div className="row">
          <Button
            className="btn-fill pull-right btn btn-primary btn-sm"
            disabled={!allowSubmit}
            onClick={submit}
            onClick={() =>
              handleConfirmationModal(
                additional_status[1].text,
                () => submit(),
                "¿Desea solicitar los siguentes adicionales?",
                "Al confirmar, se crearan los adicionales con el estado"
              )
            }
          >
            Aceptar
          </Button>
        </div>
      </div>
      {confirmationModal.open && (
        <ConfirmationAlert
          show={confirmationModal.open}
          handleAction={confirmationModal.handleAction}
          handleClose={confirmationModal.handleClose}
          message={confirmationModal.message}
          title={confirmationModal.title}
        />
      )}
    </div>
  );
};

export default Add;
