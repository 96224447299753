import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-validation/build/form";

import { useConfirmationAlert, useToast } from "../../../hooks";
import ConfirmationAlert from "../../ConfirmationAlert/confirmationAlert";
import additionalService from "../../../services/additional/additional.service";
import { constants, additional_status } from "../../../variables/constants";
import { Comments } from "../../Comments/index";
import { BadgeField, DomainAndStatus } from "./components";
import { InputField, SelectField } from "../../Form";
import { badgeGenericDiv } from "../../../utils/Badges";
import { formatAmount } from "../../../utils/Numbers";

// Estado rechazado por el lugar de firma
const status_decline_id = 3;
const status_observation_id = 6;

const allowDecline = additional_status
  .filter((f) => f.decline)
  .map((m) => m.value);
const allowObservation = additional_status
  .filter((f) => f.observation)
  .map((m) => m.value);

const Edit = (props) => {
  const {
    data: {
      response: {
        id,
        plate_number,
        vehicle_id,
        opportunity_id,
        status_id,
        request_date,
        quantity,
        amount,
        amount_iva,
        status,
        type,
        notary_office,
        reception_place,
        concept,
        comments,
      },
    },
    handleClose,
    refresh,
    profile,
  } = props;

  const initialFormState = {
    // quantity,
    // amount,
    // amount_iva,
    comment: null,
  };

  const readOnly = {
    concept,
    notary_office,
    opportunity_id,
    plate_number,
    reception_place,
    request_date,
    status_id,
    type,
    vehicle_id,
  };

  const [fields, setFields] = useState(initialFormState);
  // const [allowSubmit, SetAllowSubmit] = useState(false);
  const { confirmationModal, handleConfirmationModal } = useConfirmationAlert();
  const addToast = useToast();

  const next = additional_status[status_id]?.next;

  const submit = async (nextStatus = status_id) => {
    try {
      // Si estoy rechazando, no quiere datos adicionales al comentario y el estado
      const data =
        nextStatus === status_decline_id
          ? {
              ...initialFormState,
              comment: fields.comment,
              status_id: nextStatus,
            }
          : {
              ...fields,
              status_id: nextStatus,
            };
      const response = await additionalService.update(id, data);
      if (response.status === 200) {
        addToast({
          variant: "success",
          header: "Adicionales",
          body: "Se actualizó la información correctamente",
        });
        props.handleClose();
        props.refresh();
      }
    } catch (e) {
      console.error(e);
    }
  };

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  // useEffect(() => {
  //   SetAllowSubmit(!!fields.quantity && !!fields.amount && !!fields.iva_id);
  // }, [fields]);

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <DomainAndStatus readOnly={readOnly} profile={profile} />
          <hr />
          <div className="row">
            {/* {constants.notaryProfile === profile &&
            constants.notaryProfile === additional_status[status_id].profile ? (
              <>
                <div className="col">
                  <InputField
                    label={"Cantidad"}
                    value={fields.quantity}
                    name={"quantity"}
                    onChange={(e) =>
                      onSimpleFieldChange("quantity", e.target.value)
                    }
                  />
                </div>
                <div className="col">
                  <InputField
                    label={"Monto No Gravado Unitario"}
                    value={fields.amount}
                    name={"amount"}
                    onChange={(e) =>
                      onSimpleFieldChange("amount", e.target.value)
                    }
                  />
                </div>
                <div className="col">
                  <InputField
                    label={"Monto Gravado Unitario"}
                    value={fields.amount_iva}
                    name={"amount_iva"}
                    onChange={(e) =>
                      onSimpleFieldChange("amount_iva", e.target.value)
                    }
                  />
                </div>
              </>
            ) : (
              <> */}
            <BadgeField
              label={"Cantidad"}
              text={quantity ?? "sin datos"}
              badgeClass={"capitalize plate_number"}
              variant={"secondary"}
            />
            <BadgeField
              label={"Monto No Gravado Unit."}
              text={amount ? formatAmount(amount) : "sin datos"}
              badgeClass={"capitalize plate_number"}
              variant={"secondary"}
            />
            <BadgeField
              label={"Monto Gravado Unitario"}
              text={amount_iva ? formatAmount(amount_iva) : "sin datos"}
              badgeClass={"capitalize plate_number"}
              variant={"secondary"}
            />
            <BadgeField
              label={"Total"}
              text={formatAmount(
                parseInt(quantity || 0) *
                  (parseFloat(amount || 0.0) + parseFloat(amount_iva || 0.0))
              )}
              badgeClass={"capitalize plate_number"}
              variant={"success"}
            />
            {/* </>
            )} */}
          </div>
          <hr />
          <Comments
            comments={comments.map((c) => ({
              ...c,
              status: badgeGenericDiv(
                additional_status[c.status_id].color,
                additional_status[c.status_id].text
              ),
            }))}
            onChangeObs={(e) => onSimpleFieldChange("comment", e.target.value)}
            generic
            hideReasons={profile !== additional_status[status_id].profile}
          />
        </div>
        <br />
        <div className="modal-footer">
          {constants.notaryProfile === profile &&
            constants.notaryProfile === additional_status[status_id].profile &&
            allowDecline.includes(status_id) && (
              <div>
                <Button
                  className="btn-fill pull-right btn btn-danger btn-sm"
                  disabled={!fields.comment?.length}
                  onClick={() =>
                    handleConfirmationModal(
                      additional_status[status_decline_id].text,
                      () => submit(status_decline_id),
                      "¿Desea rechazar el adicional?",
                      "Al confirmar, el adicional cambiará al estado"
                    )
                  }
                >
                  {additional_status[status_decline_id].text}
                </Button>
              </div>
            )}
          {constants.notaryProfile !== profile &&
            constants.adminProfile === additional_status[status_id].profile &&
            allowObservation.includes(status_id) && (
              <div>
                <Button
                  className="btn-fill pull-right btn btn-danger btn-sm"
                  disabled={!fields.comment?.length}
                  onClick={() =>
                    handleConfirmationModal(
                      additional_status[status_observation_id].text,
                      () => submit(status_observation_id),
                      "¿Desea observar el adicional?",
                      "Al confirmar, el adicional cambiará al estado"
                    )
                  }
                >
                  {additional_status[status_observation_id].text}
                </Button>
              </div>
            )}

          {!additional_status[status_id].final &&
            !!next &&
            ((constants.notaryProfile === profile &&
              constants.notaryProfile ===
                additional_status[status_id].profile) ||
              (constants.notaryProfile !== profile &&
                constants.adminProfile ===
                  additional_status[status_id].profile)) && (
              <div className="row">
                <Button
                  className="btn-fill pull-right btn btn-primary btn-sm"
                  // onClick={() => submit(next)}
                  onClick={() =>
                    handleConfirmationModal(
                      additional_status[next].text,
                      () => submit(next),
                      "¿Desea cambiar el estado del adicional?",
                      "Al confirmar, el adicional cambiará al estado"
                    )
                  }
                  // disabled={!allowSubmit}
                >
                  {additional_status[next].text}
                </Button>
              </div>
            )}
        </div>
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
      </Form>
    </div>
  );
};

export default Edit;
