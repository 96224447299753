import axios from "axios";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAllPresentation = async (params) => {
  // const status = parseStatus(props.status);
  const url = `${API_URL}/presentation/list`;
  return await axios.post(url, params, { headers: authHeader() });
};

const getPresentationModalDataById = async (id) => {
  return await axios.get(`${API_URL}/presentation/${id}`, {
    headers: authHeader(),
  });
};

const getPurchaseForms = async (vehicle_id) => {
  return await axios.get(`${API_URL}/form/purchase/${vehicle_id}`, {
    headers: authHeader(),
  });
};

export default {
  getAllPresentation,
  getPresentationModalDataById,
  getPurchaseForms,
};
