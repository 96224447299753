import React, { useState, useRef, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
// import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "components/Card/Card.jsx";
import NotFound from "components/Errors/NotFound.js";
import GroupsService from "../../services/groups.service";
import { useToast } from "../../hooks";
import styles from "../General/styles.module.scss";
import { updateLocalStorageCache } from "utils/Service";
import GroupsUsersEdit from "./users/GroupsUsersEdit";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        El campo es obligatorio!
      </div>
    );
  }
};

const GroupsEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const group_id = params.id;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [resourceExist, setResourceExist] = useState(true);

  const [previousName, setPreviousName] = useState("");
  const [previousDescription, setPreviousDescription] = useState("");
  const [updatesToSave, setUpdatesToSave] = useState(false);

  const form = useRef();
  const checkBtn = useRef();
  const addToast = useToast();

  useEffect(() => {
    GroupsService.getById(group_id)
      .then((response) => {
        const res = response.data;
        if (res.success && res.data) {
          setName(res.data.name);
          setDescription(res.data.description);
          setPreviousName(res.data.name);
          setPreviousDescription(res.data.description);
        }
      })
      .catch((e) => {
        setResourceExist(false);
      });
  }, [group_id]);

  useEffect(() => {
    if (name !== previousName || description !== previousDescription) {
      setUpdatesToSave(true);
    } else {
      setUpdatesToSave(false);
    }
  }, [name, description]);

  const handSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn?.current?.context?._errors.length === 0) {
      const params = {
        id: group_id,
        name,
        description,
      };
      GroupsService.edit(params).then(
        () => {
          addToast({
            variant: "success",
            header: "Grupos",
            body: "Se actualizó el grupo correctamente",
          });
          updateLocalStorageCache().then(() => {
            navigate("/admin/groups/list");
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          addToast({
            variant: "danger",
            header: "Grupos",
            body: resMessage,
          });
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className={`content-filters form-group ${styles.lessHeight}`}>
              <Button href="/admin/groups/list" variant="outline-primary">
                Volver
              </Button>
              
            </div>
          </Col>
        </Row>

        <br/>
        <Row>
          <Col md={12}>
            <Card
              content={
                resourceExist ? (
                  <Form onSubmit={handSubmit} ref={form}>
                    <h4>Información del grupo</h4>
                    <div className="form-group">
                      <label htmlFor="email">Nombre</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Nombre"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Descripción</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="description"
                        placeholder="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        validations={[required]}
                      />
                    </div>

                    {updatesToSave ? <div className="form-group mt-3">
                      <button
                        className="btn btn-primary pull-right"
                        disabled={loading}
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Actualizar nombre y descripción del grupo</span>
                      </button>
                      <CheckButton style={{ display: "none" }} ref={checkBtn} />
                      <div className="clearfix" />
                    </div> : null}

                    <div className="w-full border-" />
                    
                    <div>
                      <GroupsUsersEdit group_id={group_id} />
                    </div>
                  </Form>
                ) : (
                  <NotFound />
                )
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GroupsEdit;
