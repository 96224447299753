import { constants } from "./constants";

export const get_status_name = {
  1: "CONFECCION_TRAMITE",
  2: "ENTREGADO_LOGISTICA",
  3: "PRESENTACION_ENTREGADO",
  4: "PRESENTACION_REGISTRO",
  5: "RETIRO_CEDULA",
  6: "COMPLETADO",
  7: "DESAFECTADO_VENTA",
  8: "DESAFECTADO_TIEMPO",
  9: "RETIRO_CEDULA_OBSERVADO",
  10: "RECEPCION_CAT",
};

export const bacs_status = {
  CONFECCION_TRAMITE: {
    value: 1,
    text: "Confección de Trámite",
    color: "badgeWarning",
    next: "ENTREGADO_LOGISTICA",
    next_value: 2,
    allowed: [
      constants.adminProfile,
      constants.coordinatorProfile,
      constants.logisticlAdmin,
      constants.employeeProfile
    ],
  },
  ENTREGADO_LOGISTICA: {
    value: 2,
    text: "Entregado a Logística",
    color: "badgeFolioInProgress",
    next: "PRESENTACION_ENTREGADO",
    next_value: 3,
    allowed: [
      constants.adminProfile,
      constants.coordinatorProfile,
      constants.logisticlAdmin,
      constants.employeeProfile
    ],
  },
  PRESENTACION_ENTREGADO: {
    value: 3,
    text: "Presentación Entregado",
    color: "badgePresentationDelivered",
    next: "PRESENTACION_REGISTRO",
    next_value: 4,
    allowed: [
      constants.adminProfile,
      constants.coordinatorProfile,
      constants.logisticlAdmin,
    ],
  },
  PRESENTACION_REGISTRO: {
    value: 4,
    text: "Presentación en Registro",
    color: "badgePresentationPresented",
    next: "RECEPCION_CAT",
    next_value: 10,
    allowed: [
      constants.adminProfile,
      constants.coordinatorProfile,
      constants.logisticlAdmin,
      constants.agentInternalProfile,
    ],
  },
  RECEPCION_CAT: {
    value: 10,
    text: "Recepción CAT",
    color: "badgePresentationReceptionCat",
    next: "RETIRO_CEDULA",
    next_value: 5,
    allowed: [
      constants.adminProfile,
      constants.coordinatorProfile,
      constants.logisticlAdmin,
      constants.agentInternalProfile,
    ],
  },
  RETIRO_CEDULA: {
    value: 5,
    text: "Retiro de Cédulas",
    color: "badgePresentationRetired",
    next: "COMPLETADO",
    next_value: 6,
    allowed: [
      constants.adminProfile,
      constants.coordinatorProfile,
      constants.logisticlAdmin,
      constants.agentInternalProfile,
    ],
  },
  RETIRO_CEDULA_OBSERVADO: {
    value: 9,
    text: "Retiro de Cédulas - Observado",
    color: "badgePresentationDelivered",
    next: "COMPLETADO",
    next_value: 6,
    allowed: [
      constants.adminProfile,
      constants.coordinatorProfile,
      constants.logisticlAdmin,
      constants.agentInternalProfile,
    ],
  },
  COMPLETADO: {
    value: 6,
    text: "Completado",
    color: "badgeCompleted",
    allowed: [
      constants.adminProfile,
      constants.coordinatorProfile,
      constants.logisticlAdmin,
    ],
  },
  DESAFECTADO_VENTA: {
    value: 7,
    text: "Desafectado - Venta",
    color: "badgeRejected",
  },
  DESAFECTADO_TIEMPO: {
    value: 8,
    text: "Desafectado - Tiempo",
    color: "badgeRejected",
  },
};

export const get_bank_status_name = {
  1: "ACTIVO",
  2: "DESAFECTADO",
  3: "FALTANTE",
  4: "SOLICITAR_DESAFECTACIÓN",
};

export const banks_status = {
  ACTIVO: {
    value: 1,
    text: "Activo",
    color: "badgePresentationRetired",
  },
  DESAFECTADO: {
    value: 2,
    text: "Desafectado",
    color: "badgeRejected",
  },
  FALTANTE: {
    value: 3,
    text: "Faltante",
    color: "badgeWarning",
  },
  SOLICITAR_DESAFECTACIÓN: {
    value: 4,
    text: "Solicitar Desafectación",
    color: "badgeFolioInProgress",
  },
};