import Dropdown from "components/Table/Dropdown.js";
import { useConfirmationAlert } from "../../../hooks/";
import React, { useRef } from "react";
import { Button, Card } from "react-bootstrap";
import Form from "react-validation/build/form";
import Textarea from "react-validation/build/textarea";
import ConfirmationAlert from "../../ConfirmationAlert/confirmationAlert";

const GenericReject = (props) => {
  const form = useRef();
  const {confirmationModal,handleConfirmationModal} = useConfirmationAlert();

  return (
    <Card.Body>
      <Form ref={form}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Motivo</label>
              <Dropdown
                className="form-control"
                name="cancel_reason_id"
                placeholder="Motivo del comentario"
                onSelect={(e) =>
                  props.handleOnChange("cancel_reason_id", e.target.value)
                }
                options={props.reasons}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Observaciones</label>
              <Textarea
                className="form-control"
                rows="3"
                name="cancel_reason"
                onChange={(e) =>
                  props.handleOnChange("cancel_reason", e.target.value)
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col mt-2">
            <Button
              onClick={() => handleConfirmationModal('RECHAZADO',props.submit)}
              className="btn-fill pull-left btn btn-danger btn-sm"
              disabled={props.fields.cancel_reason.length > 0 ? false : true}
            >
              Confirmar el rechazo
            </Button>
          </div>
        </div>
        {confirmationModal.open && <ConfirmationAlert 
          handleAction={confirmationModal.handleAction}
          handleClose={confirmationModal.handleClose}
          message={confirmationModal.message}
          show={confirmationModal.open}
          title={confirmationModal.title}
        />}
      </Form>
    </Card.Body>
  );
};

export default GenericReject;
