import React from "react";
import { ProgressBar } from "react-bootstrap";
import { passwordTracker } from "utils/User";

const PasswordStrengthMeter = ({ password, passwordStrength }) => {
  const variants = [
    "",
    "danger",
    "secondary",
    "warning",
    "info",
    "success",
  ];
  return (
    <div style={{ paddingTop: 5 }}>
      <ProgressBar
        animated
        now={(passwordStrength / 5) * 100}
        variant={variants[passwordStrength]}
      />
      <div>
        {passwordStrength < 5 && "Debe incluir "}
        {!passwordTracker(password).uppercase && "mayuscula, "}
        {!passwordTracker(password).lowercase && "minuscula, "}
        {!passwordTracker(password).specialChar && "caracter especial, "}
        {!passwordTracker(password).number && "número, "}
        {!passwordTracker(password).minCharsOrGreater && "14 o mas caracteres"}
      </div>
    </div>
  );
};

export default PasswordStrengthMeter;
