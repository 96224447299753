import additionalService from "../services/additional/additional.service";
import bacsRequirementsService from "../services/bacs/bacsRequirements.service";
import issuerEntityService from "../services/issuerEntity.service";
import notaryService from "../services/notary.service";
import printService from "../services/print/print.service";
import profileService from "../services/profile.service";
import purchaseRequirementsService from "../services/purchase/requirements.service";
import receptionPlaceService from "../services/receptionPlace.service";
import sellRequirementsService from "../services/sells/requirements.service";
import sellsService from "../services/sells/sells.service";
import userService from "../services/user.service";
import {
  legalizeStatus,
  purchaseStatus,
  status08,
} from "../variables/constants";

export const parseStatus = (status) => {
  let st = false;
  if (status instanceof Array) {
    st = status.map((data) => {
      return `status[]=${data}`;
    });
    return st.join("&");
  }
  return st;
};

export const purchaseStatusText = (status) => {
  const statusElem = Object.entries(purchaseStatus).find(
    (s) => s[1].value === status
  );
  return statusElem ? statusElem[1].text : "indefinido";
};

export const status08Text = (status) => {
  const statusElem = Object.entries(status08).find(
    (s) => s[1].value === status
  );
  return statusElem ? statusElem[1].text : "indefinido";
};

export const legalizeStatusText = (status) => {
  const statusElem = Object.entries(legalizeStatus).find(
    (s) => s[1].value === status
  );
  return statusElem ? statusElem[1].text : "indefinido";
};

export const zones = () => {
  const cache = JSON.parse(localStorage.getItem("zones"));
  if (cache && cache.length) {
    return cache;
  }
  return [];
};

export const agents = () => {
  const cache = JSON.parse(localStorage.getItem("agents"));
  if (cache && cache.length) {
    return cache;
  }
  return [];
};

export const categories = () => {
  const cache = JSON.parse(localStorage.getItem("category"));
  if (cache && cache.length) {
    return cache;
  }
  return [];
};

export const employees = () => {
  const cache = JSON.parse(localStorage.getItem("employees"));
  if (cache && cache.length) {
    return cache;
  }
  return [];
};

export const sellEmployees = () => {
  const cache = JSON.parse(localStorage.getItem("sellEmployees"));
  if (cache && cache.length) {
    return cache;
  }
  return [];
};

export const notaries = () => {
  const cache = JSON.parse(localStorage.getItem("notaries"));
  if (cache && cache.length) {
    return cache;
  }
  return [];
};

export const receptionPlaces = () => {
  const cache = JSON.parse(localStorage.getItem("reception_place"));
  if (cache && cache.length) {
    return cache;
  }
  return [];
};

export const issuerEntitiesAll = () => {
  const cache = JSON.parse(localStorage.getItem("issuer_entity_all"));
  if (cache && cache.length) {
    return cache;
  }
  return [];
};

export const issuerEntities = () => {
  const cache = JSON.parse(localStorage.getItem("issuer_entity"));
  if (cache && cache.length) {
    return cache;
  }
  return [];
};

export const sellRequirements = () => {
  const cache = JSON.parse(localStorage.getItem("sell_requirements"));
  if (cache && cache.length) {
    return cache;
  }
  return [];
};

export const sellSuspendedRequirements = () => {
  const cache = JSON.parse(localStorage.getItem("sell_suspended_requirements"));
  if (cache && cache.length) {
    return cache;
  }
  return [];
};

export const bacsRequirements = () => {
  const cache = JSON.parse(localStorage.getItem("bacs_requirements"));
  if (cache && cache.length) {
    return cache;
  }
  return [];
};

export const purchaseRequirements = () => {
  const cache = JSON.parse(localStorage.getItem("purchase_requirements"));
  if (cache && cache.length) {
    return cache;
  }
  return [];
};

export const purchaseSuspendedRequirements = () => {
  const cache = JSON.parse(
    localStorage.getItem("purchase_suspended_requirements")
  );
  if (cache && cache.length) {
    return cache;
  }
  return [];
};

export const printForms = () => {
  const cache = JSON.parse(localStorage.getItem("print_forms"));
  if (cache && cache.length) {
    return cache;
  }
  return [];
};

export const additionalConcepts = () => {
  const cache = JSON.parse(localStorage.getItem("additional_concepts"));
  if (cache && cache.length) {
    return cache;
  }
  return [];
};

export const profiles = () => {
  const cache = JSON.parse(localStorage.getItem("profiles"));
  if (cache && cache.length) {
    return cache;
  }
  return [];
};

export const updateLocalStorageCache = async () => {
  try {
    await Promise.all([
      revalidateAdditionalConceptsCache(),
      revalidateAgentsCache(),
      revalidateBacsRequirementsCache(),
      revalidateCategoriesCache(),
      revalidateEmployeesCache(),
      revalidateIssuerEntitiesCache(),
      revalidateNotariesCache(),
      revalidatePrintFormsCache(),
      revalidateProfilesCache(),
      revalidatePurchaseRequirementsCache(),
      revalidateReceptionPlaceCache(),
      revalidateSellEmployeesCache(),
      revalidateSellRequirementsCache(),
      revalidateZonesCache(),
    ]);

    return true;
  } catch (error) {
    console.error("Error revalidating cached data 🔴:", error);
    return false;
  }
};

export const revalidateEmployeesCache = async () => {
  try {
    const res = await userService.getAllByProfile({
      currentPage: 0,
      countItems: 1000,
      profile: "employee",
    });

    if (res.data && res.data.response) {
      const result = res.data.response.map((r) => ({
        value: r.id,
        text: r.name,
      }));
      localStorage.setItem("employees", JSON.stringify(result));
    }
  } catch (e) {
    console.error("Error updating employees:", e);
    return null;
  }
};

export const revalidateSellEmployeesCache = async () => {
  try {
    const res = await userService.getAllByProfile({
      currentPage: 0,
      countItems: 1000,
      profile: "sellEmployees",
    });
    const result = res.data.response.map((r) => ({
      value: r.id,
      text: r.name,
    }));
    localStorage.setItem("sellEmployees", JSON.stringify(result));
  } catch (e) {
    console.error("Error updating sellEmployees:", e);
    return null;
  }
};

export const revalidateZonesCache = async () => {
  try {
    const res = await issuerEntityService.getZones();
    const result = res.data.response.map((i) => ({
      value: i.zone,
      text: `Zona ${i.zone}`,
    }));
    localStorage.setItem("zones", JSON.stringify(result));
  } catch (e) {
    console.error("Error updating zones:", e);
    return null;
  }
};

export const revalidateAgentsCache = async () => {
  try {
    const res = await userService.getAllByProfile({
      currentPage: 0,
      countItems: 1000,
      profile: "agent",
    });
    const result = res.data.response.map((r) => ({
      value: r.id,
      text: r.name,
    }));
    localStorage.setItem("agents", JSON.stringify(result));
  } catch (e) {
    console.error("Error updating agents:", e);
    return null;
  }
};

export const revalidateCategoriesCache = async () => {
  try {
    const res = await sellsService.getCategories();
    localStorage.setItem(
      "category",
      JSON.stringify(
        res.data.data.map((cat) => ({ value: cat.id_ns, text: cat.text }))
      )
    );
  } catch (e) {
    console.error("Error updating categories:", e);
    return null;
  }
};

export const revalidateNotariesCache = async () => {
  try {
    const res = await notaryService.getAll({
      sortField: "name",
      sortType: "ASC",
    });
    const result = res.data.response.map((r) => ({
      value: r.id,
      text: r.name,
    }));
    localStorage.setItem("notaries", JSON.stringify(result));
  } catch (e) {
    console.error("Error updating notaries:", e);
    return null;
  }
};

export const revalidateReceptionPlaceCache = async () => {
  try {
    const res = await receptionPlaceService.getAll({
      sortField: "name",
      sortType: "ASC",
    });
    if (res.data.count > 0) {
      const result = res.data.response.map((r) => ({
        value: r.id,
        text: r.name,
      }));
      localStorage.setItem("reception_place", JSON.stringify(result));
    }
  } catch (e) {
    console.error("Error updating reception places:", e);
    return null;
  }
};

export const revalidateIssuerEntitiesCache = async () => {
  try {
    const res = await issuerEntityService.getAll({
      limit: 9999,
      page: 0,
      searchq: null,
      zone: null,
      sortField: "description",
      sortType: "ASC",
    });
    if (res.data.count > 0) {
      const result = res.data.response.map((r) => ({
        value: r.issuer_entity_id,
        text: r.description,
      }));
      const filter = result.filter((f) => f.value != "1");
      localStorage.setItem("issuer_entity_all", JSON.stringify(result));
      localStorage.setItem("issuer_entity", JSON.stringify(filter));
    }
  } catch (e) {
    console.error("Error updating issuer entities:", e);
    return null;
  }
};

export const revalidateSellRequirementsCache = async () => {
  try {
    const res = await sellRequirementsService.getAll();
    const observed = res.data.response
      .filter((f) => !!f.observed)
      .map((r) => ({
        value: r.value,
        text: r.text,
      }));
    const suspended = res.data.response
      .filter((f) => !!f.suspended)
      .map((r) => ({
        value: r.value,
        text: r.text,
      }));
    localStorage.setItem("sell_requirements", JSON.stringify(observed));
    localStorage.setItem(
      "sell_suspended_requirements",
      JSON.stringify(suspended)
    );
  } catch (e) {
    console.error("Error updating sell requirements:", e);
    return null;
  }
};

export const revalidateBacsRequirementsCache = async () => {
  try {
    const res = await bacsRequirementsService.getAll();
    localStorage.setItem(
      "bacs_requirements",
      JSON.stringify(res.data.response)
    );
  } catch (e) {
    console.error("Error updating BACS requirements:", e);
    return null;
  }
};

export const revalidatePurchaseRequirementsCache = async () => {
  try {
    const res = await purchaseRequirementsService.getAll();
    const observed = res.data.response
      .filter((f) => !!f.observed)
      .map((r) => ({
        value: r.value,
        text: r.text,
      }));
    const suspended = res.data.response
      .filter((f) => !!f.suspended)
      .map((r) => ({
        value: r.value,
        text: r.text,
      }));
    localStorage.setItem("purchase_requirements", JSON.stringify(observed));
    localStorage.setItem(
      "purchase_suspended_requirements",
      JSON.stringify(suspended)
    );
  } catch (e) {
    console.error("Error updating purchase requirements:", e);
    return null;
  }
};

export const revalidatePrintFormsCache = async () => {
  try {
    const res = await printService.getPrintForms();
    localStorage.setItem("print_forms", JSON.stringify(res.data.response));
  } catch (e) {
    console.error("Error updating print forms:", e);
    return null;
  }
};

export const revalidateAdditionalConceptsCache = async () => {
  try {
    const res = await additionalService.getConcepts();
    localStorage.setItem(
      "additional_concepts",
      JSON.stringify(res.data.response)
    );
  } catch (e) {
    console.error("Error updating additional concepts:", e);
    return null;
  }
};

export const revalidateProfilesCache = async () => {
  try {
    const res = await profileService.getAll({
      sortField: "name",
      sortType: "ASC",
    });
    localStorage.setItem("profiles", JSON.stringify(res.data.response));
  } catch (e) {
    console.error("Error updating profiles:", e);
    return null;
  }
};

export default {};
