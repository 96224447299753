import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Filters from "../../components/Filters/index";
import List from "../../components/Table/List";
import ShowInformation from "../../components/Modal/Sells/rejected/edit";
import ExcelButton from "../../components/ExcelButton";
import { useFilters, useGrid, useStatusModal } from "../../hooks/index";
import authService from "../../services/auth.service";
import SellsRejectedService from "../../services/sells/sellsRejected.service";
import sellsSuspendedService from "../../services/sells/sellsSuspended.service";
import { badgeGenericBoolean, twoBadges } from "../../utils/Badges";
import { FINANCED_STATUS_NAME } from "../../variables/sells/index";
import Modal from "../../layouts/ModalDisplay";
import { headRejected } from "./constants/constants_columns";
import styles from "../General/styles.module.scss";

const filterFields = {
  plate_number: {
    type: "text",
    name: "plate_number",
    placeholder: "Dominio",
    value: "",
    size: 3,
  },
};

const sortColumns = (body) => {
  return body.map((e) => ({
    opportunity_id: e.opportunity_id,
    plate_number: twoBadges(
      e.plate_number,
      e.financed ? FINANCED_STATUS_NAME.FINANCED : null
    ),
    stock_id: e.stock_id,
    createdAt: e.createdAt,
    updatedAt: e.updatedAt,
    issuer_entity_tentative: e.issuer_entity_tentative,
    zona: e.zone,
    updatedUser: e.updatedUser,
    cancel_reason: e.cancel_reason,
    bacs_id: badgeGenericBoolean(e.bacs_id),
  }));
};

const sort = {
  model: null,
  field: "opportunity_id",
  type: "ASC",
};

const StatusForm08List = (props) => {
  const {
    body,
    countItems,
    currentPage,
    currentSort,
    totalItems,
    totalPage,
    handleBody,
    handleSort,
    handleToPage,
    handleTotalItems,
    handleTotalPage,
    cleanPage,
  } = useGrid(sort);

  const { filters, onFilterChange } = useFilters(filterFields, cleanPage);
  const [emptyFilters, setEmptyFilters] = useState(true);
  const { handleCloseEditModal, handleOpenEditModal, showEditModal } =
    useStatusModal();
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const [dataModal, setDataModal] = useState([]);

  useEffect(() => {
    const permissions = authService.getCurrentPermissions("RECHAZADOS");
    setCurrentPermissions(permissions);
  }, []);

  const next = {
    link: "/admin/management/toma-vehiculo",
    text: "Toma",
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: countItems, // like countItems
      sortModel: currentSort?.model,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
      filters,
    };

    const emptyFilters = Object.keys(params.filters).every(
      (key) => 
        params.filters[key].value === "" || 
        params.filters[key].value === undefined || 
        params.filters[key].value.length === 0
    );
    setEmptyFilters(emptyFilters);
    if(emptyFilters) {
      handleTotalItems(0);
      handleTotalPage(0);
      handleBody(sortColumns([]));
      return
    }

    SellsRejectedService.getAll(params).then((response) => {
      handleTotalItems(response.data.totalItems);
      handleTotalPage(Math.ceil(response.data.totalItems / countItems));
      handleBody(sortColumns(response.data.response));
    });
    //eslint-disable-next-line
  }, [currentPage, currentSort, countItems, filters]);

  const openEditModal = (id) => {
    sellsSuspendedService.getById(id).then((response) => {
      if (response.data.success) {
        setDataModal(response.data);
        handleOpenEditModal();
      }
    });
  };

  const actions = (props) => {
    return (
      <>
        {currentPermissions && currentPermissions.permissions.hasSelect && (
          <Button
            onClick={(e) => openEditModal(props.opportunity_id, e)}
            className="btn-simple btn btn-xs btn-secondary"
          >
            <i className="fas fa-file-alt" aria-hidden="true"></i>
          </Button>
        )}
      </>
    );
  };

  return (
    <div className="content">
      <div className={styles.export}>
        <ExcelButton type="ventasRechazados" filters={filters} />
      </div>
      <Filters filterFields={filters} onFilterChange={onFilterChange} />
      <List
        thead={headRejected}
        tbody={body}
        actions={actions}
        next={next}
        totalItems={totalItems}
        currentPage={currentPage}
        totalPage={totalPage}
        toPage={handleToPage}
        handleSort={handleSort}
        sortable
        currentSort={currentSort}
      />
      {showEditModal && (
        <Modal
          content={
            <ShowInformation
              data={dataModal}
              handleClose={handleCloseEditModal}
            />
          }
          showModal={showEditModal}
          handleShow={openEditModal}
          handleClose={handleCloseEditModal}
        />
      )}
    </div>
  );
};

export default StatusForm08List;
