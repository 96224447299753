import axios from "axios";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const updateId = async (props) => {
  return await axios.put(`${API_URL}/settings/trade-ids`,{...props}, { headers: authHeader() });
};

export default {
  updateId,
};
