import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL;

const get = async () => {
    return await axios.get(`${API_URL}/menu`, { headers: authHeader() });
};

export default {
    get
};
