import React, {useEffect, useState} from "react";
import {Table} from "react-bootstrap";
import Card from "components/Card/Card.jsx";

const Information = (props) => {  
    const [information, setInformation] = useState(false);

    useEffect(() => {
        setInformation(props.data);
    }, [props.data]);

    return (
        <div className="plate-information">
            <Card
                ctTableFullWidth
                ctTableResponsive
                title="Información"
                content={
                    (props.loading) 
                    ? 'Loading...'
                    : <Table striped hover>
                        <tbody>
                        <tr>
                            <td><strong>Dominio</strong></td>
                            <td>
                                {(information && information.dominio) 
                                ? information.dominio
                                : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Registro</strong></td>
                            <td>
                                {(information && information.registro) 
                                ? information.registro
                                : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Dirección</strong></td>
                            <td>
                                {(information && information.direccion) 
                                ? information.direccion
                                : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Localidad</strong></td>
                            <td>
                                {(information && information.localidad) 
                                ? information.localidad
                                : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Provincia</strong></td>
                            <td>
                                {(information && information.provincia) 
                                ? information.provincia
                                : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Código Postal</strong></td>
                            <td>
                                {(information && information.codigo_postal) 
                                ? information.codigo_postal
                                : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Teléfono</strong></td>
                            <td>
                                {(information && information.telefono) 
                                ? information.telefono
                                : '-'}
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                }
            />
        </div>
    );
};

export default Information;
