import React from "react";

const Loading = ({ cols }) => {
  if (cols) {
    return (
      <tbody>
        <tr>
          <td colSpan={cols}>
            <div className="text-center m-2">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    );
  }
  return (
    <tbody />
  );
};

export default Loading;
