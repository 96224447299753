import React from "react";
import {
  CheckboxField,
  DatePickerField,
  InputField,
} from "components/Form/index";
import { status08 } from "variables/constants";
import { BadgeField } from ".";
import { useGetStatus } from "hooks";

const STATUS_FORM_08 = {
  delivered: "DELIVERED",
  signed: "SIGNED",
};

const Form08 = ({ data, onSimpleFieldChange }) => {
  const { status } = useGetStatus(data.current_status, status08);
  const onChangeForm08 = (_, fieldValue) => {
    data.received = fieldValue;
    onSimpleFieldChange("form_08", data);
  };

  return (
    <div className="row">
      <BadgeField
        label={"Formulario 08"}
        text={status.text}
        badgeClass="capitalize"
        variant={status.color}
      />
      <InputField
        label={"Lugar de firma"}
        disabled={true}
        value={data.notary_office}
      />
      <CheckboxField
        checked={data.received}
        label={"Recibido"}
        onChange={onChangeForm08}
        disabled={
          data.current_status !== STATUS_FORM_08.signed &&
          data.current_status !== STATUS_FORM_08.delivered
        }
      />
    </div>
  );
};

export default Form08;
