import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import ProfileService from "../../services/profile.service";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../hooks";
import { updateLocalStorageCache } from "utils/Service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        El campo es obligatorio!
      </div>
    );
  }
};

const ProfilesCreate = (props) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const form = useRef();
  const checkBtn = useRef();
  const addToast = useToast();

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const onChangeDescription = (e) => {
    const description = e.target.value;
    setDescription(description);
  };

  const handSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      ProfileService.create(name, description).then(
        () => {
          addToast({
            variant: "success",
            header: "Perfiles",
            body: "Se creó un nuevo perfil correctamente",
          });
          updateLocalStorageCache().then(() => {
            navigate("/admin/profiles/list");
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          addToast({
            variant: "danger",
            header: "Perfiles",
            body: resMessage,
          });
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <Container fluid>
        <Button
          href="/admin/profiles/list"
          className="btn-fill btn btn-info capitalize"
        >
          Volver
        </Button>
        <br />
        <br />
        <Row>
          <Col md={12}>
            <Card
              content={
                <Form onSubmit={handSubmit} ref={form}>
                  <div className="form-group">
                    <label htmlFor="email">Nombre</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Nombre"
                      onChange={onChangeName}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Descripción</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="description"
                      placeholder="description"
                      onChange={onChangeDescription}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group mt-3">
                    <button
                      className="btn btn-primary btn-fill pull-right"
                      disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Crear Perfil</span>
                    </button>
                  </div>

                  <CheckButton style={{ display: "none" }} ref={checkBtn} />

                  <div className="clearfix" />
                </Form>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProfilesCreate;
