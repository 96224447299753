import React, { useEffect, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Filters from "../../components/Filters/index";
import List from "../../components/Table/List";
import RejectBacs from "../../components/Modal/Bacs/rejectBacs";
import Edit from "../../components/Modal/Bacs/edit";
import UploadFile from "../../components/Modal/UploadFile/uploadFile";
import ExcelButton from "../../components/ExcelButton";
import {
  useFilters,
  useGrid,
  useStatusModal,
  useConfiguration,
  useToast,
} from "../../hooks/index";
import Modal from "../../layouts/ModalDisplay";
import BacsService from "../../services/bacs/bacs.service";
import styles from "../General/styles.module.scss";
import { head, headAgent } from "./constants/constant_columns";
import { bacs_filters } from "./constants/constant_filters";
import { downloadAllZip, linkExternal } from "../../utils/File";
import { genericBadge } from "../../utils/Badges";
import {
  bacs_status,
  get_status_name,
  banks_status,
  get_bank_status_name,
} from "../../variables/bacs_constants";
import { constants } from "../../variables/constants";

const sortColumns = (body, profile) => {
  return body.map((e) => {
    const columns = {
      id: e.id,
      vehicle_id: e.vehicle_id,
      plate_number: e.plate_number,
      status_id: genericBadge(bacs_status, get_status_name[e.status_id]),
      documentation_link: linkExternal(e.documentation_link),
      adenda: e.adenda,
      issuer_entity_tentative: e.issuer_entity_tentative,
      person_in_charge: e.person_in_charge,
      bank_status_id: genericBadge(
        banks_status,
        get_bank_status_name[e.bank_status_id]
      ),
      vehicle_chasis: e.vehicle_chasis,
      months_seniority: e.months_seniority,
      purchase_form08_signed: e.purchase_form08_signed,
      logistica_date: e.logistica_date,
      presentation_date: e.presentation_date,
      retiro_date: e.retiro_date,
      _status: e.status_id,
    };

    if (profile && profile === constants.agentInternalProfile) {
      delete columns.person_in_charge;
    }

    return columns;
  });
};

const sort = {
  model: null,
  field: "id",
  type: "ASC",
};

const rejected = [
  bacs_status.DESAFECTADO_VENTA.value,
  bacs_status.DESAFECTADO_TIEMPO.value,
];

const allowedProfiles = [constants.employeeProfile];

const BacsList = (props) => {
  const {
    handleCloseEditModal,
    handleOpenEditModal,
    showEditModal,
    handleCloseRejectModal,
    handleShowRejectModal,
    showRejectModal,
  } = useStatusModal();

  const {
    body,
    countItems,
    currentPage,
    currentSort,
    totalItems,
    totalPage,
    handleBody,
    handleSort,
    handleToPage,
    handleTotalItems,
    handleTotalPage,
    cleanPage,
    refresh,
    refreshGrid,
  } = useGrid(sort);

  const { filters, onFilterChange } = useFilters(bacs_filters, cleanPage);
  const { currentPermissions, profile, userId } = useConfiguration("BACS");

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [processName, setProcessName] = useState();
  const [title, setTitle] = useState();
  const [dataModal, setDataModal] = useState();

  const addToast = useToast();

  const next = {
    link: "/admin/ventas/list",
    text: "Ventas",
  };

  const handleOpenUploadModal = (modalProcessName, modalTitle) => {
    setProcessName(modalProcessName);
    setTitle(modalTitle);
    setOpenUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
  };

  const handleToastEdit = (message, type) => {
    addToast({ variant: type, header: "BACS", body: message });
    handleCloseEditModal();
  };

  useEffect(() => {
    if (profile) {
      // @todo: Ver de mejorar esto
      let currentFilters = { ...filters };
      if (profile === constants.agentInternalProfile) {
        filters.adenda.size = 3;
        filters.status_bank.size = 5;
        delete filters.agents;
        currentFilters = { ...currentFilters, agents: { value: userId } };
      }

      const params = {
        page: currentPage,
        limit: countItems, // like countItems
        sortModel: currentSort?.model,
        sortField: currentSort?.field,
        sortType: currentSort?.type,
        filters: currentFilters,
      };

      BacsService.getAll(params).then((response) => {
        handleTotalItems(response.data.totalItems);
        handleTotalPage(Math.ceil(response.data.totalItems / countItems));
        handleBody(sortColumns(response.data.response, profile));
      });
    }
    //eslint-disable-next-line
  }, [currentPage, currentSort, countItems, filters, refresh, profile]);

  const openEditModal = (id) => {
    BacsService.getById(id).then((response) => {
      if (response.data.success) {
        setDataModal(response.data);
        handleOpenEditModal();
      }
    });
  };

  const actions = (props) => {
    return (
      <>
        {currentPermissions && currentPermissions.permissions.hasSelect && (
          <Button
            onClick={(e) => openEditModal(props.id, e)}
            className="btn-simple btn btn-xs btn-secondary"
          >
            <i className="fas fa-file-alt" aria-hidden="true"></i>
          </Button>
        )}
        {currentPermissions &&
        currentPermissions.permissions.hasDelete &&
        !rejected.includes(props._status) ? (
          <Button
            onClick={() => handleShowRejectModal(props.id)}
            className="btn-simple btn btn-xs btn-danger"
          >
            <i className="fas fa-times"></i>
          </Button>
        ) : null}
      </>
    );
  };

  return (
    <div className="content">
      <div className={`content-filters form-group ${styles.lessHeight}`}>
        <Button
          className="btn-fill btn btn-primary capitalize"
          onClick={() =>
            handleOpenUploadModal(
              "7_read_bacs_xlsx_file",
              "Cargar archivo BACS"
            )
          }
        >
          Cargar archivo BACS
        </Button>
        {!allowedProfiles.includes(profile) ? (
          <Button
            className="btn-fill btn btn-info capitalize"
            onClick={() =>
              handleOpenUploadModal(
                "8_bacs_bank_xlsx_file",
                "Cargar archivo Bancos"
              )
            }
          >
            Cargar archivo Bancos
          </Button>
        ) : null}
        <div className={styles.export}>
          <ExcelButton type="bacs" filters={filters} />
        </div>
      </div>
      <Filters filterFields={filters} onFilterChange={onFilterChange} />
      <List
        thead={profile === constants.agentInternalProfile ? headAgent : head}
        tbody={body}
        actions={actions}
        next={next}
        totalItems={totalItems}
        currentPage={currentPage}
        totalPage={totalPage}
        toPage={handleToPage}
        handleSort={handleSort}
        sortable
        currentSort={currentSort}
      />
      {openUploadModal && (
        <Modal
          content={
            <UploadFile
              processName={processName}
              title={title}
              handleClose={handleCloseUploadModal}
            />
          }
          showModal={openUploadModal}
          handleShow={openUploadModal}
          handleClose={handleCloseUploadModal}
        />
      )}
      {showEditModal && (
        <Modal
          content={
            <Edit
              data={dataModal}
              handleClose={handleCloseEditModal}
              refresh={refreshGrid}
            />
          }
          showModal={showEditModal}
          handleShow={openEditModal}
          handleClose={handleCloseEditModal}
        />
      )}
      {showRejectModal.show && (
        <Modal
          content={
            <RejectBacs
              handleClose={handleCloseRejectModal}
              refresh={refreshGrid}
              id={showRejectModal.id}
            />
          }
          showModal={showRejectModal.show}
          handleShow={showRejectModal}
          handleClose={handleCloseRejectModal}
          headerTitle={<div>Motivos de la desafectación</div>}
        />
      )}
    </div>
  );
};

export default BacsList;
