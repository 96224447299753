import React from 'react';

const UploadFieldFile = ({accept,title,handleFiles,disabled}) => {
  return (
    <div className="col">
      <label className="capitalize">
        <strong>{title}</strong>
      </label>
      <div>
        <span>
          <input
            type="file"
            accept={accept}
            className="form-control"
            name="xlsx"
            encType="multipart/form-data"
            multiple={false}
            onChange={handleFiles}
            disabled={disabled}
          />
        </span>
      </div>
    </div>
  );
};

export default UploadFieldFile;
