import axios from "axios";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const saveXlsxFile = async (props) => {
  try {
    return await axios.post(`${API_URL}/settings/save-xlsx`,{...props}, { headers: authHeader() });
  } catch (error) {
    return error;
  }
};

export default {
    saveXlsxFile,
};
