import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";

import AdminNavbarLinks from "./AdminNavbarLinks.jsx";
import authHeader from "../../services/auth-header";

class Header extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      sidebarExists: false,
      user: JSON.parse(localStorage.getItem('user'))
    };
  }
  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");    
  }
  render() {
    return (
      <Navbar className="navbar navbar-default" collapseOnSelect expand="lg" >
        <Navbar.Brand href="#home">{this.props.brandText}</Navbar.Brand>
        <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav>
            <AdminNavbarLinks logged={authHeader}/>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;
