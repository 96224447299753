import React from "react";
import { Button } from "react-bootstrap";

const ItemBox = ({ item, onComplexFieldChange, onDeleteForm }) => (
  <div className="row" key={item.checkbox.name}>
    <div className="col">
      <strong className="capitalize">{item.title}</strong>
      {!item.checkbox.checked && (
        <Button
          onClick={(e) => onDeleteForm(item, e)}
          className="btn-simple btn btn-xs btn-danger"
        >
          <i className="fas fa-times"></i>
        </Button>
      )}
    </div>
    <div className="col">
      <input
        type="checkbox"
        name={item.checkbox.name}
        checked={item.checkbox.checked}
        onChange={(e) =>
          onComplexFieldChange(item.checkbox.name, e.target.checked)
        }
      />
    </div>
  </div>
);

export default ItemBox;
