import React, { useState, useRef, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "components/Card/Card.jsx";
import NotFound from "components/Errors/NotFound.js";
import IssuerEntityService from "../../services/issuerEntity.service";
import { constants } from "../../variables/constants";
import { useToast } from "../../hooks";
import { updateLocalStorageCache } from "utils/Service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        El campo es obligatorio!
      </div>
    );
  }
};

const IssuerEntityEdit = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const issuer_entity_id = params.id;
  const [description, setDescription] = useState("");
  const [zone, setZone] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resourceExist, setResourceExist] = useState(true);

  const form = useRef();
  const checkBtn = useRef();
  const addToast = useToast();

  useEffect(() => {
    IssuerEntityService.getById(issuer_entity_id)
      .then((response) => {
        if (response.data.success && response.data.data) {
          setDescription(response.data.data.description);
          setZone(response.data.data.zone);
        }
      })
      .catch((e) => {
        setResourceExist(false);
      });
  }, [issuer_entity_id]);

  const handSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      const params = {
        issuer_entity_id,
        description,
        zone,
      };
      IssuerEntityService.edit(params).then(
        () => {
          addToast({
            variant: "success",
            header: "Registros",
            body: "Se actualizó el registro correctamente",
          });
          updateLocalStorageCache().then(() => {
            navigate("/admin/issuer-entity/list");
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          addToast({
            variant: "danger",
            header: "Registros",
            body: resMessage,
          });
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <Container fluid>
        <Button
          href="/admin/issuer-entity/list"
          className="btn-fill btn btn-info capitalize"
        >
          Volver
        </Button>
        <br />
        <br />
        <Row>
          <Col md={12}>
            <Card
              content={
                resourceExist ? (
                  <Form onSubmit={handSubmit} ref={form}>
                    <div className="row form-group">
                      <div className="col-sm-4">
                        <label htmlFor="issuer_entity_id">Id</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="issuer_entity_id"
                          value={issuer_entity_id}
                          disabled
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="description">Descripción</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="description"
                          placeholder="description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          validations={[required]}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="zone">{constants.zone}</label>
                        <Input
                          type="number"
                          className="form-control"
                          name="zone"
                          placeholder="Seleccione una zona"
                          value={zone}
                          onChange={(e) => setZone(e.target.value)}
                          validations={[required]}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        className="btn btn-primary btn-fill pull-right"
                        disabled={loading}
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Editar Registro</span>
                      </button>
                    </div>

                    <CheckButton style={{ display: "none" }} ref={checkBtn} />

                    <div className="clearfix" />
                  </Form>
                ) : (
                  <NotFound />
                )
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default IssuerEntityEdit;
