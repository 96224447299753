import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-validation/build/form";
import { delivery_types } from "../../../../variables/constants";
import { DatePickerField, InputField, SelectField } from "../../../Form/index";
import { DomainAndStatus } from "../components";

const Edit = (props) => {
  const {
    data: {
      response: {
        plate_number,
        delivery_type,
        status,
        delivery_date,
        owner,
        estimated_end_trial,
        bill_created_date,
        stock_id,
      },
    },
    handleClose,
  } = props;

  const initialFormState = {
    finish_date_test: estimated_end_trial,
    payment_date: new Date(bill_created_date),
    stock_id,
  };
  const [fields] = useState(initialFormState);

  return (
    <div className="container">
      <Form>
        <div className="row">
          <DomainAndStatus
            fields={{ domain: plate_number, status: status, stock_id }}
          />
          <InputField
            label={"Titular 1"}
            value={owner}
            disabled={true}
            name={"client"}
          />
        </div>
        <div className="row">
          <DatePickerField
            selected={delivery_date == null ? null : new Date(delivery_date)}
            disabled={true}
            label={"Fecha de entrega"}
          />
          <SelectField
            label={"Tipo de entrega"}
            name={"type_of_delivery"}
            value={delivery_type}
            disabled={true}
            options={delivery_types}
          />
        </div>
        <div className="row">
          <DatePickerField
            label={"Fin periodo de prueba"}
            disabled={true}
            selected={
              fields.finish_date_test == null
                ? null
                : new Date(fields.finish_date_test)
            }
          />
          <DatePickerField
            selected={
              fields.payment_date == null ? null : new Date(fields.payment_date)
            }
            disabled={true}
            label={"Fecha de cobro"}
          />
        </div>
        <div className="modal-footer justify-content-center">
          <div className="row">
            <Button
              className="btn-fill pull-right btn btn-primary btn-sm"
              onClick={handleClose}
            >
              Cerrar
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Edit;
