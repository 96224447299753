import Accordion from 'react-bootstrap/Accordion';
import styles from "./style.module.scss";

function CustomAccordion({ title, children, index = 0, defaultOpen = false }) {
  return (
    <Accordion
      defaultActiveKey={defaultOpen ? index : null}
      alwaysOpen={false}
      flush
      className={styles.container}
    >
      <Accordion.Item eventKey={index}>
        <Accordion.Button className={styles.customAccordion}>
          {title}
        </Accordion.Button>
        <Accordion.Body className={styles.body}>{children}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default CustomAccordion;
