import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAll = async () => {
  return await axios.get(`${API_URL}/purchase-file-canceled-status`, { headers: authHeader() });
};

export default {
  getAll
};
